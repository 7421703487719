import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

// Componenets
import LoadingButton from 'components/LoadingButton';
import messages from 'messages/Auth';
import { useDispatch } from 'react-redux';
import { authActions } from 'services/auth';
import { Box, Button } from '@material-ui/core';
import { useAlert } from 'react-alert';
import {
  hasErrorPassword,
  minSymbolsForPassword,
  onInputCheckPassword,
} from './validation/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    width: '100%',
    height: '498',
    margin: '10% auto',
    padding: theme.spacing(3),
    position: 'relative',
  },
  button: {
    color: '#fff',
    background: theme.palette.primary.main,
    margin: theme.spacing(2, 0),
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  title: {
    textAlign: 'center',
    width: '100%',
  },
  modal: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

function PasswordChange(props) {
  const classes = useStyles();
  const { code, onClose, intl } = props;
  const dispatch = useDispatch();
  const alert = useAlert();

  // States
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const handleChange = (evt) => {
    setIsError(true);
    setPassword(evt.target.value.replace(onInputCheckPassword, ''));
    if (
      !evt.target.value &&
      evt.target.value === confirmPassword &&
      hasErrorPassword.test(password) &&
      hasErrorPassword.test(confirmPassword)
    ) {
      setIsError(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password.trim() !== confirmPassword.trim()) {
      alert.error(
        intl.locale === 'ru'
          ? 'Пароли не совпадают'
          : 'Сыр сөздөр дал келген жок',
      );
    } else if (!password.trim() || !confirmPassword.trim()) {
      alert.error(
        intl.locale === 'ru' ? 'Заполните форму' : 'Форманы толтуруңуз',
      );
    } else if (
      password.trim().length < minSymbolsForPassword ||
      !hasErrorPassword.test(password)
    ) {
      setIsError(true);
      alert.error(intl.formatMessage({ ...messages.PasswordTip }));
    } else {
      await dispatch(
        authActions.recovery({
          code,
          new_password: password,
          confirm_new_password: confirmPassword,
        }),
      ).then(() => {
        onClose();
        setIsError(false);
        setLoading(false);
      });
    }
  };
  return (
    <>
      <Box sx={{ padding: '8px !important' }}>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: 24 }}>
              {intl.formatMessage({ ...messages.newPassword })}
            </Typography>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, margin: '10px 0 20px' }}
            >
              {intl.formatMessage({ ...messages.PasswordTip })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <TextField
                fullWidth
                type={showPass ? 'text' : 'password'}
                variant="outlined"
                margin="dense"
                name="password"
                color="primary"
                placeholder={intl.formatMessage({ ...messages.password })}
                value={password}
                onChange={handleChange}
                error={
                  hasErrorPassword.test(password)
                    ? false
                    : isError
                    ? true
                    : false
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass((prev) => !prev)}
                        onMouseDown={handleMouseDownPassword}
                        className={classes.icon}
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                type={showPass2 ? 'text' : 'password'}
                variant="outlined"
                margin="dense"
                name="password"
                color="primary"
                placeholder={'Подтвердите пароль'}
                className={classes.textField}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(
                    e.target.value.replace(onInputCheckPassword, ''),
                  );
                  if (
                    e.target.value !== password &&
                    !hasErrorPassword.test(password) &&
                    !hasErrorPassword.test(confirmPassword)
                  ) {
                    setIsError(true);
                  } else if (
                    !e.target.value &&
                    e.target.value === password &&
                    hasErrorPassword.test(password) &&
                    hasErrorPassword.test(confirmPassword)
                  ) {
                    setIsError(false);
                  } else {
                    setIsError(false);
                  }
                }}
                error={isError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass2((prev) => !prev)}
                        onMouseDown={handleMouseDownPassword}
                        className={classes.icon}
                      >
                        {showPass2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitButton}
              style={{ boxShadow: 'none', padding: '10px 0' }}
            >
              {intl.formatMessage({ ...messages.save })}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

PasswordChange.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default memo(PasswordChange);
