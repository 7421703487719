import { combineActions, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { boolReducer } from 'common/helpers';
import { profileActions } from 'states/Profile';
import { actions } from './actions';

export const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
};

export const isAuthorizing = boolReducer(
  [combineActions(actions.loginRequest, actions.registerRequest)],
  [
    combineActions(
      actions.loginSuccess,
      actions.loginFail,
      actions.registerSuccess,
      actions.registerFail,
      actions.socialLoginSuccess,
      actions.socialLoginFail,
    ),
  ],
);

const authSuccessAction = combineActions(
  actions.loginSuccess,
  actions.socialLoginSuccess,
  actions.recoverySuccess,
  actions.activateSuccess,
);

const authFailedAction = combineActions(
  actions.loginFail,
  actions.registerFail,
  actions.socialLoginFail,
  actions.recoveryFail,
  actions.logout,
  actions.activateFail,
);

const handleSuccess = (state, { payload }) => {
  return {
    ...state,
    isAuthenticated: true,
    user: {
      info: payload?.user,
      token: payload?.access,
      refresh: payload?.refresh,
    },
  };
};
export const data = handleActions(
  {
    [authSuccessAction]: handleSuccess,
    [authFailedAction]: (state, { payload }) => ({
      ...state,
      isAuthenticated: false,
      user: null,
      error: payload || null,
    }),
    [actions.refreshTokenSuccess]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        token: payload,
      },
    }),
    [actions.tokenLoginSuccess]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        info: payload,
      },
    }),
    [profileActions.updateProfileSuccess]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        info: payload,
      },
    }),
    [actions.updateLocationSuccess]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        info: payload,
      },
    }),

    [actions.logout]: () => initialState,
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isAuthorizing,
});

export const key = 'auth';

export const authSelectors = {
  getAuthStatus: (state) =>
    state?.auth ? state?.auth?.data?.isAuthenticated : false,
  getAuthError: (state) => (state?.auth ? state?.auth?.data?.error : null),
  getAuthUser: (state) => (state?.auth ? state?.auth?.data?.user : null),
};
