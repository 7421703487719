import Cookies from 'universal-cookie';
import { COOKIE_KEYS } from 'common/constants';

const cookies = new Cookies();
const { TOKEN, REFRESH } = COOKIE_KEYS;
const tokenExpires = 30; // token expires in days

export function getTokenFromCookies() {
  return cookies.get(TOKEN);
}

export function setTokenToCookies(token) {
  cookies.set(TOKEN, token, {
    path: '/',
    sameSite: true,
    maxAge: 86400 * tokenExpires,
  });
}

export function getRefreshTokenFromCookies() {
  return cookies.get(REFRESH);
}

export function setRefreshTokenToCookies(token) {
  cookies.set(REFRESH, token, {
    path: '/',
    sameSite: true,
    maxAge: 86400 * tokenExpires,
    // secure: true,
  });
}

export function removeTokensFromCookies() {
  cookies.remove(REFRESH, { path: '/' });
  cookies.remove(TOKEN, { path: '/' });
}
