import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './styles';

export default function Skeleton() {
  const classes = useStyles();
  return (
    <div className={classes.shimmer}>
      <MuiSkeleton
        variant="rect"
        width="100%"
        height={120}
        className={classes.shimmerItem}
      />
    </div>
  );
}
