import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import MultipleSelect from 'components/MultipleSelect';
import FromTo from './FromTo';

function CategoryFilter(props) {
  const {
    filter,
    parameters,
    options,
    onOptionClick,
    onParameterUpdate,
    intl,
  } = props;

  if (filter.type === 'select') {
    const selectedOptions = options.filter((globalOpt) => {
      return filter.options.find((opt) => opt?.id === globalOpt?.id);
    });
    return (
      <MultipleSelect
        value={selectedOptions.map((opt) => opt?.id)}
        onChange={onOptionClick}
        options={filter.options}
        label={filter[`title_${intl?.locale}`]}
        style={{
          width: '100%',
        }}
      />
    );
  }
  if (filter?.from_to) {
    return (
      <FromTo
        parameter={parameters.find((param) => param?.id === filter?.id) || null}
        onChange={onParameterUpdate}
        filter={filter}
      />
    );
  }
  return <></>;
}

CategoryFilter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['select', 'enter']),
    category: PropTypes.number,
    optional: PropTypes.bool,
    from_to: PropTypes.bool,
    is_many: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    title_ky: PropTypes.string,
    title_ru: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.object),
  parameters: PropTypes.arrayOf(PropTypes.object),
  onOptionClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  intl: intlShape,
};

export default injectIntl(CategoryFilter);
