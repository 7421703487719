import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserContext } from 'providers/UserProvider';
import { getTokenFromCookies } from 'utils/cookies';

import CircularProgress from '@material-ui/core/CircularProgress';

const PrivateRouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    redirectTo,
    path,
    ...rest
  } = props;

  const { user } = useContext(UserContext);
  const token = getTokenFromCookies();

  function renderComponent(routeProps) {
    if (user || token) {
      return (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      );
    }

    return <Redirect to={{ pathname: redirectTo }} />;
  }

  if (token && !user) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={renderComponent}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  redirectTo: PropTypes.string,
};

PrivateRouteWithLayout.defaultProps = {
  redirectTo: '/',
};

export default PrivateRouteWithLayout;
