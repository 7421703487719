import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@material-ui/core';
import SadIcon from 'assets/images/sad.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '35vh',
    width: '50%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    color: '#3B414B',
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  description: {
    color: '#A6AAB4',
    fontSize: 18,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}));

function NotFount(props) {
  const { intl, messages } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        alt=""
        src={SadIcon}
        width="80"
        height="80"
      />
      <Typography
        component="h1"
        variant="h5"
        className={classes.title}
      >
        {intl.formatMessage({ ...messages.noResults })}
      </Typography>
      <Typography
        component="p"
        variant="caption"
        className={classes.description}
      >
        {intl.formatMessage({ ...messages.noResultsText })}
      </Typography>
    </div>
  );
}

NotFount.propTypes = {
  intl: PropTypes.object,
  messages: PropTypes.object,
};

export default NotFount;
