import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { paths } from 'common/constants';
import Filter from 'containers/Filter';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'scroll',
    padding: theme.spacing(1),
    fontSize: '14px',

    '&::-webkit-scrollbar': {
      width: 4,
      height: 5,
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
      borderRadius: 5,
    },
  },
  btn: {
    lineHeight: '30px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    textDecoration: 'none',
    fontSize: '16px',
    color: 'inherit',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    boxShadow: '0px 3px 10px rgba(164, 175, 202, 0.25)',
    borderRadius: '5px',

    '&:last-child': {
      marginRight: 0,
    },

    '@media (max-width: 420px)': {
      fontSize: 'inherit',
    },
  },
  selectBtn: {
    textTransform: 'none',
    fontSize: 'inherit',
    paddingTop: 0,
    paddingBottom: 0,
  },
  arrowIcon: {
    color: theme.palette.primary.blue,

    '& .MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
}));

function Mobile(props) {
  const { categories, intl } = props;

  const classes = useStyles();
  const scrollRef = useHorizontalScroll();

  // const currentCat = categoryLine[categoryLine.length - 1]

  const ChildCategory = ({ category }) => (
    <Typography
      className={classes.btn}
      component={NavLink}
      to={paths.CATEGORY_PAGE(category.title_slug)}
    >
      {category[`title_${intl.locale}`]}
    </Typography>
  );

  const childCategories =
    categories?.map &&
    categories.map((child) => (
      <ChildCategory
        category={child}
        key={child?.id}
      />
    ));

  return childCategories ? (
    <div
      className={classes.root}
      ref={scrollRef}
    >
      {childCategories}
    </div>
  ) : (
    <Filter />
  );
}

Mobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  intl: intlShape,
};

export default React.memo(Mobile);
