import { makeStyles, withStyles } from '@material-ui/core';
import { Radio, Switch } from '@material-ui/core';

export const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: '#9AA6B6',
    '&$checked': {
      color: theme.palette.primary.blue,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.blue,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export const MyRadio = withStyles((theme) => ({
  colorPrimary: {
    color: '#fff',
  },
  root: {
    color: '#fff',
    '&$checked': {
      color: theme.palette.primary.blue,
    },
  },
  checked: {},
}))(Radio);

export const useStyles = makeStyles((theme) => ({
  navWrapper: {
    textDecoration: 'none',
  },
  deletionText: {
    marginLeft: '30px',
  },
  product: {
    minHeight: '380px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0px',
    boxShadow: '0px 4px 10px 0px rgba(36, 86, 151, 0.05)',
    background: '#fff',
    border: '1px solid transparent',
    height: '100%',

    '@media (max-width: 340px)': {
      maxWidth: '145px',
    },
    '& p': {
      marginLeft: '8px',
    },
    '@media (max-width: 760px)': {
      minHeight: '120px',
    },
    '& a': {
      textDecoration: 'none',
    },
  },

  chartAndEdit: {
    backgroundColor: 'white',
    display: 'flex',
    position: 'absolute',
    top: '102%',
    width: '100%',
    zIndex: 15,
    '& img': {
      cursor: 'pointer',
    },
    '& a:nth-child(2)': {
      marginLeft: '15px',
      textDecoration: 'none',
    },
  },
  chartAndEditNone: {
    display: 'none',
  },
  chartAndEditContainer: {
    '& .MuiAccordionSummary-content': {
      zIndex: '100',
      background: '#fff',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '120px !important',
    },
    '& button': {
      color: '#0A142F',
      fontSize: '13px ',
      '&:hover': {
        backgroundColor: '#fff !important',
      },
    },
  },
  image: {
    width: '100%',
    minHeight: '190px',
    maxHeight: '190px',
    position: 'relative',
    '@media (max-width: 480px)': {
      minHeight: '150px',
      maxHeight: '150px',
    },
    borderRadius: '5px 5px 0 0',
    backgroundSize: 'cover !important',
  },
  priceAndTitle: {
    position: 'relative',
    maxHeight: '120px',
    minHeight: '120px',
    '& p:first-child': {
      marginTop: '8px',
      marginLeft: '8px',
      color: '#384454',
      fontSize: '20px',
      '@media (max-width: 380px)': {
        fontSize: '16px',
        lineHeight: '19px',
      },
      lineHeight: '21px',
      height: '35px',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& p:nth-child(2)': {
      marginTop: '8px',
      marginLeft: '8px',
      color: '#384454',
      fontSize: '16px',
      '@media (max-width: 480px)': {
        fontSize: '14px',
      },
      lineHeight: '21px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  location: {
    display: 'flex',
    marginBottom: '15px',
    '@media (max-width: 580px)': {
      maxWidth: '145px',
    },
    '& img': {
      width: '15px',
      height: '18px',
    },
    '& a img': {
      width: 25,
      height: 25,
    },
    '& p': {
      color: '#9AA6B6',
      fontSize: '14px',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  switch: {
    height: '11%',
    '& MuiSwitch-root': {
      backgroundColor: theme.palette.primary.blue,
    },
    display: 'flex',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chartAndEdit: {
    backgroundColor: 'white',
    display: 'flex',
    position: 'absolute',
    top: '102%',
    width: '100%',
    zIndex: 15,
    '& img': {
      cursor: 'pointer',
    },
    '& a:nth-child(2)': {
      marginLeft: '15px',
      textDecoration: 'none',
    },
  },
  chartAndEditNone: {
    display: 'none',
  },
  chartAndEditContainer: {
    '& .MuiAccordionSummary-content': {
      zIndex: '100',
      background: '#fff',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '120px !important',
    },
    '& button': {
      color: '#0A142F',
      fontSize: '13px ',
      '&:hover': {
        backgroundColor: '#fff !important',
      },
    },
  },
  changeButton: {
    display: 'flex',
    color: 'black',
    marginLeft: '13px',
    gap: '20px',
  },
  navLinkText: {
    display: 'flex',
    color: 'black',
    marginLeft: '12px',
    gap: '20px',
  },
  select: {
    paddingLeft: '10px',
  },
  stockAndPrice: {
    paddingBottom: '10px',
    '& p:first-child': {
      color: 'rgba(255, 60, 0, 0.7)',
      fontSize: '20px',
      '@media (max-width: 380px)': {
        fontSize: '16px',
        lineHeight: '19px',
      },
      lineHeight: '28px',
    },
    '& p:nth-child(2)': {
      color: '#384454',
      fontSize: '14px',
      lineHeight: '16px',
      '@media (max-width: 380px)': {
        fontSize: '12px',
      },
      marginTop: '5px',
    },
  },
  favorite: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      width: '22px',
      height: '20px',
      position: 'absolute',
      right: 8,
      top: 200,
      '@media (max-width: 480px)': {
        top: 170,
      },
      '& path': {
        transition: 'all 0.3s',
      },
      '&:focus path': {
        stroke: theme.palette.primary.blue,
        fill: theme.palette.primary.blue,
      },
    },
  },
  business: {
    width: '70px',
    height: '23px',
    background: 'rgba(0, 81, 186, 0.8)',
    borderRadius: '3px',
    padding: '4px',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '10px',
    color: '#fff',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&>div': {
      margin: '0',
    },
  },
  locationInfo: {
    marginLeft: '8px',
  },
  discount: {
    width: '40px',
    height: '25px',
    backgroundColor: '#FF0000',
    opacity: '60%',
    borderRadius: '5px',
    position: 'absolute',
    bottom: '7px',
    right: '10px',
    '& p': {
      color: 'white',
      marginTop: '3px',
      marginLeft: '4px',
    },
  },
  businessNameProduct: {
    display: 'flex',
    '& p': {
      marginBottom: '2px !important',
      color: '#0051BA !important',
      fontSize: '13px !important',
      marginLeft: '8px !important',
      marginRight: '10px !important',
    },
  },

  locationBurger: {
    display: '    flex',
    justifyContent: 'space-between',
    width: '95%',
  },
  moreButton: {
    background: 'white',
    border: 'none',
    cursor: 'pointer',
    '& img': {
      width: '15px',
    },
  },
  actions: {
    background: 'none',
    color: '#0A142F',
    height: '40px',
    padding: '0px',
  },
  onReviewClass: {
    background: '#4F4F4F',
    position: 'absolute',
    top: '82%',
    color: '#fff',
    left: '12px',
    borderRadius: '17px',
    width: '90%',
    height: '28px',
    opacity: 0.8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
