import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from 'messages/Auth';

// Mui
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TabPanel from 'components/TabPanel';
import { useStyles } from './styles';

// Core
import LoginComponent from './Login';
import RegisterComponent from './Register';
import PasswordRecoveryComponent from './PasswordRecovery';
import { authActions } from 'services/auth';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { hasErrorPassword } from './validation/validation';

const types = {
  login: 0,
  register: 1,
};

function AuthFormComponent(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    defaultType,
    onLogin,
    onRegister,
    intl,
    confirmPhone,
  } = props;

  const [tab, setTab] = useState(types[defaultType]);
  const [isPassRecovery, setIsPassRecovery] = useState(false);

  useEffect(() => {
    if (types[defaultType]) {
      setTab(types[defaultType]);
    } else {
      setTab(0);
    }

    return () => null;
  }, [defaultType]);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const setType = (type) => {
    setTab(types[type]);
    setIsPassRecovery(false);
  };

  const handlePassRecovery = () => setIsPassRecovery((prev) => !prev);

  const loginSubmit = useCallback(
    (e, data) => {
      e.preventDefault();
      onLogin(data);
    },
    [onLogin],
  );

  const registerSubmit = (e, data) => {
    e.preventDefault();
    if (hasErrorPassword.test(data.password)) {
      onRegister(data);
    } else {
      alert.error(
        intl.locale === 'ru' ? 'Заполните форму' : 'Форманы толтуруңуз',
      );
    }
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const render = useMemo(() => {
    if (isPassRecovery) {
      return (
        <PasswordRecoveryComponent
          intl={intl}
          messages={messages}
          setType={setType}
          confirmPhone={confirmPhone}
          onCloseConfirmPhome={onClose}
        />
      );
    }

    return (
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          className={classes.primaryBlue}
          onChange={handleChange}
          aria-label="auth"
          centered
        >
          <Tab
            label={intl.formatMessage({ ...messages.signin })}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({ ...messages.register })}
            {...a11yProps(1)}
          />
        </Tabs>

        <TabPanel
          value={tab}
          index={0}
          className={classes.mainBlockAuth}
        >
          <LoginComponent
            onSubmit={loginSubmit}
            setType={setType}
            intl={intl}
            messages={messages}
            onPassRecovery={handlePassRecovery}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          className={classes.mainBlockAuth}
        >
          <RegisterComponent
            setType={setType}
            intl={intl}
            messages={messages}
            onClose={onClose}
            onSubmit={registerSubmit}
          />
        </TabPanel>
      </>
    );
  }, [
    intl,
    loginSubmit,
    onClose,
    registerSubmit,
    tab,
    isPassRecovery,
    classes.primaryBlue,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
    >
      <Paper className={classes.root}>{render}</Paper>
    </Modal>
  );
}

AuthFormComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  defaultType: PropTypes.oneOf(['login', 'register', 'passwordRecovery'])
    .isRequired,
  onLogin: PropTypes.func,
  onRegister: PropTypes.func,
  intl: intlShape,
  onPasswordRecoverySubmit: PropTypes.func,
};

export default injectIntl(AuthFormComponent);
