import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: 'relative',
    height: 22,
    width: 26,
  },
  iconStick: {
    content: '""',
    position: 'absolute',
    left: '2px',
    right: '2px',
    height: '2px',
    transition: 'all 1000ms !important',
    borderRadius: '1px',
    backgroundColor: theme.palette.primary.light,
  },

  // top stick

  iconTopStick: {
    top: '2px',
  },

  topStickOpen: {
    animation: '$openTop 1000ms ease-in-out forwards',
  },

  '@keyframes openTop': {
    '0%': {
      top: '2px',
    },
    '50%': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '100%': {
      top: '50%',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
  },

  topStickClose: {
    animation: '$closeTop 1000ms ease-in-out forwards',
  },
  '@keyframes closeTop': {
    '100%': {
      top: '2px',
    },
    '50%': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '0%': {
      top: '50%',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
  },

  iconCenterStick: {
    top: '50%',
    transform: 'translateY(-50%)',
  },

  centerStickOpen: {
    animation: '$openCenter 500ms ease-in-out forwards',
  },

  '@keyframes openCenter': {
    '99%': {
      visibility: 'visible',
    },
    '100%': {
      visibility: 'hidden',
    },
  },

  centerStickClose: {
    animation: '$closeCenter 1000ms ease-in-out forwards',
  },
  '@keyframes closeCenter': {
    '100%': {
      backgroundColor: theme.palette.primary.light,
    },
    '0%': {
      backgroundColor: 'transparent',
    },
  },

  iconBottomStick: {
    bottom: '2px',
  },

  bottomStickOpen: {
    animation: '$openBottom 1000ms ease-in-out forwards',
  },
  '@keyframes openBottom': {
    '0%': {
      bottom: '2px',
    },
    '50%': {
      bottom: '50%',
      transform: 'translateY(50%)',
    },
    '100%': {
      bottom: '50%',
      transform: 'translateY(50%) rotate(45deg)',
    },
  },

  bottomStickClose: {
    animation: '$closeBottom 1000ms ease-in-out forwards',
  },
  '@keyframes closeBottom': {
    '100%': {
      bottom: '2px',
    },
    '50%': {
      bottom: '50%',
      transform: 'translateY(50%)',
    },
    '0%': {
      bottom: '50%',
      transform: 'translateY(50%) rotate(45deg)',
    },
  },
}));

function MenuIcon({ open, animate }) {
  const classes = useStyles();
  const topStickClose =
    open === null || !animate ? null : classes.topStickClose;
  const centerStickClose =
    open === null || !animate ? null : classes.centerStickClose;
  const bottomStickClose =
    open === null || !animate ? null : classes.bottomStickClose;

  return (
    <div className={classes.iconContainer}>
      <div
        className={clsx(
          classes.iconStick,
          classes.iconTopStick,
          open && animate ? classes.topStickOpen : topStickClose,
        )}
      />
      <div
        className={clsx(
          classes.iconStick,
          classes.iconCenterStick,
          open && animate ? classes.centerStickOpen : centerStickClose,
        )}
      />
      <div
        className={clsx(
          classes.iconStick,
          classes.iconBottomStick,
          open && animate ? classes.bottomStickOpen : bottomStickClose,
        )}
      />
    </div>
  );
}

MenuIcon.propTypes = {
  open: PropTypes.bool,
  animate: PropTypes.bool,
};

export default MenuIcon;
