import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#FFFFFF',
      main: '#FFCB05',
      blue: '#0051BA',
      dark: '#0A142F',
      contrastText: '#666',
      opacity: 'rgba(0, 0, 0, 0.7)',
    },
    secondary: {
      main: '#FC5C65',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Roboto', '-apple-system', 'sans-serif'],
    fontSize: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Set typography for mobile
const { h3, subtitle1 } = theme.typography;

theme.typography.h3 = {
  ...h3,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    fontWeight: 600,
  },
};

theme.typography.subtitle1 = {
  ...subtitle1,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    fontWeight: 600,
  },
};

// Overrides
theme.overrides = {
  MuiButton: {
    root: {
      fontSize: 14,
      textTransform: 'none',
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.dark,

      '&:hover': {
        backgroundColor: '#CFA400',
      },
    },
    containedSecondary: {
      backgroundColor: theme.palette.primary.blue,
      color: theme.palette.primary.light,

      '&:hover': {
        backgroundColor: '#003984',
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
};

export default function MuiTheme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
