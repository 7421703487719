import { defineMessages } from 'react-intl';

export const scope = 'Filter';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Поиск',
  },
  find: {
    id: `${scope}.find`,
    defaultMessage: 'Найти',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Фильтры',
  },
  chooseCategory: {
    id: `${scope}.chooseCategory`,
    defaultMessage: 'Выберите Категорию',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'Все',
  },
  chooseLocation: {
    id: `${scope}.chooseLocation`,
    defaultMessage: 'Выберите Местоположение',
  },
  order: {
    id: `${scope}.order`,
    defaultMessage: 'Соритировать',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'По умолчанию',
  },
  cheap: {
    id: `${scope}.cheap`,
    defaultMessage: 'Сначала дешевле',
  },
  expensive: {
    id: `${scope}.expensive`,
    defaultMessage: 'Сначала дороже',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'Сначала новые',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Цена',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'от',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'до',
  },
  priceFrom: {
    id: `${scope}.priceFrom`,
    defaultMessage: 'Цена от',
  },
  priceTo: {
    id: `${scope}.priceTo`,
    defaultMessage: 'Цена до',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Валюта',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Очистить',
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: 'Применить',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Закрыть',
  },
  locationSelectTitle: {
    id: `${scope}.locationSelectTitle`,
    defaultMessage: 'Выберите область или город',
  },
  show: {
    id: `${scope}.show`,
    defaultMessage: 'Показать',
  },
  postButton: {
    id: `${scope}.postButton`,
    defaultMessage: 'Подать объявление',
  },
  allCategories: {
    id: `${scope}.allCategories`,
    defaultMessage: 'Все категории',
  },
  allParams: {
    id: `${scope}.allParams`,
    defaultMessage: 'Все параметры',
  },
  hide: {
    id: `${scope}.hide`,
    defaultMessage: 'Свернуть',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Сбросить',
  },
  mainPage: {
    id: `${scope}.mainPage`,
    defaultMessage: 'Главная',
  },
  fromDate: {
    id: `${scope}.fromDate`,
    defineMessages: 'От',
  },
  toDate: {
    id: `${scope}.toDate`,
    defineMessages: 'До',
  },
});
