import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import IconButton from '@material-ui/core/IconButton';
import { Box, List, SwipeableDrawer, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

// Core
import filterSvg from 'assets/images/FilterIcon.svg';
import FilterMenu from 'containers/FilterMenu';
import MenuIcon from 'assets/images/menuIcon.svg';
import { useStyles } from './styles';
import CategoryAccordion from '../components/CategoryAccordion';
import SearchFIeid from './SearchFieid';

function Mobile(props) {
  const classes = useStyles();
  const {
    intl,
    messages,
    categories,
    debounce,
    showTips,
    onSubmit,
    handleShowTips,
    onChange,
    value,
  } = props;

  // NOTE: set null to not show animation after first rendering
  const [menuOpen, setMenuOpen] = useState(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const toggleMenuOpen = () => setMenuOpen((prev) => !prev);
  const toggleFilterMenuOpen = useCallback(() => {
    setFilterMenuOpen((prev) => !prev);
  }, []);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const categoryAccordions = categories?.map((cat) => (
    <CategoryAccordion
      onItemClick={handleClose}
      key={cat.id}
      category={cat}
    />
  ));
  return (
    <div className={classes.root}>
      <Box
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          className={classes.menuBtn}
          onClick={toggleMenuOpen}
        >
          <img
            src={MenuIcon}
            alt="categories"
          />
        </IconButton>
        <SearchFIeid
          onChange={onChange}
          value={value}
          handleShowTips={handleShowTips}
          debounce={debounce}
          showTips={showTips}
          onSubmit={onSubmit}
        />
        <IconButton
          className={classes.filterMenuBtn}
          onClick={toggleFilterMenuOpen}
        >
          <img
            alt="filters"
            src={filterSvg}
          />
        </IconButton>
      </Box>

      <SwipeableDrawer
        anchor="left"
        open={menuOpen}
        className={classes.menuDrawer}
        style={{
          zIndex: 1299,
        }}
      >
        <Box paddingTop="42px">
          <Box
            padding="6.4px"
            position="relative"
            borderBottom="1px solid #C4C4C4"
          >
            <Typography
              variant="h3"
              component="h3"
              align="center"
            >
              {intl.formatMessage({ ...messages.allCategories })}
            </Typography>
            <IconButton
              onClick={handleClose}
              className={classes.menuClose}
            >
              <Close fontSize="large" />
            </IconButton>
          </Box>
          <List disablePadding>{categoryAccordions}</List>
        </Box>
      </SwipeableDrawer>

      <FilterMenu
        open={filterMenuOpen}
        onClose={toggleFilterMenuOpen}
      />
    </div>
  );
}

Mobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  intl: PropTypes.object,
  messages: PropTypes.object,
};

export default memo(Mobile);
