/*
 *
 * AdsPage actions
 *
 */

import { createActions } from 'redux-actions';

import { createRequestAction, getResponseError } from 'common/helpers';
import { ENDPOINTS, ACTION_TYPES } from './constants';

const actionCreators = createActions({
  [ACTION_TYPES.CHANGE_CATEGORY_PARAMETERS]: null,
  ...createRequestAction(ACTION_TYPES.CREATE_PRODUCT),
  ...createRequestAction(ACTION_TYPES.UPDATE_PRODUCT),
  ...createRequestAction(ACTION_TYPES.UPLOAD),
  ...createRequestAction(ACTION_TYPES.MULTI_UPLOAD),
  ...createRequestAction(ACTION_TYPES.GET_CATEGORY_PARAMETERS),
});

export function getCategoryParameters(id, notDispatch = false) {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getCategoryParametersRequest());
    }
    try {
      const { data } = await api.get(ENDPOINTS.CATEGORY_PARAMETERS(id));

      if (!notDispatch) {
        dispatch(actionCreators.getCategoryParametersSuccess(data));
      }
      return data;
    } catch (e) {
      const error = getResponseError(e);
      if (!notDispatch) {
        dispatch(actionCreators.getCategoryParametersFail(error));
      }
      return Promise.reject(error);
    }
  };
}

export const changeCategoryParametersAsync = (category) => async (dispatch) => {
  dispatch(actionCreators.changeCategoryParameters(category));
};

export function createProduct(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.createProductRequest());
    try {
      const { data } = await api.post(ENDPOINTS.PRODUCT, params);

      dispatch(actionCreators.createProductSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.createProductFail(error));
      return Promise.reject(error);
    }
  };
}

export function updateProduct(params, id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.createProductRequest());
    try {
      const { data } = await api.patch(`${ENDPOINTS.PRODUCT}${id}/`, params);

      dispatch(actionCreators.createProductSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.createProductFail(error));
      return Promise.reject(error);
    }
  };
}

export function upload(image, endpointKey) {
  const endpoints = {
    product: ENDPOINTS.PRODUCT_UPLOAD,
    publication: ENDPOINTS.PUBLICATION_UPLOAD,
  };
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.uploadRequest());
    try {
      const param = new FormData();
      param.append('photo', image);
      const { data } = await api.post(endpoints[endpointKey], param, {
        multi: true,
      });

      dispatch(actionCreators.uploadSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.uploadFail(error));
      return Promise.reject(error);
    }
  };
}

export function multiUpload(images, endpointKey) {
  const endpoints = {
    product: ENDPOINTS.PRODUCT_UPLOAD,
    publication: ENDPOINTS.PUBLICATION_UPLOAD,
  };

  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.multiUploadRequest());
    const transformPromises = (param) =>
      api.post(endpoints[endpointKey], param, {
        multi: true,
      });

    try {
      const promises = images.map((photo) => {
        const param = new FormData();
        param.append('photo', photo);
        return transformPromises(param);
      });
      const data = await Promise.all(promises).then((res) => {
        dispatch(actionCreators.multiUploadSuccess(res));
        return res.map((resData) => resData.data);
      });

      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.multiUploadFail(error || e));
      return Promise.reject(error || e);
    }
  };
}

export const actions = {
  ...actionCreators,
  getCategoryParameters,
  changeCategoryParametersAsync,
  createProduct,
  updateProduct,
  upload,
  multiUpload,
};
