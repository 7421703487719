import { defineMessages } from 'react-intl';

export const scope = 'Footer';

export default defineMessages({
  aboutTezSat: {
    id: `${scope}.aboutTezSat`,
    defaultMessage:
      'Tez Sat (Тез Сат) - это сервис бесплатных объявлений Кыргызстана. Сервис позволяет подавать бесплатные объявления о купле, продаже, аренде, обмене как б/у, так и новых товаров и услуг. Продажа авто, домов, квартир, электроники, поиск работы и услуг является неотъемлемой частью повседневного бытия.',
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: 'Узнать больше',
  },
  downloadApp: {
    id: `${scope}.downloadApp`,
    defaultMessage: 'Скачать приложение',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'Служба поддержки',
  },
  sitemap: {
    id: `${scope}.sitemap`,
    defaultMessage: 'карта сайта',
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'О нас',
  },
  policy: {
    id: `${scope}.policy`,
    defaultMessage: 'Пользовательское соглашение',
  },
  reserved: {
    id: `${scope}.reserved`,
    defaultMessage: `Все права защищены`,
  },
  forbidden: {
    id: `${scope}.forbidden`,
    defaultMessage: 'Запрещенные товары',
  },
});
