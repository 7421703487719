import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { compose } from 'redux';
import { getObjectLineFromTree } from 'common/helpers';

import { makeStyles } from '@material-ui/styles';
import {
  SwipeableDrawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VerifiedIcon from 'assets/images/verifiedIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: '101%',
      backgroundColor: '#fafafa',
    },
  },
  menuClose: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '3px',
    color: theme.palette.primary.blue,
  },
  goBack: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '3px',
    color: theme.palette.primary.blue,
  },
  menuItem: {
    position: 'relative',
    fontSize: 16,
  },
  selectedItemIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    transform: 'translateY(-50%)',
  },
  submit: {
    position: 'fixed',
    bottom: theme.spacing(1.8),
    left: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
}));

function OptionDrawer({
  open,
  title,
  options,
  onClose,
  onSelect,
  selectedId,
  intl,
  ...rest
}) {
  const [displayedChildren, setDisplayedChildren] = useState(null);
  const [openedOptionLine, setOpenedOptionLine] = useState([]);
  const classes = useStyles();
  const isSelected = openedOptionLine.length !== 0;
  const handleClose = () => {
    setOpenedOptionLine([]);
    setDisplayedChildren(null);
    onClose();
  };

  const handleOptionClick = (opt) => {
    if ('children' in opt === false) {
      onSelect(opt);
      handleClose();
      return;
    }
    setDisplayedChildren(opt.children);
    setOpenedOptionLine((prevState) => [...prevState, opt]);
  };

  const displayOptionsWith = useCallback(
    (id) => {
      if (!id) return;
      const optLine = getObjectLineFromTree(options, id);
      const newOptions = optLine[optLine.length - 2]?.children;

      if (!newOptions) return;

      setDisplayedChildren(newOptions);
      setOpenedOptionLine(optLine.slice(0, optLine.length - 1));
    },
    [options],
  );

  useEffect(() => {
    displayOptionsWith(selectedId);
  }, [selectedId, displayOptionsWith]);

  const handleBack = () => {
    const preLastOption = openedOptionLine[openedOptionLine.length - 2];
    setDisplayedChildren(preLastOption?.children);
    setOpenedOptionLine((prevState) => {
      const newState = prevState.slice(0, prevState.length - 1);
      return newState;
    });
  };

  const handleSubmit = () => {
    const selectedOpt = openedOptionLine[openedOptionLine.length - 1];
    if (!selectedOpt) return;
    onSelect(selectedOpt);
    handleClose();
  };

  const OptionsList = ({ options, selectedId, style, ...rest }) => {
    const styles = {
      paddingBottom: isSelected ? '65px' : 0,
    };
    const isOptSelected = (opt) => opt.id === selectedId;
    return (
      <List
        {...rest}
        style={style ? { ...style, ...styles } : styles}
      >
        {options.map((opt) => {
          return (
            <React.Fragment key={opt.id}>
              <ListItem
                className={classes.menuItem}
                onClick={() => handleOptionClick(opt)}
                key={opt.id}
              >
                {opt.icon && (
                  <ListItemIcon>
                    <img
                      src={opt.icon}
                      alt=""
                      width="30"
                      height="30"
                      loading="lazy"
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  style={{
                    fontWeight: isOptSelected(opt) ? 'bold' : 'normal',
                  }}
                >
                  {opt[`title_${intl.locale}`]}
                </ListItemText>
                {isOptSelected(opt) && (
                  <img
                    className={classes.selectedOptIcon}
                    src={VerifiedIcon}
                    alt="selected"
                  />
                )}
              </ListItem>
              <Divider
                style={{
                  backgroundColor: 'rgb(196, 196, 196)',
                }}
              />
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return (
    <SwipeableDrawer
      variant="persistent"
      anchor="right"
      open={open}
      onOpen={() => {}}
      className={classes.root}
      onClose={handleClose}
      {...rest}
    >
      <Box>
        <Box
          padding="10px"
          position="relative"
          borderBottom="1px solid #C4C4C4"
        >
          {isSelected && (
            <IconButton
              className={classes.goBack}
              onClick={handleBack}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          )}
          <Typography
            variant="h3"
            component="h3"
            align="center"
          >
            {title}
          </Typography>
          <IconButton
            onClick={handleClose}
            className={classes.menuClose}
          >
            <Close fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      {options && (
        <OptionsList
          selectedId={selectedId}
          options={displayedChildren || options}
        />
      )}

      {isSelected && (
        <Button
          onClick={handleSubmit}
          className={classes.submit}
          variant="contained"
          color="secondary"
        >
          Применить
        </Button>
      )}
    </SwipeableDrawer>
  );
}

OptionDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  selectedId: PropTypes.number,
  title: PropTypes.string,
  intl: intlShape,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default compose(memo)(OptionDrawer);
