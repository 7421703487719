import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

export const FirebaseContext = createContext();

const isFirebaseProd = process.env.REACT_APP_FIREBASE_IS_PROD;

const firebaseProdConfig = {
  apiKey: 'AIzaSyAiQxyfUi3sRD6gPeLGBDMleJt07nXb620',
  authDomain: 'tezsat-a824d.firebaseapp.com',
  databaseURL: 'https://tezsat-a824d.firebaseio.com',
  projectId: 'tezsat-a824d',
  storageBucket: 'tezsat-a824d.appspot.com',
  messagingSenderId: '875751405160',
  appId: '1:875751405160:web:82b3d793df0b32ca3e2ddd',
  measurementId: 'G-5BDKZDG4MQ',
};

const firebaseDevConfig = {
  apiKey: 'AIzaSyCjkXW04ZNrtYvmZlFrQaKnyND9yqtxevQ',
  authDomain: 'tezsat-dev.firebaseapp.com',
  databaseURL: 'https://tezsat-dev.firebaseio.com',
  projectId: 'tezsat-dev',
  storageBucket: 'tezsat-dev.appspot.com',
  messagingSenderId: '761908701033',
  appId: '1:761908701033:web:ccb3e516495c56b61cfff8',
  measurementId: 'G-6CSD6DB46L',
};

const firebaseConfig =
  isFirebaseProd === 'false' ? firebaseDevConfig : firebaseProdConfig;

export default function ClientFirebase({ children }) {
  const [auth, setAuth] = useState(null);
  const [firestore, setFirestore] = useState(null);
  const [provider, setProvider] = useState(null);
  const [fbProvider, setFbProvider] = useState(null);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    setAuth(firebase.auth());
    setFirestore(firebase.firestore());
    setProvider(new firebase.auth.GoogleAuthProvider());
    setFbProvider(new firebase.auth.FacebookAuthProvider());
  }, []);

  useEffect(() => {
    if (fbProvider) {
      fbProvider.setCustomParameters({
        display: 'popup',
      });
    }
  }, [fbProvider]);

  function fetchMySaleChats(myID) {
    return firestore.collection('chats').where('seller_id', '==', myID);
  }

  function fetchMyPurchaseChats(myID) {
    return firestore.collection('chats').where('buyer_id', '==', myID);
  }

  function activeChat(chatID) {
    return firestore.collection('chats').doc(chatID);
  }

  const updatePassword = async (password) => {
    await auth.currentUser.updatePassword(password);
  };

  const values = {
    fetchMySaleChats,
    fetchMyPurchaseChats,
    activeChat,
    updatePassword,
    auth,
    firestore,
  };

  if (!auth) return null;

  return (
    <FirebaseContext.Provider value={values}>
      {children}
    </FirebaseContext.Provider>
  );
}

ClientFirebase.propTypes = {
  children: PropTypes.node,
};

export function useFirebaseState() {
  const state = React.useContext(FirebaseContext);

  if (state === undefined) {
    throw new Error('useFirebaseState must be used within a FirebaseProvider');
  }

  return state;
}
