import { defineMessages } from 'react-intl';

export const scope = 'Header';

export default defineMessages({
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Войти',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Регистрация',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Выйти',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Аккаунт',
  },
  myProducts: {
    id: `${scope}.myProducts`,
    defaultMessage: 'Мои объявления',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Помощь',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Выход',
  },
  policy: {
    id: `${scope}.policy`,
    defaultMessage: 'Пользовательское соглашение',
  },
  connectBusiness: {
    id: `${scope}.connectBusiness`,
    defaultMessage: 'Подключить Tez Sat Business',
  },
  business: {
    id: `${scope}.business`,
    defaultMessage: 'Бизнес',
  },
  forBusiness: {
    id: `${scope}.forBusiness`,
    defaultMessage: 'Для бизнеса',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Блог',
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'О нас',
  },
  assistance: {
    id: `${scope}.assistance`,
    defaultMessage: 'Помощь',
  },
  supportService: {
    id: `${scope}.supportService`,
    defaultMessage: 'Служба поддержки',
  },
  questionsAndAswers: {
    id: `${scope}.questionsAndAswers`,
    defaultMessage: 'Вопросы и ответы',
  },
  russian: {
    id: `${scope}.russian`,
    defaultMessage: 'Русский',
  },
  kyrgyz: {
    id: `${scope}.kyrgyz`,
    defaultMessage: 'Кыргыз тили',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'RU',
  },
  favorites: {
    id: `${scope}.favorites`,
    defaultMessage: 'Избранное',
  },
  messages: {
    id: `${scope}.messages`,
    defaultMessage: 'Сообщения',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Уведомления',
  },
  logoutMessage: {
    id: `${scope}.logoutMessage`,
    defaultMessage: 'Вы уверены, что хотите выйти?',
  },
  logoutCancel: {
    id: `${scope}.logoutCancel`,
    defaultMessage: 'Отмена',
  },
  logoutAgree: {
    id: `${scope}.logoutAgree`,
    defaultMessage: 'выход',
  },
});
