import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CodeInput from 'react-verification-code-input';
import { injectIntl, intlShape } from 'react-intl';

import messages from 'messages/Auth';
// Mui
import { Box, Modal, Typography } from '@material-ui/core';
import LoadingButton from 'components/LoadingButton';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import arrowLeft from '../../assets/images/arrow-left.svg';
import x from '../../assets/images/x.svg';
import { useEffect } from 'react';
import { authActions } from 'services/auth';
import NewPassword from './NewPassword';
import { useAlert } from 'react-alert';
import { isEmail } from './validation/validation';
import { requester } from 'services/requester';
import { profileActions } from 'states/Profile';

function ConfirmPhoneComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();

  const {
    onClose,
    onSubmit,
    error,
    isOpen,
    intl,
    setUserConfirm,
    isPasswordRecovery,
    phone,
    isPhoneChange,
  } = props;

  const [code, setCode] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  let timerCount = 60;
  let isActive = true;
  const [timer, setTimer] = useState(timerCount);

  let timeout;
  const handleTimer = () => {
    clearInterval(timeout);
    timeout = setInterval(() => {
      if (timerCount <= 0) {
        isActive = true;
        clearInterval(timeout);
      }
      setTimer(timerCount);
      return (timerCount -= 1);
    }, 1000);
  };

  useEffect(() => {
    handleTimer();
    return () => clearInterval(timeout);
  }, []);

  const handleChange = (value) => {
    setCode(value.split(''));
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (isPhoneChange) {
      // FIX_ME move to redux
      try {
        await requester.post('/auth/v2/check_code/', {
          code: code.join(''),
        });

        const formData = new FormData();
        formData.append('phone', `+${phone}`);
        await dispatch(profileActions.updateProfile(formData));

        onClose();
        alert.success('Успешно сохранено');
      } catch (e) {
        alert.error('Неверный код подтверждения');
        setCode([]);
      }
      setLoading(false);
      return;
    }

    if (isPasswordRecovery) {
      dispatch(authActions.checkCode({ code: +code.join('') }))
        .then((status) => {
          if (status) {
            setShowNewPassword(true);
          } else {
            alert.error(intl.formatMessage({ ...messages.invalidCode }));
          }
        })
        .catch(() => {
          alert.error(intl.formatMessage({ ...messages.invalidCode }));
        });
    } else {
      await onSubmit(code)
        .then((res) => res && onClose())
        .catch((status) => console.error(status));
    }
    setLoading(false);
  };

  const handleSendCodeAgain = async () => {
    setCode([]);
    if (timer > 0 || !isActive) return;

    isActive = false;
    if (isPhoneChange) {
      handleTimer();
      // FIX_ME move to redux
      await requester.post('auth/v1/update_phone/', {
        phone: `+${phone}`,
      });
    } else {
      dispatch(
        authActions.sendCode(
          {
            login: localStorage.getItem('tezsatRecoveryPhone'),
          },
          isPasswordRecovery,
        ),
        handleTimer(),
      );
    }

    return (timerCount = 60);
  };

  if (showNewPassword) {
    return (
      <NewPassword
        intl={intl}
        onClose={onClose}
        code={+code.join('')}
      />
    );
  } else {
    return (
      <Modal
        open={isOpen}
        onClose={() => {
          setUserConfirm(false);
          onClose();
        }}
        className={classes.modal}
      >
        <Box
          sx={{
            padding: '48px 58px !important',
            maxWidth: '440px !important',
            background: '#fff',
          }}
          className={classes.root}
        >
          <Box
            onClick={() => {
              setUserConfirm(false);
              onClose();
            }}
            sx={{
              position: 'absolute',
              right: '24px',
              top: '24px',
              width: '32px',
              height: '32px',
              cursor: 'pointer',
            }}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={x}
              alt="x"
            />
          </Box>
          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Typography className={classes.confirmTitle}>
              {intl.formatMessage({ ...messages.confirmTitle })}
            </Typography>
            <Typography className={classes.confirmText}>
              {isEmail.test(localStorage.getItem('tezsatRecoveryPhone')) ? (
                <>
                  {intl.formatMessage({ ...messages.confirmEmail })}
                  <Typography style={{ fontWeight: 700 }}>
                    {localStorage.getItem('tezsatRecoveryPhone')}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography style={{ fontWeight: 700 }}>
                    {localStorage.getItem('tezsatRecoveryPhone')}
                  </Typography>
                  {intl.formatMessage({ ...messages.confirmText })}
                </>
              )}
            </Typography>
            <CodeInput
              type="number"
              fields={6}
              key={code.join('')}
              values={code}
              onChange={handleChange}
              className={classes.codeInput}
              fieldWidth={40}
              fieldHeight={40}
              autoFocus
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: timer !== 0 ? 'black' : '#0051BA',
                height: '40px',
                alignItems: 'center',
                cursor: timer <= 0 && isActive ? 'pointer' : 'auto',
              }}
              onClick={handleSendCodeAgain}
            >
              <Typography>
                {timer <= 0
                  ? 'Запросить код повторно'
                  : `Запросить код повторно через: ${timer}`}
              </Typography>
            </Box>

            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              buttonClass={classes.submitButton}
              fullwidth
              loading={isLoading}
              disabled={isLoading || code.length !== 6}
            >
              {intl.formatMessage({ ...messages.confirm })}
            </LoadingButton>
            {error || null}
          </form>
        </Box>
      </Modal>
    );
  }
}

ConfirmPhoneComponent.propTypes = {
  phone: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  intl: intlShape,
  isPhoneChange: PropTypes.bool.isRequired,
};

export default injectIntl(ConfirmPhoneComponent);
