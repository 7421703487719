import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import onClickOutside from 'react-onclickoutside';
import clsx from 'clsx';
import { injectIntl, intlShape } from 'react-intl';
import { useStyles } from './styles';

function MultipleSelect(props) {
  const { value, options, onChange, label, intl, style, className } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const selectedOptions = options.filter((opt) =>
    value.find((id) => id === opt?.id),
  );
  const optionTitles = selectedOptions.map(
    (opt) => opt[`title_${intl.locale}`],
  );

  MultipleSelect[`handleClickOutside_${label}`] = () => setOpen(false);

  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
    >
      <Box
        onClick={() => setOpen((prev) => !prev)}
        className={classes.label}
      >
        <Typography
          component="p"
          className={classes.labelParagraph}
        >
          {optionTitles.length > 0 ? optionTitles.join(', ') : label}
        </Typography>
        <KeyboardArrowDown
          className={clsx(classes.labelIcon, open ? classes.openedIcon : null)}
        />
      </Box>

      {open && (
        <Paper
          className={classes.paper}
          elevation={0}
        >
          <List className={classes.list}>
            {options.map((opt) => {
              const isOptionSelected = !!value.find((id) => id === opt?.id);
              return (
                <ListItem
                  button
                  className={classes.listItem}
                  style={{
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  onClick={() => onChange(opt)}
                  value={opt?.id}
                  key={opt?.id}
                >
                  <Checkbox
                    checked={isOptionSelected}
                    // onChange={() => onChange(filter, opt)}
                    className={classes.checkbox}
                  />
                  <Typography component="p">
                    {opt[`title_${intl.locale}`]}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}
    </div>
  );
}

MultipleSelect.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  intl: intlShape,
};

MultipleSelect.defaultProps = {
  value: [],
};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) =>
    MultipleSelect[`handleClickOutside_${props.label}`],
};

export default injectIntl(onClickOutside(MultipleSelect, clickOutsideConfig));
