import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accardion: {
    margin: 0,
    padding: 0,
    background: 'transparent',
    boxShadow: 'none',

    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 10,
    },

    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: 0,
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',

      '& img': {
        marginRight: 12,
      },
    },

    '& .MuiAccordionDetails-root': {
      padding: 0,
    },

    '& .MuiIconButton-root': {
      padding: 0,
      paddingRight: 12,
    },
  },
  heading: {
    color: theme.palette.primary.blue,
  },
  // active: {
  //   color: theme.palette.primary.blue,
  // },
  button: {
    position: 'relative',
    minWidth: 30,
    backgroundColor: 'rgba(0, 81, 186, 0.11)',
    textTransform: 'none',
    padding: '0 7px',
    color: 'inherit',
    '& .MuiTypography-body1': {
      lineHeight: '20px',
      fontSize: '0.75rem',
    },
  },
  text: {
    '& .MuiTypography-body1': {
      fontSize: '0.88rem',
    },
  },
  grow: {
    position: 'absolute',
    top: '222%',
    minWidth: 140,
    padding: '5px 0',
    transformOrigin: 'bottom left',
    zIndex: '10000000',
  },
  invisibleBlock: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    zIndex: '99999 !important',
    opacity: '0',
  },
}));
