/*
 *
 * AdsPage constants
 *
 */

export const ACTION_TYPES = {
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  CREATE_PUBLICATION: 'CREATE_PUBLICATION',
  UPLOAD: 'UPLOAD',
  MULTI_UPLOAD: 'MULTI_UPLOAD',
  GET_CATEGORY_PARAMETERS: 'GET_CATEGORY_PARAMETERS',
  CHANGE_CATEGORY_PARAMETERS: 'CHANGE_CATEGORY_PARAMETERS',
};

export const ENDPOINTS = {
  PRODUCT: '/product/v1/personal/products/',
  PUBLICATION: '/publication/v1/personal/publications/',
  PRODUCT_UPLOAD: '/product/v1/personal/photo-upload/',
  PUBLICATION_UPLOAD: '/publication/v1/personal/photo-upload/',
  CATEGORY_PARAMETERS: (id) => `/category/v1/categories/${id}/parameters/`,
};
