/*
 *
 * AdsPage reducer
 *
 */
import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  product: null,
  categoryParameters: [],
  isParamsLoading: false,
};

const isLoading = boolReducer(
  [combineActions(actions.createProductRequest)],
  [combineActions(actions.createProductSuccess, actions.createProductFail)],
);

const isParamsLoading = boolReducer(
  [combineActions(actions.getCategoryParametersRequest)],
  [
    combineActions(
      actions.getCategoryParametersSuccess,
      actions.getCategoryParametersFail,
    ),
  ],
);

const data = handleActions(
  {
    [actions.createProductSuccess]: (state, { payload }) => ({
      ...state,
      product: payload,
    }),
    [actions.getCategoryParametersSuccess]: (state, { payload }) => ({
      ...state,
      categoryParameters: payload,
    }),
    [actions.changeCategoryParameters]: (state, { payload }) => ({
      ...state,
      categoryParameters: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isLoading,
  isParamsLoading,
});

export const key = 'post';

export const postPageSelectors = {
  newProduct: (state) => (state.postPage ? state.postPage.data.product : null),
  categoryParameters: (state) =>
    getStateValue(state, key, 'categoryParameters'),
  isLoading: (state) => getStateValue(state, key, 'isLoading'),
  isParamsLoading: (state) =>
    getStateValue(state, key, 'isParamsLoading', true),
};
