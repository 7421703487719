import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';

import ProductListContainer from 'containers/ProductList';
import BusinessMessages from 'messages/BusinessProfile';

// components

import AdModal from 'components/AdModal';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { UserContext } from '../../providers/UserProvider';
import { useStyles } from './styles';
import { productActions } from '../../states/Products';

// Containers

const HomePage = ({ intl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [adModal, setAdModal] = useState(true);
  const [isOpenModal, setIsOpenAdModal] = useState(false);
  const { user } = useContext(UserContext);

  const closeOrOpenAdModal = useCallback(() => {
    setAdModal(!adModal);
  }, [adModal]);

  const isOpenAdModal = useCallback(async () => {
    const res = await dispatch(productActions.fetchIsOpenModal());
    setIsOpenAdModal(res.status);
  }, [dispatch]);

  useEffect(() => {
    isOpenAdModal();
  }, [isOpenAdModal]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Tez Sat - сервис бесплатных объявлений Кыргызстана.</title>
        <meta
          name="description"
          content="Tez Sat - это сервис бесплатных объявлений Кыргызстана.
Сервис позволяет подавать бесплатные объявления о купле, продаже, аренде, обмене как б/у, так и новых товаров и услуг.
Продажа авто, домов, квартир, электроники, поиск работы и услуг является неотъемлемой частью повседневного бытия."
        />
      </Helmet>
      {isOpenModal && !user?.info?.is_business ? (
        <AdModal
          intl={intl}
          open={adModal}
          messages={BusinessMessages}
          close={closeOrOpenAdModal}
        />
      ) : (
        <></>
      )}
      <ProductListContainer />
    </div>
  );
};

HomePage.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(HomePage);
