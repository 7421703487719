import { Typography } from '@material-ui/core';
import React from 'react';

const PriceProduct = ({ product, messages, intl }) => {
  return (
    <Typography component={'p'}>
      {parseFloat(product?.initial_price) > 0 ? (
        <>
          {intl.formatNumber(parseFloat(product?.initial_price))}{' '}
          {product?.currency === 'KGS' ? 'СОМ' : '$'}
        </>
      ) : (
        intl.formatMessage({ ...messages.negotiable })
      )}
    </Typography>
  );
};

export default PriceProduct;
