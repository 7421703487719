// URL
const dev = process.env.REACT_APP_BASE_URL;
const prod = process.env.REACT_APP_BASE_URL;
export const BASE_URL = prod || dev;
export const BASE_SOCKET_URL = process.env.REACT_APP_BASE_WEBSOCKETS_URL;
export const MEDIA_URL = 'https://media.tezsat.kg/media/products/photo/';
export const APP_URL = 'https://tezsat.kg';
const scope = '__ts:';
export const COOKIE_KEYS = {
  TOKEN: `${scope}token`,
  REFRESH: `${scope}refresh`,
};

// Erros
export const ERROR_MESSAGES = {
  NETWORK_ERR: 'Network error',
};

// Country Codes for phone input
export const countryCodes = [
  'kg',
  'ru',
  'kz',
  'az',
  'am',
  'by',
  'lv',
  'lt',
  'md',
  'cn',
  'tj',
  'tm',
  'uz',
  'ua',
];

export const paths = {
  CATEGORY_PAGE: (titleSlug) => `/category/${titleSlug}`,
  aboutUs: '/about',
};
