import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import BusinessProduct from 'components/BusinessProduct';
import { data } from 'services/auth';

const Index = (props) => {
  const {
    intl,
    products,
    AllProducts,
    setProducts,
    saveChanges,
    isSelect,
    selected,
    isMyProfile,
    withoutSwitch,
    fetchMore,
    isFavoritePage,
    data,
    id,
    newProductsTab,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {products &&
        Object.keys(products).map((id) => (
          <div
            key={id}
            className={classes.rootProduct}
          >
            <BusinessProduct
              intl={intl}
              product={products[id]}
              setProducts={setProducts}
              userId={products[id]?.user}
              saveChanges={saveChanges}
              isSelect={isSelect}
              selected={selected}
              isMyProfile={isMyProfile}
              withoutSwitch={withoutSwitch}
              fetchMore={fetchMore}
              isFavoritePage={isFavoritePage}
              data={products}
              id={id}
              newProductsTab={newProductsTab}
            />
          </div>
        ))}
    </div>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  products: PropTypes.array,
  saveChanges: PropTypes.func,
  isSelect: PropTypes.bool,
  selected: PropTypes.func,
  isMyProfile: PropTypes.bool,
  withoutSwitch: PropTypes.bool,
  fetchMore: PropTypes.func,
  isFavoritePage: PropTypes.bool,
};

export default Index;
