import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';

// Mui
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { useStyles } from './styles';
import { loginSchema } from './schema';
import { useDispatch } from 'react-redux';
import { authActions } from 'services/auth';
import ConfirmPhoneComponent from './ConfirmPhone';
import { useAlert } from 'react-alert';
import { Box, Button } from '@material-ui/core';
import arrowLeft from '../../assets/images/arrow-left.svg';
import x from '../../assets/images/x.svg';
import { checkLogin, isEmail } from './validation/validation';

const initValues = {
  login: '',
};
function LoginComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { setType, intl, messages, confirmPhone, onCloseConfirmPhome } = props;

  const [state, setState] = useState({
    isValid: false,
    values: initValues,
    startValues: {},
    touched: {},
    errors: {},
  });
  const [showConfirmPhone, setShowConfirmPhone] = useState(false);

  useEffect(() => {
    const errors = validate(state.values, loginSchema);

    setState((prevState) => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {},
    }));

    return () => null;
  }, [state.values]);

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value.length === 1 && !!+value ? `+${value}` : value,
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
    localStorage.setItem('tezsatRecoveryPhone', value);
  };

  const handlePassRecoverySubmit = (e, login) => {
    e.preventDefault();
    login === ''
      ? alert.error(intl.formatMessage({ ...messages.emptyEmail }))
      : dispatch(authActions.sendCode({ login }, true))
          .then(() => {
            setShowConfirmPhone(true);
          })
          .catch(() => alert.error(intl.formatMessage({ ...messages.error })));
  };

  const stylesForBoxs = {
    position: 'absolute',
    top: '12px',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  };

  if (showConfirmPhone) {
    return (
      <ConfirmPhoneComponent
        isOpen={true}
        onSubmit={confirmPhone}
        setUserConfirm={setShowConfirmPhone}
        onClose={onCloseConfirmPhome}
        isPasswordRecovery={true}
      />
    );
  } else {
    return (
      <>
        <Box
          onClick={() => setType('login')}
          sx={stylesForBoxs}
          style={{ left: '12px' }}
        >
          <img
            className={classes.imgSize}
            src={arrowLeft}
            alt="arrowLeft"
          />
        </Box>
        <Box
          onClick={onCloseConfirmPhome}
          sx={stylesForBoxs}
          style={{ right: '12px' }}
        >
          <img
            className={classes.imgSize}
            src={x}
            alt="x"
          />
        </Box>
        <div className={classes.loginRoot}>
          <form
            onSubmit={(e) => handlePassRecoverySubmit(e, state?.values?.login)}
            className={classes.form}
            style={{
              paddingTop: '12px',
            }}
          >
            <Typography className={classes.passwordRecoveryTitle}>
              {intl.formatMessage({ ...messages.passwordRecoveryTitle })}
            </Typography>
            <Typography className={classes.passwordRecoveryTitle}>
              {intl.formatMessage({
                ...messages.passwordRecoveryTitleSecondPiece,
              })}
            </Typography>
            <Typography className={classes.passwordRecoveryTitle}>
              {intl.formatMessage({ ...messages.passwordRecoverySubTitle })}
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              name="login"
              value={state.values.login}
              type={+state.values.login ? 'tel' : 'email'}
              error={
                checkLogin.test(state.values.login)
                  ? false
                  : state?.touched?.login
                  ? true
                  : false
              }
              placeholder="Номер телефона или email"
              helperText={
                !state?.touched?.login ||
                checkLogin.test(state.values.login) ? null : (
                  <Typography
                    className={classes.extraTextPassword}
                    variant="caption"
                  >
                    {isEmail.test(state.values.login)
                      ? intl.formatMessage({ ...messages.wrongEmail })
                      : intl.formatMessage({ ...messages.phoneFormat })}
                  </Typography>
                )
              }
              onChange={handleChange}
              className={classes.textField}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: '30px' }}
                className={classes.submitButton}
              >
                {intl.formatMessage({ ...messages.send })}
              </Button>
            </Box>
          </form>
        </div>
      </>
    );
  }
}

LoginComponent.propTypes = {
  onSubmit: PropTypes.func,
  setType: PropTypes.func,
  intl: PropTypes.object,
  messages: PropTypes.object,
};

export default LoginComponent;
