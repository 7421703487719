import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './styles';

export default function Skeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiSkeleton
        variant="rect"
        width="100%"
        height={180}
        className={classes.skeletImg}
      />
      <div className={classes.skeletRow}>
        <MuiSkeleton
          variant="circle"
          width={30}
          height={30}
        />
        <MuiSkeleton
          variant="text"
          width="50%"
          height={20}
          style={{
            marginLeft: 10,
          }}
        />
      </div>
      <MuiSkeleton
        variant="rect"
        width="92%"
        height={10}
        className={classes.skeletText}
      />
      <MuiSkeleton
        variant="rect"
        width="92%"
        height={10}
        className={classes.skeletText}
      />
    </div>
  );
}
