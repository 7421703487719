import React from 'react';
import ReactStars from 'react-rating-stars-component/dist/react-stars';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

const BusinessNameProduct = ({ product, businessName }) => {
  const classes = useStyles();
  return (
    <Box className={classes.businessNameProduct}>
      <Typography component={'p'}>{businessName}</Typography>
      <ReactStars
        edit={false}
        half={true}
        value={product?.rating}
        size={12}
      />
    </Box>
  );
};

export default React.memo(BusinessNameProduct);
