import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  label: {
    position: 'relative',
    maxHeight: '100%',
    padding: `${theme.spacing(0.9)}px ${theme.spacing(4.5)}px ${theme.spacing(
      0.9,
    )}px ${theme.spacing(1.2)}px`,
    border: '1px solid #C4C4C4',
    borderRadius: 10,
    cursor: 'pointer',
    color: '#666666',
  },
  labelIcon: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',

    transition: '.5s all',
  },
  labelParagraph: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`,

    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
  },
  openedIcon: {
    transform: 'translateY(-50%) rotate(180deg)',
  },
  list: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  listItem: {},
  select: {
    margin: '0 auto',

    '& fieldset': {
      // border: '1px solid rgba(0, 0, 0, 0.23) !important'
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  paper: {
    position: 'absolute',
    top: 37,
    left: 0,
    right: 0,
    zIndex: 13000,
  },
}));
