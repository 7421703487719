import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// MUI
import { makeStyles } from '@material-ui/styles';
import { MenuItem } from '@material-ui/core';

import { intlShape } from 'react-intl';

import VerifiedIcon from 'assets/images/verifiedIcon.svg';
import { searchActions, searchSelectors } from 'states/Search';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerHeader, SubmitButton } from './components';

const useStyles = makeStyles({
  filterOption: {
    borderBottom: '1px solid #F4F4F4',
    fontSize: 'inherit',
  },
  selectedOption: {
    fontWeight: 'bold',
    justifyContent: 'space-between',
  },
  optionsList: {
    paddingBottom: 65,
    overflowY: 'auto',
  },
});

function Select(props) {
  const classes = useStyles();
  const { openedParam, onClose, intl } = props;

  const options = useSelector(searchSelectors.options);

  const [potentialOptions, setPotentialOptions] = useState([...options]);

  const dispatch = useDispatch();

  const handleSubmit = (opts) => {
    dispatch(searchActions.changeOptionsAsync(opts));
    onClose();
  };

  const isSelectedOption = (opt) =>
    !!potentialOptions.find((option) => option.id === opt.id);

  const toggleOption = (opt) =>
    setPotentialOptions((opts) => {
      const targetOptIndex = opts.findIndex((currOpt) => currOpt.id === opt.id);
      const newOpts =
        targetOptIndex !== -1
          ? [
              ...opts.slice(0, targetOptIndex),
              ...opts.slice(targetOptIndex + 1),
            ]
          : [...opts, opt];
      return newOpts;
    });

  const Option = ({ opt }) => {
    const selected = isSelectedOption(opt);
    return (
      <MenuItem
        className={clsx(
          classes.filterOption,
          selected ? classes.selectedOption : null,
        )}
        onClick={() => toggleOption(opt)}
        button
      >
        {opt[`title_${intl.locale}`]}
        {selected && (
          <img
            src={VerifiedIcon}
            alt="verified"
          />
        )}
      </MenuItem>
    );
  };

  return (
    <>
      <DrawerHeader
        title={openedParam[`title_${intl.locale}`]}
        onClear={() => setPotentialOptions([])}
        onClose={onClose}
        intl={intl}
      />
      <div className={classes.optionsList}>
        {openedParam.options.map((opt) => (
          <Option
            opt={opt}
            key={opt.id}
          />
        ))}
      </div>
      <SubmitButton onSubmit={() => handleSubmit(potentialOptions)} />
    </>
  );
}

Select.propTypes = {
  openedParam: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape,
};

export default Select;
