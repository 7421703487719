import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import messages from 'messages/Filter';
import { useStyles } from '../styles';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { Link } from 'react-router-dom';

function SelectedCategory(props) {
  const { category, intl, onClick, categoryLine } = props;

  const isMobile = useMediaQuery('(max-width: 720px)');
  const classes = useStyles();

  const children = useMemo(() => {
    if (!category?.children?.length > 0) {
      return null;
    }
    const filteredChildren = category.children
      .slice()
      ?.sort(
        (categoryA, categoryB) =>
          categoryB?.product_count - categoryA?.product_count,
      );
    return filteredChildren;
  }, [category]);

  if (isMobile) {
    return (
      <Mobile
        classes={classes}
        intl={intl}
        onClick={onClick}
        categories={children}
      />
    );
  }
  return (
    <Desktop
      classes={classes}
      intl={intl}
      categoryLine={categoryLine}
      onClick={onClick}
      categories={children}
      messages={messages}
    />
  );
}

SelectedCategory.propTypes = {
  intl: PropTypes.object,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onClick: PropTypes.func,
  categoryLine: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(SelectedCategory);
