import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';
// import { useTheme } from '@emotion/react'
import { useTheme } from '@material-ui/core';

function Skeleton() {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: theme.spacing(1),
      }}
    >
      {new Array(5).fill(null).map((_, idx) => (
        <div
          key={idx}
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          <MuiSkeleton
            variant="rect"
            height={28}
            width="50%"
            style={{
              borderRadius: '6px',
              marginBottom: theme.spacing(1),
            }}
          />
          <MuiSkeleton
            variant="rect"
            height={34}
            width="100%"
            style={{
              borderRadius: '6px',
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default Skeleton;
