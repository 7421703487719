import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import {
  ListItem,
  ListItemText,
  List,
  Paper,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  ChevronLeft,
} from '@material-ui/icons';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import messages from 'messages/Filter';

import { useStyles } from './styles';

function NodeSelect(props) {
  const classes = useStyles();
  const {
    nodes,
    intl,
    name,
    selected,
    primaryText,
    secondaryText,
    onSelect,
    fullPage,
    withDivider,
    type,
    className,
    inputProps,
  } = props;

  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [history, setHistory] = useState([]);

  NodeSelect[`handleClickOutside_${name}`] = () => setOpen(false);

  const list = useMemo(() => {
    if (current) return current.children;
    return nodes;
  }, [current, nodes]);

  const handleClick = useCallback(
    (node) => {
      if (node.children && node.children.length) {
        setCurrent(node);
        setHistory((prev) => [node, ...prev]);
      } else {
        onSelect({
          target: {
            name,
            value: node.id,
          },
        });
        setOpen(false);
      }
    },
    [name, onSelect],
  );

  const handleBack = useCallback(() => {
    setCurrent(history[1]);
    setHistory((prev) => prev.filter((_, idx) => idx !== 0));
  }, [history]);

  const handleSelectCurrent = useCallback(() => {
    onSelect({
      target: {
        name,
        value: current.id,
      },
    });
    setOpen(false);
  }, [current, onSelect, name]);

  return (
    <div className={clsx(classes.root, className || null)}>
      {type === 'select' ? (
        <Box
          className={clsx(classes.input, {
            [classes.opened]: open,
          })}
          onClick={() => setOpen((prev) => !prev)}
          {...inputProps}
        >
          <Typography component="p">
            {selected === 'all'
              ? intl.formatMessage({ ...messages.all })
              : selected[`title_${intl.locale}`]}
          </Typography>
          <KeyboardArrowDown />
        </Box>
      ) : (
        <ListItem
          button
          onClick={() => setOpen((prev) => !prev)}
          className={clsx(classes.rootListItem, 'triggerItem')}
        >
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
          />
        </ListItem>
      )}
      {open ? (
        <Paper
          className={clsx(classes.menu, {
            [classes.fullPage]: fullPage,
          })}
        >
          {!fullPage ? null : (
            <div className={classes.nav}>
              <IconButton
                onClick={() => {
                  current ? handleBack() : setOpen(false);
                }}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="h2">
                {current
                  ? current[`title_${intl.locale}`]
                  : intl.formatMessage({ ...messages.locationSelectTitle })}
              </Typography>
              <div />
            </div>
          )}
          <List
            className={clsx({
              [classes.mobileList]: fullPage,
              [classes.withDivider]: withDivider,
            })}
          >
            {current && current.children ? (
              <ListItem
                button
                onClick={handleSelectCurrent}
              >
                <ListItemText
                  className={
                    typeof selected === 'object' && selected?.id === current?.id
                      ? classes.selected
                      : null
                  }
                >
                  {intl.formatMessage({ ...messages.all })}
                </ListItemText>
              </ListItem>
            ) : null}
            {list.map((node) => (
              <ListItem
                key={node?.id}
                button
                onClick={() => handleClick(node)}
              >
                {node.icon ? (
                  <img
                    alt=""
                    src={node.icon}
                    width="20"
                    height="20"
                    className={classes.icon}
                  />
                ) : null}
                <ListItemText
                  className={
                    typeof selected === 'object' && selected.id === node.id
                      ? classes.selected
                      : null
                  }
                >
                  {node[`title_${intl.locale}`]}
                </ListItemText>
                {node.children ? (
                  <KeyboardArrowRight className={classes.childrenIcon} />
                ) : null}
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : null}
    </div>
  );
}

NodeSelect.propTypes = {
  nodes: PropTypes.array,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSelect: PropTypes.func,
  intl: intlShape,
  fullPage: PropTypes.bool,
  withDivider: PropTypes.bool,
  type: PropTypes.oneOf(['list', 'select']),
  inputProps: PropTypes.object,
  primaryText: PropTypes.node,
  secondaryText: PropTypes.node,
};

NodeSelect.defaultProps = {
  type: 'select',
};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) =>
    NodeSelect[`handleClickOutside_${props.name}`],
};

const withIntl = injectIntl(NodeSelect);

export default onClickOutside(withIntl, clickOutsideConfig);
