/*
 *
 * AdsPage constants
 *
 */

export const ACTION_TYPES = {
  GET_PROFILE: 'GET_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  SET_PASSWORD: 'SET_PASSWORD',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_INFO: 'GET_PRODUCT_INFO',
  UPVOTE: 'UPVOTE',
  GET_FAVORITES: 'GET_FAVORITES',
  SET_TO_FAVORITE: 'SET_TO_FAVORITE',
  CLEARE_FAVORITES: 'CLEARE_FAVORITES',
  REMOVE_FAVORITE_PRODUCT: 'REMOVE_FAVORITE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PROFILE: 'DELETE_PROFILE',
  DELETE_PROFILE_PHOTO: 'DELETE_PROFILE_PHOTO',
  GET_BUSINESS_PROFILE: 'GET_BUSINESS_PROFILE',
  UPDATE_BUSINESS_PROFILE: 'UPDATE_BUSINESS_PROFILE',
  GET_BUSINESS_CATALOGS: 'GET_BUSINESS_CATALOGS',
};

export const ENDPOINTS = {
  PROFILE: '/auth/v1/profile/',
  DELETE_PHOTO: '/auth/v1/profile/delete_photo/',
  PASSWORD: '/auth/v1/profile/password/',
  PRODUCTS: '/product/v1/personal/products/',
  UPVOTE: (id) => `/product/v1/personal/products/${id}/upvote/`,
  FAVORITES: '/product/v1/personal/favorites/',
  CLEAR_FAVORITES: '/product/v1/personal/favorites/clear/',
  SET_PASSWORD: '/auth/v1/profile/set_password/',
  BUSINESS_PROFILE: '/business/v1/profile/',
};
