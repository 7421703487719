import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { CardActions, IconButton } from '@material-ui/core';

import { CustomSwitch, useStyles } from '../styles';

import DeleteIcon from 'assets/images/deletes.svg';
import edit from 'assets/images/editBlue.svg';
import { useDispatch } from 'react-redux';
import { profileActions } from 'states/Profile';
import { useOutsideClick } from 'hooks/useOutSideClick';

const EditCardNoReview = (props) => {
  const {
    product,
    setProducts,
    editCard,
    setOpen,
    intl,
    handleAgree,
    messages,
    checkHandler,
    checked,
    handleClick,
    newProductsTab,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDeleteProduct = async () => {
    await dispatch(profileActions.deleteProduct(product.id));
    setProducts((prevProducts) => {
      return {
        ...prevProducts,
        on_review: prevProducts['on_review'].filter(
          (item) => item.id !== product.id,
        ),
      };
    });
  };

  const editCardRef = useRef(null);

  useOutsideClick(editCardRef, handleClick, editCard);

  return (
    <div
      ref={editCardRef}
      className={editCard ? classes.chartAndEdit : classes.chartAndEditNone}
    >
      <div className={classes.chartAndEditContainer}>
        <div className={classes.switch}>
          <CustomSwitch
            onClick={checkHandler}
            checked={checked}
            color="primary"
          />{' '}
          <p>{intl.formatMessage({ ...messages.activationMessage })}</p>
        </div>
        <NavLink to={`/profile/my-products/edit/${product?.id}/`}>
          <div className={classes.changeButton}>
            <img
              src={edit}
              alt="Изменить"
            />
            <p>
              {intl?.formatMessage({
                ...messages.activatedMessage,
              })}
            </p>
          </div>
        </NavLink>
        <CardActions className={classes.actions}>
          <IconButton onClick={handleDeleteProduct}>
            <img
              alt=""
              src={DeleteIcon}
              style={{ marginRight: '25px' }}
              width="20"
              height="20"
            />
            <p>
              {intl?.formatMessage({
                ...messages.delationProduct,
              })}
            </p>
          </IconButton>
        </CardActions>
      </div>
    </div>
  );
};

export default React.memo(EditCardNoReview);
