import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productActions, productSelectors } from 'states/Products';
import { searchSelectors } from 'states/Search';

export const useProductList = () => {
  const dispatch = useDispatch();

  const selectedCategory = useSelector(searchSelectors.selectedCategory);
  const selectedLocation = useSelector(searchSelectors.selectedLocation);
  const search = useSelector(searchSelectors.search);
  const currency = useSelector(searchSelectors.currency);
  const order = useSelector(searchSelectors.order);
  const gte = useSelector(searchSelectors.gte);
  const lte = useSelector(searchSelectors.lte);
  const nextKey = useSelector(productSelectors.nextKey);
  const options = useSelector(searchSelectors.options);
  const parameters = useSelector(searchSelectors.parameters);

  const prevParams = useSelector(productSelectors.prevParams);

  const mappedParams = useMemo(() => {
    return parameters.map((p) =>
      Object.keys(p).reduce((acc, k) => {
        if (!k === 'from' || !k === 'to') {
          acc[k] = p[k];
        }

        if (p[k].length !== 0) {
          acc[k] = p[k];
        }

        return acc;
      }, {}),
    );
  }, [parameters]);

  const params = useMemo(() => {
    return {
      key: nextKey,
      location: selectedLocation !== 'all' ? selectedLocation : undefined,
      category: selectedCategory !== 'all' ? selectedCategory : undefined,
      ordering: ![null, '', '0'].includes(order) ? order : undefined,
      search: search || undefined,
      price__gte: gte && !lte ? gte : undefined,
      price__lte: lte && !gte ? lte : undefined,
      price__range: gte && lte ? `${gte},${lte}` : undefined,
      currency: currency || undefined,
      options:
        options.length > 0
          ? options.map((option) => option.id).join(',')
          : undefined,
      parameters:
        mappedParams.length > 0
          ? JSON.stringify(mappedParams).replaceAll('},{', '}-{')
          : undefined,
      limit: 28,
    };
  }, [
    nextKey,
    search,
    selectedCategory,
    selectedLocation,
    order,
    gte,
    lte,
    currency,
    options,
    mappedParams,
  ]);

  const fetchProductList = async (offset = 0, isInfinity = false) => {
    if (
      !isInfinity &&
      prevParams &&
      Object.keys(prevParams).every((key) => prevParams[key] === params[key])
    )
      return;

    try {
      await dispatch(productActions.getProductList({ ...params, offset }));
      return null;
    } catch (e) {
      return null;
    }
  };

  return { fetchProductList, params };
};
