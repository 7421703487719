import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchComponent from 'components/Search';
import { searchActions, searchSelectors } from 'states/Search';

import { useDebounce } from 'use-debounce';

function Search(props) {
  const dispatch = useDispatch();

  // Selectors
  const search = useSelector(searchSelectors.search);

  const [value, setValue] = useState(search || '');
  const [showTips, setShowTips] = useState();

  const handleChange = (e) => setValue(e.target.value);

  const [debouncedText] = useDebounce(value, 500);

  const handleSubmit = (submitValue) => {
    const searchValue = submitValue ? submitValue : value;

    dispatch(searchActions.changeSearchAsync(searchValue.trim()));
    setShowTips('');
  };
  useEffect(() => {
    if (value !== debouncedText) {
      setShowTips('');
    }
  }, [value]);

  const handleShowTips = async (param) => {
    try {
      const data = await dispatch(
        searchActions.getSearchTips({ search: param }),
      );
      setShowTips(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeSearchValue = (newValue) => setValue(newValue);

  return (
    <SearchComponent
      value={value}
      debounce={debouncedText}
      onChange={handleChange}
      onSubmit={handleSubmit}
      handleShowTips={handleShowTips}
      showTips={showTips}
      onChangeSearch={handleChangeSearchValue}
      {...props}
    />
  );
}

Search.propTypes = {};

export default memo(Search);
