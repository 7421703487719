/*
 *
 * AdsPage reducer
 *
 */
import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  info: null,
  businessProfile: null,
};

const isLoading = boolReducer(
  [combineActions(actions.getProfileRequest)],
  [combineActions(actions.getProfileSuccess, actions.getProfileFail)],
);

const data = handleActions(
  {
    [actions.getProfileSuccess]: (state, { payload }) => ({
      ...state,
      info: {
        ...payload,
        photo: payload.photo
          ? payload.photo.replace('http', 'https')
          : payload.photo,
      },
    }),
    [actions.getBusinessProfileSuccess]: (state, { payload }) => ({
      ...state,
      businessProfile: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isLoading,
});

export const key = 'profile';

export const profileSelectors = {
  info: (state) => getStateValue(state, key, 'info'),
  businessProfile: (state) => getStateValue(state, key, 'businessProfile'),
  isLoading: (state) => getStateValue(state, key, 'isLoading', true, false),
};
