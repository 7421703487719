import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';

const DiscountPrice = ({ intl, product }) => {
  const classes = useStyles();

  return (
    <div className={classes.stockAndPrice}>
      <Typography component="p">
        {intl.formatNumber(
          parseFloat(
            product?.[`discount_price_${product?.currency.toLowerCase()}`],
          ),
        )}{' '}
        {product?.currency === 'KGS' ? 'СОМ' : '$'}
      </Typography>
      <Typography component={'p'}>
        <s>
          {intl.formatNumber(parseFloat(product?.initial_price))} x{' '}
          {product?.currency === 'KGS' ? 'СОМ' : '$'}
        </s>
      </Typography>
    </div>
  );
};

export default DiscountPrice;
