import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import GooglePlay from 'assets/images/google_play.svg';
import Footer from 'messages/Footer';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#F4F4F4',
    paddingTop: '30px',
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  small: {
    maxWidth: '960px',
    width: '960px',
    height: '85px',
    borderTop: '1px solid #DBDBDB',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    paddingLeft: '30px',
    paddingRight: '30px',
    '@media (min-width: 1920px)': {
      padding: '0',
    },
    '@media (max-width: 1125px)': {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 10,
    },
  },
  logo: {
    '& img': {
      width: '150px',
    },
  },
  text: {
    minWidth: '192px',
    maxWidth: '230px',
    height: '16px',
    fontSize: '14px',
    lineHeight: '16.4px',
    color: '#C2C4CB',
    fontWeight: 'normal',
    paddingTop: '10px',
    marginRight: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  },
  socials: {
    display: 'flex',
    paddingTop: '10px',
    justifyContent: 'space-between',
    width: '300px',
    '& a': {
      width: '25px',
    },
    '@media (max-width: 920px)': {
      marginRight: '30px',
    },
  },
  social_block: {
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid #0A142F',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      border: '0',
      background: '#FAC917',
      '& path': {
        fill: '#fff',
      },
    },
  },
  flexes: {
    display: 'flex',
  },
  big: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '960px',
    width: '960px',
    '@media (min-width: 1920px)': {
      padding: '0',
    },
    '@media (max-width: 1125px)': {
      width: '100%',
      maxWidth: '100%',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  name: {
    color: '#0051BA',
    fontSize: '15px',
    fontWeight: '700',
  },
  bigTitle: {
    maxWidth: '445px',
    paddingRight: '10px',
    minHeight: '125px',
    paddingTop: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    opacity: '0.8',
    lineHeight: '25px',
  },
  navigations: {
    width: '234px',
    height: '154px',
    '& ul': {
      padding: '0',
    },
    '& li': {
      listStyle: 'none',
    },
    '& a': {
      opacity: '0.8',
      color: '#1B1B1B',
      fontSize: '15px',
      textDecoration: 'none',
      fontWeight: '400',
      lineHeight: '30px',
      transition: 'all 0.2s',
      '&:hover': {
        color: '#000',
        fontSize: '15.5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'min-content',
    },
  },
  downloadApps: {
    minWidth: '155px',
    maxWidth: '175px',
    height: '154px',
    '& img': {
      transition: 'all 0.3s',
      width: '122px',
      marginTop: '12px',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  },
}));

const currentYear = new Date().getFullYear();

const Index = (props) => {
  const classes = useStyles();
  const { intl } = props;

  return (
    <footer
      id="footer"
      className={classes.footer}
    >
      <div>
        <div className={classes.big}>
          <div>
            <Typography className={classes.name}>TEZ SAT</Typography>
            <Typography className={classes.bigTitle}>
              {intl.formatMessage({ ...Footer.aboutTezSat })}
            </Typography>
          </div>
          <div className={classes.navigations}>
            <Typography className={classes.name}>
              {intl.formatMessage({ ...Footer.more })}
            </Typography>
            <ul>
              <li>
                <a
                  href="https://api.whatsapp.com/send/?phone=996707797800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ ...Footer.support })}
                </a>
              </li>
              <li>
                <NavLink to="/sitemap/">
                  {intl.formatMessage({ ...Footer.sitemap })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">
                  {intl.formatMessage({ ...Footer.aboutUs })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/policy/">
                  {intl.formatMessage({ ...Footer.policy })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog/forbidden-products">
                  {intl.formatMessage({ ...Footer.forbidden })}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={classes.downloadApps}>
            <Typography className={classes.name}>
              {intl.formatMessage({ ...Footer.downloadApp })}
            </Typography>
            <a
              href="https://play.google.com/store/apps/details?id=com.tezsat.tezsat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={GooglePlay}
                alt="Google Play"
              />
            </a>
          </div>
        </div>
        <div className={classes.small}>
          <NavLink
            className={classes.logo}
            to="/"
          >
            <img
              src={logo}
              alt="Логотип"
            />
          </NavLink>
          <div className={classes.flexes}>
            <div className={classes.text}>
              <Typography>
                © {currentYear} {intl.formatMessage({ ...Footer.reserved })}
              </Typography>
            </div>
            {/* <div className={classes.socials} style={{ display: 'none' }}>
              <a
                href="https://telegram.me/TezSatTelegram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 19 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.06276 10.3858L6.76501 14.5738C7.19101 14.5738 7.37551 14.3908 7.59676 14.171L9.59401 12.2623L13.7325 15.293C14.4915 15.716 15.0263 15.4933 15.231 14.5948L17.9475 1.86578L17.9483 1.86503C18.189 0.743029 17.5425 0.304279 16.803 0.579529L0.835511 6.69278C-0.254239 7.11578 -0.237739 7.72328 0.650261 7.99853L4.73251 9.26828L14.2148 3.33503C14.661 3.03953 15.0668 3.20303 14.733 3.49853L7.06276 10.3858Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=996707797800"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00225 0H8.99775C4.03538 0 0 4.0365 0 9C0 10.9688 0.6345 12.7935 1.71337 14.2751L0.59175 17.6186L4.05113 16.5128C5.47425 17.4555 7.17188 18 9.00225 18C13.9646 18 18 13.9624 18 9C18 4.03763 13.9646 0 9.00225 0ZM14.2391 12.7091C14.022 13.3223 13.1602 13.8307 12.4729 13.9792C12.0026 14.0794 11.3884 14.1593 9.32063 13.302C6.67575 12.2063 4.9725 9.51862 4.83975 9.34425C4.71263 9.16988 3.771 7.92112 3.771 6.62963C3.771 5.33813 4.42688 4.70925 4.69125 4.43925C4.90838 4.21763 5.26725 4.11637 5.6115 4.11637C5.72288 4.11637 5.823 4.122 5.913 4.1265C6.17737 4.13775 6.31012 4.1535 6.4845 4.57088C6.70162 5.094 7.23037 6.3855 7.29338 6.51825C7.3575 6.651 7.42163 6.831 7.33162 7.00538C7.24725 7.18538 7.173 7.26525 7.04025 7.41825C6.9075 7.57125 6.7815 7.68825 6.64875 7.8525C6.52725 7.99538 6.39 8.14838 6.543 8.41275C6.696 8.6715 7.22475 9.53437 8.00325 10.2274C9.00787 11.1217 9.82237 11.4075 10.1137 11.529C10.3309 11.619 10.5896 11.5976 10.7483 11.4289C10.9496 11.2118 11.1982 10.8518 11.4514 10.4974C11.6314 10.2431 11.8586 10.2116 12.0971 10.3016C12.3401 10.386 13.626 11.0216 13.8904 11.1532C14.1547 11.286 14.3291 11.349 14.3932 11.4604C14.4562 11.5718 14.4562 12.0949 14.2391 12.7091Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://www.instagram.com/tezsat.kg/?hl=ru"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.375 0H5.625C2.51888 0 0 2.51888 0 5.625V12.375C0 15.4811 2.51888 18 5.625 18H12.375C15.4811 18 18 15.4811 18 12.375V5.625C18 2.51888 15.4811 0 12.375 0ZM16.3125 12.375C16.3125 14.5463 14.5463 16.3125 12.375 16.3125H5.625C3.45375 16.3125 1.6875 14.5463 1.6875 12.375V5.625C1.6875 3.45375 3.45375 1.6875 5.625 1.6875H12.375C14.5463 1.6875 16.3125 3.45375 16.3125 5.625V12.375Z"
                      fill="#0A142F"
                    />
                    <path
                      d="M9 4.5C6.51487 4.5 4.5 6.51487 4.5 9C4.5 11.4851 6.51487 13.5 9 13.5C11.4851 13.5 13.5 11.4851 13.5 9C13.5 6.51487 11.4851 4.5 9 4.5ZM9 11.8125C7.44975 11.8125 6.1875 10.5503 6.1875 9C6.1875 7.44863 7.44975 6.1875 9 6.1875C10.5503 6.1875 11.8125 7.44863 11.8125 9C11.8125 10.5503 10.5503 11.8125 9 11.8125Z"
                      fill="#0A142F"
                    />
                    <path
                      d="M13.8375 4.76214C14.1687 4.76214 14.4372 4.49368 14.4372 4.16252C14.4372 3.83136 14.1687 3.5629 13.8375 3.5629C13.5064 3.5629 13.2379 3.83136 13.2379 4.16252C13.2379 4.49368 13.5064 4.76214 13.8375 4.76214Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCchiOe5aMumD2ZljKXSO7MA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.235 4.005C16.7467 3.1365 16.2169 2.97675 15.138 2.916C14.0603 2.84288 11.3501 2.8125 9.00225 2.8125C6.64987 2.8125 3.93862 2.84288 2.862 2.91488C1.78537 2.97675 1.25437 3.13537 0.761625 4.005C0.25875 4.87238 0 6.36638 0 8.99663C0 8.99888 0 9 0 9C0 9.00225 0 9.00338 0 9.00338V9.00563C0 11.6246 0.25875 13.1299 0.761625 13.9882C1.25437 14.8568 1.78425 15.0143 2.86087 15.0874C3.93863 15.1504 6.64987 15.1875 9.00225 15.1875C11.3501 15.1875 14.0602 15.1504 15.1391 15.0885C16.218 15.0154 16.7479 14.8579 17.2361 13.9894C17.7435 13.131 18 11.6257 18 9.00675C18 9.00675 18 9.00338 18 9.00113C18 9.00113 18 8.99888 18 8.99775C18 6.36638 17.7435 4.87238 17.235 4.005ZM6.75 12.375V5.625L12.375 9L6.75 12.375Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://vk.com/public199760880"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="10"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8396 10.0253C17.7912 9.9443 17.4919 9.294 16.0518 7.9574C14.5442 6.5578 14.7467 6.78506 16.5626 4.36501C17.6686 2.89115 18.1107 1.99108 17.9723 1.6063C17.8407 1.23952 17.0273 1.33628 17.0273 1.33628L14.3226 1.35203C14.3226 1.35203 14.1223 1.32503 13.9727 1.41391C13.8275 1.50167 13.7341 1.70418 13.7341 1.70418C13.7341 1.70418 13.3055 2.84502 12.7339 3.81484C11.529 5.86137 11.0463 5.96938 10.8494 5.84224C10.3915 5.54634 10.5063 4.6519 10.5063 4.01736C10.5063 2.03383 10.8067 1.2069 9.92011 0.993131C9.62534 0.922251 9.40932 0.874998 8.65664 0.867122C7.69132 0.856996 6.87339 0.870497 6.41098 1.09664C6.1027 1.2474 5.86531 1.5838 6.01045 1.60293C6.18934 1.62655 6.59437 1.71206 6.80926 2.00458C7.08715 2.38148 7.07703 3.2298 7.07703 3.2298C7.07703 3.2298 7.23679 5.56435 6.70462 5.85462C6.33897 6.05376 5.83831 5.6476 4.76385 3.79009C4.21369 2.83939 3.79741 1.78744 3.79741 1.78744C3.79741 1.78744 3.71753 1.59168 3.57464 1.48704C3.40138 1.35991 3.15836 1.31941 3.15836 1.31941L0.58642 1.33516C0.58642 1.33516 0.200516 1.34641 0.0587555 1.51405C-0.0672538 1.66368 0.0486298 1.97195 0.0486298 1.97195C0.0486298 1.97195 2.06253 6.68268 4.34195 9.05773C6.43348 11.2348 8.8074 11.0919 8.8074 11.0919H9.88298C9.88298 11.0919 10.2081 11.0559 10.3735 10.877C10.5265 10.7127 10.5209 10.4045 10.5209 10.4045C10.5209 10.4045 10.4995 8.96097 11.1701 8.74833C11.8305 8.53907 12.6788 10.1434 13.5778 10.7611C14.2573 11.228 14.7737 11.1256 14.7737 11.1256L17.178 11.0919C17.178 11.0919 18.4359 11.0143 17.8396 10.0253Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://ok.ru/group/59124579106936"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.11407 12.6787C3.71865 12.5332 2.46047 12.1895 1.38328 11.347C1.24962 11.2422 1.11162 11.1411 0.989648 11.0241C0.518161 10.5712 0.470654 10.0526 0.843734 9.51816C1.1629 9.06082 1.69867 8.93847 2.25555 9.20108C2.36339 9.25198 2.46613 9.31551 2.56454 9.38375C4.57208 10.7631 7.32993 10.8012 9.34501 9.44577C9.54465 9.2927 9.75805 9.1679 10.0054 9.10418C10.4863 8.9807 10.9348 9.15734 11.1929 9.57811C11.4877 10.0586 11.4839 10.5277 11.1207 10.9008C10.5634 11.4727 9.89322 11.8865 9.14857 12.1753C8.44445 12.4483 7.67322 12.5857 6.90991 12.677C7.02509 12.8024 7.07938 12.864 7.1514 12.9364C8.18788 13.9778 9.22888 15.0148 10.262 16.0594C10.6139 16.4153 10.6875 16.8566 10.4937 17.2706C10.2818 17.7231 9.80745 18.0208 9.34237 17.9889C9.04772 17.9685 8.81791 17.8219 8.61374 17.6162C7.83158 16.8291 7.03471 16.0566 6.26838 15.2546C6.04536 15.0214 5.93809 15.0655 5.74128 15.268C4.9544 16.0781 4.1547 16.8757 3.35029 17.6688C2.98908 18.0249 2.55926 18.089 2.14037 17.8856C1.69509 17.6697 1.41174 17.2152 1.43361 16.7584C1.44869 16.4494 1.60064 16.2134 1.81272 16.0017C2.83846 14.9775 3.86136 13.9506 4.88464 12.9247C4.95232 12.8565 5.01548 12.7841 5.11407 12.6787Z"
                      fill="#0A142F"
                    />
                    <path
                      d="M5.96412 9.11096C3.47472 9.10248 1.43344 7.03876 1.44795 4.5454C1.46228 2.02453 3.50489 -0.0069573 6.01879 1.79086e-05C8.53778 0.0068046 10.5596 2.06751 10.547 4.61459C10.5342 7.10285 8.47877 9.11963 5.96412 9.11096ZM8.22653 4.55144C8.2222 3.31343 7.23624 2.32785 6.00069 2.32672C4.75477 2.3254 3.75995 3.32889 3.76957 4.57821C3.7788 5.8115 4.77324 6.7901 6.01219 6.78558C7.24737 6.78124 8.23068 5.78925 8.22653 4.55144Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://www.facebook.com/tezsatkg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 10 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.06765 20V9.99922H0V6.55276H2.06765V4.48352C2.06765 1.6719 3.23517 0 6.55223 0H9.31379V3.44685H7.58762C6.29637 3.44685 6.21095 3.92849 6.21095 4.82738L6.20626 6.55237H9.33333L8.96742 9.99883H6.20626V20H2.06765Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://twitter.com/tezsatkg?s=21"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.social_block}>
                  <svg
                    width="25"
                    height="12"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.71114 4.57873L9.75449 5.3107L9.03194 5.22107C6.40184 4.87749 4.10411 3.71231 2.15321 1.75541L1.19944 0.78443L0.953773 1.50146C0.433533 3.09985 0.765909 4.78786 1.84974 5.92316C2.42779 6.55057 2.29773 6.6402 1.3006 6.26674C0.953773 6.14724 0.6503 6.05761 0.621397 6.10242C0.52024 6.20699 0.867066 7.56636 1.14164 8.10414C1.51737 8.85105 2.28327 9.58302 3.12144 10.0162L3.82954 10.3598L2.99138 10.3747C2.18212 10.3747 2.15321 10.3897 2.23992 10.7034C2.52894 11.6744 3.67058 12.7051 4.94228 13.1532L5.83825 13.4669L5.05789 13.945C3.9018 14.6321 2.54339 15.0205 1.18499 15.0504C0.534691 15.0653 0 15.1251 0 15.1699C0 15.3193 1.76303 16.1558 2.78906 16.4845C5.86715 17.4554 9.52328 17.0372 12.269 15.379C14.2199 14.1989 16.1708 11.8536 17.0812 9.58302C17.5725 8.37302 18.0639 6.16217 18.0639 5.10156C18.0639 4.41441 18.1072 4.32478 18.9165 3.50318C19.3934 3.02516 19.8414 2.50232 19.9281 2.35294C20.0726 2.06911 20.0581 2.06911 19.3211 2.32306C18.0928 2.77121 17.9194 2.71145 18.5263 2.03924C18.9743 1.56121 19.509 0.694799 19.509 0.440851C19.509 0.396036 19.2922 0.470726 19.0466 0.605171C18.7864 0.754553 18.2084 0.978624 17.7749 1.11307L16.9945 1.36702L16.2864 0.874058C15.8962 0.605171 15.3471 0.306408 15.0581 0.216778C14.321 0.00764465 13.1939 0.0375195 12.5291 0.276531C10.7227 0.94875 9.58108 2.68158 9.71114 4.57873Z"
                      fill="#0A142F"
                    />
                  </svg>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
};

export default Index;
