import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import {
  Box,
  makeStyles,
  MenuList,
  Paper,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: '100%',
    minWidth: '100%',
    left: 0,
    zIndex: 13000,
  },
  label: {
    position: 'relative',
    maxHeight: '100%',
    padding: `${theme.spacing(0.9)}px ${theme.spacing(4.5)}px ${theme.spacing(
      0.9,
    )}px ${theme.spacing(1.2)}px`,
    border: '1px solid #C4C4C4',
    borderRadius: 10,
    cursor: 'pointer',
    color: '#666666',
  },

  labelIcon: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',

    transition: '.5s all',
  },
  labelParagraph: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  openedIcon: {
    transform: 'translateY(-50%) rotate(180deg)',
  },
  unselectable: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    msTouchSelect: 'none',
    WebkitTouchCallout: 'none',
  },
}));

function Select(props) {
  const {
    value,
    name,
    children,
    onChange,
    defaultValue,
    placeholder,
    style,
    className,
    paperProps,
  } = props;
  const classes = useStyles();
  const [currVal, setCurrVal] = useState(defaultValue || value || null);
  const [open, setOpen] = useState(false);

  const handleClick = (val) => {
    if (onChange) {
      onChange(val);
    }

    if (!value) {
      setCurrVal(val);
    }

    setOpen(false);
  };

  useEffect(() => {
    if (value) {
      setCurrVal(value);
    }
  }, [value]);

  const label = React.Children.map(
    children,
    (child) => child.props?.value === currVal && child.props?.children,
  );

  Select[`handleClickOutside_${name}`] = () => setOpen(false);

  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
    >
      <Box
        className={classes.label}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Typography
          component="p"
          className={clsx(classes.labelParagraph, classes.unselectable)}
        >
          {label || placeholder}
        </Typography>
        <KeyboardArrowDown
          className={clsx(classes.labelIcon, open ? classes.openedIcon : null)}
        />
      </Box>
      {open && (
        <Paper
          className={classes.paper}
          {...paperProps}
        >
          <MenuList>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                onClick: () => handleClick(child.props?.value),
              });
            })}
          </MenuList>
        </Paper>
      )}
    </div>
  );
}

Select.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.any,
  name: PropTypes.string.isRequired,
  paperProps: PropTypes.object,
  children: PropTypes.any,
};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => Select[`handleClickOutside_${props.name}`],
};

export default onClickOutside(Select, clickOutsideConfig);
