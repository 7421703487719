import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 10,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      // paddingTop: 180
    },
  },
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    position: 'sticky',
    zIndex: 1300,
    background: '#fff',
    paddingTop: 5,
    paddingBottom: 5,
  },
  appButtonsWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 8px',
  },
  grow: {
    flexGrow: 1,
  },
  forBusiness: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#555555',
    textTransform: 'none',
    marginLeft: 20,
    maxHeight: 30,

    '@media (max-width: 560px)': {
      display: 'none',
    },
  },
  appButton: {
    padding: 0,
    minWidth: 80,
    maxWidth: 80,
    background: 'transparent',
    marginLeft: 5,

    '&:hover': {
      background: 'transparent',
    },

    '& img': {
      height: 30,
    },
  },
  menu: {
    color: '#000000',
    minWidth: 30,
    marginRight: 15,
    '@media (max-width: 1180px)': {
      display: 'inline-flex',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  },
  menuItem: {
    minHeight: 0,
    fontSize: 16,
  },
  menuItemPersonal: {
    color: 'inherit',
    minHeight: 0,
    fontWeight: 600,
    fontSize: 16,
  },
  mobileMenuItem: {
    alignItems: 'center',
    justifyContent: 'right',
    // paddingBottom: '17px',
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'right',
  },
  ad: {},
}));
