import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import back from '../../assets/images/BusinessAdvantagesBack.png';
import Typography from '@material-ui/core/Typography';
import Logo from 'assets/images/BusinessLogoBlack.svg';
import blur from '../../assets/images/blur.png';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileLogo from 'assets/images/BlackBusiness.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '1000px',
    height: '332px',
    padding: '33px 59px',
    background: `url(${back}) no-repeat 100% 0%`,
    backgroundSize: '400px',
    '@media (max-width: 800px)': {
      width: '420px',
      padding: 0,
    },
  },
  dialog: {
    '& div': {
      maxWidth: '100% !important',
    },
  },
  content: {
    border: '2px solid #fff',
    padding: '25px',
    height: '265px',
    borderRadius: '5px',
    background: `url(${blur}) no-repeat`,
    backdropFilter: 'blur(20px)',
    textAlign: 'center',
    '@media (max-width: 800px)': {
      height: '332px',
      backdropFilter: 'blur(100px)',
      '& img': {
        width: '90%',
      },
    },
    '& h1': {
      color: '#0A142F',
      fontSize: '20px',
      textTransform: 'uppercase',
      lineHeight: '24px',
      marginTop: '25px',
      '@media (max-width: 800px)': {
        fontSize: '17px',
      },
    },
    '& button': {
      width: '170px',
      height: '35px',
      background: theme.palette.primary.main,
      border: 0,
      borderRadius: '5px',
      marginTop: '35px',
      cursor: 'pointer',
      transition: 'all 0.3s',
      '&:hover': {
        color: '#fff',
        background: theme.palette.primary.blue,
      },
      '@media (max-width: 800px)': {
        width: '90%',
      },
    },
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const { intl, messages, open, close } = props;

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onEscapeKeyDown={close}
      onBackdropClick={close}
    >
      <div className={classes.root}>
        <div className={classes.content}>
          {isMobile ? (
            <img
              src={MobileLogo}
              alt={intl.formatMessage({ ...messages.connectToBusiness })}
            />
          ) : (
            <img
              src={Logo}
              alt={intl.formatMessage({ ...messages.connectToBusiness })}
            />
          )}

          <Typography component="h1">
            {intl.formatMessage({ ...messages.connectToBusiness })}
          </Typography>

          <NavLink to="/business-advantages">
            <button onClick={close}>
              {intl.formatMessage({ ...messages.more })}
            </button>
          </NavLink>
        </div>
      </div>
    </Dialog>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  messages: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default Index;
