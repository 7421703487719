import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  selectedCategory: 'all',
  selectedLocation: 'all',
  search: '',
  currency: 'KGS',
  order: '',
  gte: '',
  lte: '',
  options: [],
  parameters: [],
  categoryAndChildren: null,
};

const data = handleActions(
  {
    [actions.changeCategory]: (state, { payload }) => ({
      ...state,
      selectedCategory: payload,
    }),
    [actions.changeLocation]: (state, { payload }) => ({
      ...state,
      selectedLocation: payload,
    }),
    [actions.changeSearch]: (state, { payload }) => ({
      ...state,
      search: payload,
    }),
    [actions.changeCurrency]: (state, { payload }) => ({
      ...state,
      currency: payload,
    }),
    [actions.changeOrder]: (state, { payload }) => ({
      ...state,
      order: payload,
    }),
    [actions.changeGte]: (state, { payload }) => ({
      ...state,
      gte: payload,
    }),
    [actions.changeLte]: (state, { payload }) => ({
      ...state,
      lte: payload,
    }),
    [actions.changeOptions]: (state, { payload }) => ({
      ...state,
      options: payload,
    }),
    [actions.changeParameters]: (state, { payload }) => ({
      ...state,
      parameters: payload,
    }),
    [actions.clearFilters]: (state) => ({
      ...state,
      selectedLocation: 'all',
      search: '',
      currency: 'KGS',
      order: '',
      gte: '',
      lte: '',
      options: [],
      parameters: [],
      categoryAndChildren: null,
    }),
    [actions.getCategoryAndChildrenSucces]: (state, { payload }) => ({
      ...state,
      categoryAndChildren: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
});

export const key = 'search';

export const searchSelectors = {
  selectedCategory: (state) =>
    getStateValue(state, key, 'selectedCategory', false, 'all'),
  selectedLocation: (state) =>
    getStateValue(state, key, 'selectedLocation', false, 'all'),
  search: (state) => getStateValue(state, key, 'search', false, ''),
  currency: (state) => getStateValue(state, key, 'currency', false, ''),
  order: (state) => getStateValue(state, key, 'order', false, ''),
  gte: (state) => getStateValue(state, key, 'gte', false, ''),
  lte: (state) => getStateValue(state, key, 'lte', false, ''),
  options: (state) => getStateValue(state, key, 'options', false, []),
  parameters: (state) => getStateValue(state, key, 'parameters', false, []),
  categoryAndChildren: (state) =>
    getStateValue(state, key, 'categoryAndChildren', false, null),
};
