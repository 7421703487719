import React from 'react';
import PropTypes from 'prop-types';

import { Slide } from '@material-ui/core';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const MobileHeaderMenu = (props) => {
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box
        zIndex={10000000}
        overflow="hidden"
        position="absolute"
        top="0"
        right="0"
        left="0"
        background="#FAFAFA"
      >
        <Slide
          direction="down"
          in={props.open}
          mountOnEnter
          unmountOnExit
        >
          <Box
            onClick={props.toggleOpen}
            className={classes.menu}
            {...props}
          >
            {props.children}
          </Box>
        </Slide>
      </Box>
    </Box>
  );
};

MobileHeaderMenu.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

export default MobileHeaderMenu;
