export const ACTION_TYPES = {
  GET_PARENT_CATEGORIES: 'GET_PARENT_CATEGORIES',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_MAIN_CATEGORIES: 'GET_MAIN_CATEGORIES',
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_NOTIFYS: 'GET_NOTIFYS',
  GET_UNREAD_NOTIFYS: 'GET_UNREAD_NOTIFYS',
  READ_ALL_NOTIFYS: 'READ_ALL_NOTIFYS',
  UPDATE_NOTIFY: 'UPDATE_NOTIFY',
  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
  GET_CATEGORY_AND_ALL_CHILDREN: 'GET_CATEGORY_AND_ALL_CHILDREN',
};

export const ENDPOINTS = {
  PARENT_CATEGORIES: '/category/v1/categories/parents/',
  CATEGORIES: '/category/v2/categories/non_structured/',
  MAIN_CATEGORIES: '/category/v1/categories/',
  LOCATIONS: '/location/v1/locations/non_structured/',
  NOTIFYS: '/notification/v1/notifications/',
  UNREAD_NOTIFYS: '/notification/v1/notifications/unread_count/',
  READ_ALL_NOTIFYS: '/notification/v1/notifications/read_all/',
  CATEGORY_AND_ALL_CHILDRENS: '/category/v2/categories/',
};
