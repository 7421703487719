/*
 *
 * AdsPage actions
 *
 */

import { createActions } from 'redux-actions';

import { createRequestAction, getResponseError } from 'common/helpers';
import { ENDPOINTS, ACTION_TYPES } from './constants';

const actionCreators = createActions({
  ...createRequestAction(ACTION_TYPES.GET_PROFILE),
  ...createRequestAction(ACTION_TYPES.UPDATE_PROFILE),
  ...createRequestAction(ACTION_TYPES.PASSWORD_CHANGE),
  ...createRequestAction(ACTION_TYPES.GET_PRODUCTS),
  ...createRequestAction(ACTION_TYPES.GET_PRODUCT_INFO),
  ...createRequestAction(ACTION_TYPES.GET_FAVORITES),
  ...createRequestAction(ACTION_TYPES.SET_TO_FAVORITE),
  ...createRequestAction(ACTION_TYPES.REMOVE_FAVORITE_PRODUCT),
  ...createRequestAction(ACTION_TYPES.SET_PASSWORD),
  ...createRequestAction(ACTION_TYPES.DELETE_PRODUCT),
  ...createRequestAction(ACTION_TYPES.UPVOTE),
  ...createRequestAction(ACTION_TYPES.DELETE_PROFILE),
  ...createRequestAction(ACTION_TYPES.DELETE_PROFILE_PHOTO),
  ...createRequestAction(ACTION_TYPES.GET_BUSINESS_PROFILE),
});

export function getProfile() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getProfileRequest());
    try {
      const { data } = await api.get(ENDPOINTS.PROFILE);

      dispatch(actionCreators.getProfileSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getProfileFail(error));
      return Promise.reject(error);
    }
  };
}

export function updateProfile(params, isMulti = true) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.updateProfileRequest());
    try {
      const { data } = await api.patch(ENDPOINTS.PROFILE, params, {
        multi: isMulti,
      });
      dispatch(actionCreators.updateProfileSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.updateProfileFail(error));
      return Promise.reject(error);
    }
  };
}

export function deleteProfilePhoto() {
  return async (dispatch, getState, { api }) => {
    const cookie_data = document.cookie //// get values from useMemo because everyTime while render send a new request
      .split(';')
      .map((cookie) => cookie.split('='))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {},
      );
    await api.delete(ENDPOINTS.DELETE_PHOTO, {
      headers: {
        Authorization: 'Bearer ' + cookie_data['__ts:token'],
      },
    });
  };
}

export function passwordChange(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.passwordChangeRequest());
    try {
      const { data } = await api.post(ENDPOINTS.PASSWORD, params);

      dispatch(actionCreators.passwordChangeSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.passwordChangeFail(error));
      return Promise.reject(error);
    }
  };
}

export function setPassword(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.setPasswordRequest());
    try {
      const { data } = await api.post(ENDPOINTS.SET_PASSWORD, params);

      dispatch(actionCreators.setPasswordSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.setPasswordFail(error));
      return Promise.reject(error);
    }
  };
}

export function getProducts(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getProductsRequest());
    try {
      const { data } = await api.get(ENDPOINTS.PRODUCTS, params);

      dispatch(actionCreators.getProductsSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getProductsFail(error));
      return Promise.reject(error);
    }
  };
}

export function getProductInfo(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getProductInfoRequest());
    try {
      const { data } = await api.get(`${ENDPOINTS.PRODUCTS}${id}`);

      dispatch(actionCreators.getProductInfoSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getProductInfoFail(error));
      return Promise.reject(error);
    }
  };
}

export function getFavorites(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getFavoritesRequest());
    try {
      const { data } = await api.get(ENDPOINTS.FAVORITES, params);

      dispatch(actionCreators.getFavoritesSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getFavoritesFail(error));
      return Promise.reject(error);
    }
  };
}

export function setToFavorite(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.setToFavoriteRequest());
    try {
      const { data } = await api.post(ENDPOINTS.FAVORITES, params);
      dispatch(actionCreators.setToFavoriteSuccess(data));
      return data;
    } catch (err) {
      const error = getResponseError(err);
      dispatch(actionCreators.setToFavoriteFail(error));

      return Promise.reject(error);
    }
  };
}

export function removeFavoriteProduct(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.removeFavoriteProductRequest());
    try {
      const { data } = await api.delete(`${ENDPOINTS.FAVORITES}${id}/`);
      dispatch(actionCreators.removeFavoriteProductSuccess(data));
      return data;
    } catch (err) {
      const error = getResponseError(err);
      dispatch(actionCreators.removeFavoriteProductFail(error));

      return Promise.reject(error);
    }
  };
}

export function deleteProduct(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.deleteProductRequest());
    try {
      const { data } = await api.delete(`${ENDPOINTS.PRODUCTS}${id}/`);
      dispatch(actionCreators.deleteProductSuccess(data));
      return data;
    } catch (err) {
      const error = getResponseError(err);
      dispatch(actionCreators.deleteProductFail(error));

      return Promise.reject(error);
    }
  };
}

export function upvote(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.upvoteRequest());
    try {
      const { data } = await api.post(ENDPOINTS.UPVOTE(id));
      dispatch(actionCreators.upvoteSuccess(data));
      return data;
    } catch (err) {
      const error = getResponseError(err);
      dispatch(actionCreators.upvoteFail(error));

      return Promise.reject(error);
    }
  };
}

export function deleteProfile() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.deleteProfileRequest());
    try {
      const { data } = await api.delete(ENDPOINTS.PROFILE);
      dispatch(actionCreators.deleteProfileSuccess(data));
      return data;
    } catch (err) {
      const error = getResponseError(err);
      dispatch(actionCreators.deleteProfileFail(err));

      return Promise.reject(error);
    }
  };
}

// Business

export function getBusinessProfile() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getBusinessProfileRequest());
    try {
      const { data } = await api.get(ENDPOINTS.BUSINESS_PROFILE);
      dispatch(actionCreators.getBusinessProfileSuccess(data));

      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getBusinessProfileFail(e));
      return Promise.reject(error);
    }
  };
}

export const actions = {
  ...actionCreators,
  getProfile,
  updateProfile,
  passwordChange,
  getProducts,
  getFavorites,
  setToFavorite,
  removeFavoriteProduct,
  deleteProfilePhoto,
  setPassword,
  getProductInfo,
  deleteProduct,
  upvote,
  deleteProfile,
  getBusinessProfile,
};
