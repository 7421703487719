import { createActions } from 'redux-actions';

import {
  createRequestAction,
  getResponseError,
  makeTree,
} from 'common/helpers';
import { ENDPOINTS, ACTION_TYPES } from './constants';

const actionCreators = createActions({
  ...createRequestAction(ACTION_TYPES.GET_PARENT_CATEGORIES),
  ...createRequestAction(ACTION_TYPES.GET_CATEGORIES),
  ...createRequestAction(ACTION_TYPES.GET_MAIN_CATEGORIES),
  ...createRequestAction(ACTION_TYPES.GET_LOCATIONS),
  ...createRequestAction(ACTION_TYPES.GET_NOTIFYS),
  ...createRequestAction(ACTION_TYPES.GET_UNREAD_NOTIFYS),
  ...createRequestAction(ACTION_TYPES.READ_ALL_NOTIFYS),
  ...createRequestAction(ACTION_TYPES.UPDATE_NOTIFY),
  ...createRequestAction(ACTION_TYPES.GET_ALL_CATEGORIES),
  ...createRequestAction(ACTION_TYPES.GET_CATEGORY_AND_ALL_CHILDREN),
});

export function getParentCategories() {
  return async (dispatch, _, { api }) => {
    dispatch(actionCreators.getParentCategoriesRequest());
    try {
      const { data } = await api.get(ENDPOINTS.PARENT_CATEGORIES);

      dispatch(actionCreators.getParentCategoriesSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getParentCategoriesFail(error));
      return Promise.reject(error);
    }
  };
}

export function getCategories() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getCategoriesRequest());
    try {
      const { data } = await api.get(ENDPOINTS.CATEGORIES);

      const TreeLength = '1045';
      const initTree = makeTree(data);
      const tree = Object.keys(initTree).reduce((acc, rec) => {
        if (rec !== TreeLength) {
          return {
            ...acc,
            [rec]: initTree[rec],
          };
        }

        return acc;
      }, {});

      const payload = {
        tree: {
          ...tree,
        },
        array: data,
      };

      localStorage.setItem(
        'iconCategories',
        JSON.stringify(Object.values(payload.tree)),
      );
      localStorage.setItem(
        'CategoriesObj',
        JSON.stringify(
          payload.array.reduce(
            (acc, rec) => ({
              ...acc,
              [rec.id]: rec,
            }),
            {},
          ),
        ),
      );

      dispatch(actionCreators.getCategoriesSuccess(payload));
      return payload;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getCategoriesFail(error));
      return Promise.reject(error);
    }
  };
}

export function getMainCategories(parentID) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getMainCategoriesRequest());
    try {
      const url = parentID
        ? `${ENDPOINTS.MAIN_CATEGORIES}${parentID}/`
        : ENDPOINTS.MAIN_CATEGORIES;
      const { data } = await api.get(url);

      dispatch(actionCreators.getMainCategoriesSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getMainCategoriesFail(error));
      return Promise.reject(error);
    }
  };
}

export function getLocations(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getLocationsRequest());
    try {
      const { data } = await api.get(ENDPOINTS.LOCATIONS, { params });

      const payload = {
        tree: makeTree(data, 1),
        array: data,
      };
      dispatch(actionCreators.getLocationsSuccess(payload));
      return payload.tree;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getLocationsFail(error));
      return Promise.reject(error);
    }
  };
}

// Notifications
export function getNotifys() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getNotifysRequest());
    try {
      const { data } = await api.get(ENDPOINTS.NOTIFYS);

      dispatch(actionCreators.getNotifysSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getNotifysFail(error));
      return Promise.reject(error);
    }
  };
}

export function getUnreadNotifys() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getUnreadNotifysRequest());
    try {
      const { data } = await api.get(ENDPOINTS.UNREAD_NOTIFYS);

      dispatch(actionCreators.getUnreadNotifysSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getUnreadNotifysFail(error));
      return Promise.reject(error);
    }
  };
}

export function readAllNotifys() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.readAllNotifysRequest());
    try {
      const { data } = await api.post(ENDPOINTS.READ_ALL_NOTIFYS);

      dispatch(actionCreators.readAllNotifysSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.readAllNotifysFail(error));
      return Promise.reject(error);
    }
  };
}

export function updateNotify(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.updateNotifyRequest());
    try {
      const { data } = await api.patch(`${ENDPOINTS.NOTIFYS}${id}/`, {
        is_read: true,
      });

      dispatch(actionCreators.updateNotifySuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.updateNotifyFail(error));
      return Promise.reject(error);
    }
  };
}

export function getAllCategories() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getAllCategoriesRequest());
    try {
      const { data } = await api.get(`${ENDPOINTS.CATEGORIES}`);
      dispatch(actionCreators.getAllCategoriesSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getAllCategoriesError(error));
      return Promise.reject(error);
    }
  };
}

export function getCategoryAndAllChildren(params, concat) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getCategoryAndAllChildrenRequest());
    try {
      const state = getState()?.app?.data?.categoryAndChildren;

      const { data } = await api.get(
        `${ENDPOINTS.CATEGORY_AND_ALL_CHILDRENS}`,
        { params },
      );

      if (concat) {
        const result = {
          ...data,
          results: state?.results?.concat(data?.results),
        };

        dispatch(actionCreators.getCategoryAndAllChildrenSuccess(result));
        return result;
      }

      dispatch(actionCreators.getCategoryAndAllChildrenSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getCategoryAndAllChildrenError(error));
      return Promise.reject(error);
    }
  };
}

export const actions = {
  ...actionCreators,
  getParentCategories,
  getCategories,
  getMainCategories,
  getLocations,
  getNotifys,
  getUnreadNotifys,
  updateNotify,
  getAllCategories,
  getCategoryAndAllChildren,
};
