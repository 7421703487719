import React, { memo, useContext } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import { UserContext } from 'providers/UserProvider';

// Messages
import headerMessages from 'messages/Header';
import HeaderComponent from '../../components/Header';

const Header = (props) => {
  const { intl } = props;
  const { openAuthForm } = useContext(UserContext);

  return (
    <HeaderComponent
      intl={intl}
      messages={headerMessages}
      openAuthForm={openAuthForm}
    />
  );
};

Header.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(Header);
