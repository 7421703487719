import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { DrawerContainer } from './components';
import Select from './Select';
import FromTo from './FromTo';

function CustomDrawer(props) {
  const { openedParam, onClose, open, intl } = props;

  const requiredProps = {
    openedParam,
    onClose,
    intl,
  };

  if (!openedParam) return <></>;

  let drawerContent;

  if (openedParam.type === 'enter') {
    drawerContent = <FromTo {...requiredProps} />;
  }

  if (openedParam.type === 'select') {
    drawerContent = openedParam.from_to ? (
      <FromTo {...requiredProps} />
    ) : (
      <Select {...requiredProps} />
    );
  }

  return (
    <DrawerContainer
      open={open}
      onClose={onClose}
    >
      {drawerContent}
    </DrawerContainer>
  );
}

CustomDrawer.propTypes = {
  intl: intlShape,
  openedParam: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(CustomDrawer);
