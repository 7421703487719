import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { UserContext } from 'providers/UserProvider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Desktop from './Desktop';
import Mobile from './Mobile';

function Header(props) {
  const { intl, messages, openAuthForm } = props;
  const { user, logout, newMsgsAmount } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  if (isMobile) {
    return (
      <Mobile
        openAuthForm={openAuthForm}
        user={user}
        logout={logout}
        newMsgsAmount={newMsgsAmount}
      />
    );
  }

  return (
    <Desktop
      intl={intl}
      messages={messages}
      openAuthForm={openAuthForm}
      user={user}
      logout={logout}
      newMsgsAmount={newMsgsAmount}
    />
  );
}

Header.propTypes = {
  intl: intlShape,
  messages: PropTypes.object.isRequired,
  openAuthForm: PropTypes.func,
};

export default memo(Header);
