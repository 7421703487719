import { minSymbolsForPassword } from './validation/validation';

export const loginSchema = {
  login: {
    presence: {
      allowEmpty: false,
      message: '^requiredField',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^requiredField',
    },
    length: {
      minimum: minSymbolsForPassword,
      tooShort: '^tooShortPass',
    },
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
      message: '^requiredField',
    },
    length: {
      minimum: minSymbolsForPassword,
      tooShort: '^tooShortPass',
    },
  },
};

export const passwordSchema = {
  password: {
    presence: { allowEmpty: false, message: '^requiredField' },
    length: {
      minimum: minSymbolsForPassword,
      tooShort: '^tooShortPass',
    },
  },
};
