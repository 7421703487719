/**
 *
 * LoadingButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-block',
    position: 'relative',
    minWidth: 88,
  },
  fullwidth: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  loader: {
    display: 'inline-flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  },
}));

function LoadingButton({
  className,
  buttonClass,
  children,
  color,
  loadingColor,
  loading,
  ProgressProps,
  ...rest
}) {
  const classes = useStyles();
  const progressClassName = clsx(classes.loader, ProgressProps.className, {
    loading,
  });

  return (
    <div
      className={clsx(classes.container, className, {
        [classes.fullwidth]: rest.fullwidth,
      })}
    >
      <Button
        className={clsx(classes.button, buttonClass)}
        color={color}
        disabled={rest.disabled || loading}
        {...rest}
      >
        {children}
      </Button>
      <div className={progressClassName}>
        {loading && (
          <CircularProgress
            color={loadingColor || color}
            {...ProgressProps}
          />
        )}
      </div>
    </div>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  ProgressProps: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  buttonClass: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  loadingColor: PropTypes.string,
};

LoadingButton.defaultProps = {
  loading: false,
  ProgressProps: {
    size: 28,
  },
};

export default LoadingButton;
