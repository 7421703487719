import { appActions, appSelectors } from 'providers/AppProvider/data';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions } from 'states/Search';

export const useAllLocations = () => {
  const dispatch = useDispatch();
  const locations = useSelector(appSelectors.locations);

  const getAllLocations = async () => {
    if (locations.length) return;
    try {
      const res = await dispatch(appActions.getLocations());
      const data = Object?.values(res)?.[0]?.id;

      dispatch(searchActions.changeLocationAsync(data));
    } catch (e) {
      console.warn(e);
      return e;
    }
  };

  return {
    getAllLocations,
  };
};
