import React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
  const { children, direction } = props;
  const trigger = useScrollTrigger({
    target: document.getElementById('scroller') || undefined,
  });

  return (
    <Slide
      appear={false}
      direction={direction}
      in={!trigger}
    >
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  direction: PropTypes.string,
};

HideOnScroll.defaultProps = {
  direction: 'up',
};

export default HideOnScroll;
