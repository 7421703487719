export function getStateValue(state, key, param, isLoader, defaultReturn) {
  if (!state) {
    return defaultReturn;
  }

  if (!key) {
    return new Error('Key is missing');
  }

  if (!param) {
    return new Error('Parameter is missing');
  }

  if (state[key]) {
    return isLoader ? state[key][param] : state[key].data[param];
  }

  return defaultReturn || null;
}
