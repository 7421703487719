import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import lodash from 'lodash';
import { makeStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/styles';
import CategoryFilter from './CategoryFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  selectFilter: {
    margin: '0 auto',
    '& fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  inputBox: {
    display: 'flex',
    height: 42,
    margin: '2px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currencyFilter: {
    width: `calc(16.5% - ${theme.spacing(1)}px)`,
    padding: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
}));

function CategoryFilters(props) {
  const {
    filters,
    options,
    parameters,
    onOptionClick,
    onParameterUpdate,
    isLoading,
    submitButton,
    resetButton,
    currencyFilter,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const filterSkeleton = (
    <div
      className={classes.inputBox}
      style={{
        width: `calc(33.33% - ${theme.spacing(1)}px)`,
        marginBottom: theme.spacing(1),
      }}
    >
      <MuiSkeleton
        variant="rect"
        height={37}
        width="100%"
        style={{
          borderRadius: '10px',
          margin: 'auto',
        }}
      />
    </div>
  );

  const skeletons = lodash.range(9).map(() => filterSkeleton);

  const renderFilters = filters.map((filter) => {
    if (filter?.from_to) {
      const width =
        filter.type === 'select'
          ? `calc(16.5% - ${theme.spacing(1)}px)`
          : `calc(33.33% - ${theme.spacing(1)}px)`;

      let categoryFilterProps = {
        filter,
      };

      if (filter.type === 'select') {
        categoryFilterProps = {
          ...categoryFilterProps,
          options,
          onOptionClick,
        };
      } else {
        categoryFilterProps = {
          ...categoryFilterProps,
          parameters,
          onParameterUpdate,
        };
      }

      return (
        <div
          className={classes.inputBox}
          style={{ width }}
          key={filter?.id}
        >
          {/* {input} */}
          <CategoryFilter {...categoryFilterProps} />
        </div>
      );
    }
  });

  const fill = useMemo(() => {
    const cellsNum =
      filters.reduce((acc, f) => acc + (f.type === 'select' ? 0.5 : 1), 0) +
      0.5;
    return lodash.range((3 - (cellsNum % 3)) / 0.5).map((_, idx) => (
      <div
        className={classes.inputBox}
        style={{
          flexBasis: '15%',
        }}
        key={idx}
      />
    ));
  }, [filters, classes]);
  return (
    <>
      <div className={classes.root}>
        {isLoading ? skeletons : renderFilters}
        <div className={clsx(classes.inputBox, classes.currencyFilter)}>
          {currencyFilter}
        </div>
      </div>
      <div className={classes.btns}>
        <div
          style={{
            marginRight: 40,
          }}
        >
          {resetButton}
        </div>
        <div
          style={{
            width: 146,
          }}
        >
          {submitButton}
        </div>
      </div>
    </>
  );
}

CategoryFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionClick: PropTypes.func,
  parameters: PropTypes.arrayOf(PropTypes.object),
  onParameterUpdate: PropTypes.func,
  submitButton: PropTypes.any,
  resetButton: PropTypes.any,
  currencyFilter: PropTypes.any,
};

export default CategoryFilters;
