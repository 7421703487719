import { defineMessages } from 'react-intl';

export const scope = 'Auth';

export default defineMessages({
  signin: {
    id: `${scope}.signin`,
    defaultMessage: 'Войти',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'Регистрация',
  },
  noAcc: {
    id: `${scope}.noAcc`,
    defaultMessage: 'У вас нет аккаунта?',
  },
  hasAcc: {
    id: `${scope}.hasAcc`,
    defaultMessage: 'Уже зарегистрированы?',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Имя',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Номер телефона или email',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Пароль',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Подтвердить',
  },
  confirmText: {
    id: `${scope}.confirmText`,
    defaultMessage:
      'на данный номер отправлен SMS с 6-значным кодом для подтверждения',
  },
  confirmEmail: {
    id: `${scope}.confirmEmail`,
    defaultMessage: 'Код подтверждения отправлен на почту ',
  },
  confirmTitle: {
    id: `${scope}.confirmTitle`,
    defaultMessage: 'Введите код подтверждения',
  },
  policy: {
    id: `${scope}.policy`,
    defaultMessage: 'Продолжая, я подтверждаю, что ознакомлен с',
  },
  terms: {
    id: `${scope}.terms`,
    defaultMessage: 'Условиями пользования',
  },
  policyEnd: {
    id: `${scope}.policyEnd`,
    defaultMessage: 'и принимаю их',
  },
  authFail: {
    id: `${scope}.authFail`,
    defaultMessage: 'Неправильный логин или пароль!',
  },
  userExists: {
    id: `${scope}.userExists`,
    defaultMessage: 'Такой пользователь уже существует!',
  },
  emailAlreadyInUse: {
    id: `${scope}.emailAlreadyInUse`,
    defaultMessage: 'Такой пользователь уже существует!',
  },
  wrongNumber: {
    id: `${scope}.wrongNumber`,
    defaultMessage: 'Номер телефона введен неверно! Попробуйте еще раз',
  },
  wrongWhatsapp: {
    id: `${scope}.wrongWhatsapp`,
    defaultMessage:
      'Номер телефона для WhatsApp введен неверно! Попробуйте еще раз',
  },
  phoneExist: {
    id: `${scope}.phoneExist`,
    defaultMessage: 'Пользователь с таким номером уже существует!',
  },
  invalidCode: {
    id: `${scope}.invalidCode`,
    defaultMessage: 'Код подтвердждения не совпдает!',
  },
  passwordForgot: {
    id: `${scope}.passwordForgot`,
    defaultMessage: 'Забыли пароль?',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Отправить',
  },
  passwordRecoveryTitle: {
    id: `${scope}.passwordRecoveryTitle`,
    defaultMessage: 'Отправить',
  },
  passwordRecoverySubTitle: {
    id: `${scope}.passwordRecoverySubTitle`,
    defaultMessage: 'Отправить',
  },
  passwordRecoveryTitleSecondPiece: {
    id: `${scope}.passwordRecoveryTitleSecondPiece`,
    defaultMessage: 'Отправить',
  },
  phoneFormat: {
    id: `${scope}.phoneFormat`,
    defaultMessage: 'Телефон в формате: +996*********',
  },
  emailSendText: {
    id: `${scope}.emailSendText`,
    defaultMessage: 'Ваши изменения успешно сохранены!',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Произошла ошибка',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Введите новый пароль',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Сохранить',
  },
  notValidEmail: {
    id: `${scope}.notValidEmail`,
    defaultMessage: 'Проверьте правильность адреса почты',
  },
  otherRegistration: {
    id: `${scope}.otherRegistration`,
    defaultMessage: 'или',
  },
  PasswordTip: {
    id: `${scope}.PasswordTip`,
    defaultMessage:
      'Пароль должен содержать минимум 6 символов, цифры и буквы латинского алфавита (a-z)',
  },
  wrongEmail: {
    id: `${scope}.wrongEmail`,
    defaultMessage: 'Неправильный e-mail',
  },
  emptyEmail: {
    id: `${scope}.emptyEmail`,
    defaultMessage: 'Логин пустой!',
  },
});
