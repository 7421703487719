import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  modal: {
    '&:focus': {
      outline: 'none',
    },
    position: 'relative',
  },
  root: {
    bottom: '10%',
    maxWidth: 400,
    width: '100%',
    margin: '10% auto',
    padding: theme.spacing(3),
    position: 'relative',
    '@media (max-width: 920px)': {
      marginTop: '25%',
    },
    '@media (max-width: 760px)': {
      marginTop: '45%',
    },
    '@media (max-width: 420px)': {
      marginTop: '50%',
      maxWidth: '340px',
      padding: '7px 20px 15px',
    },
    borderRadius: '8px !important',
  },
  close: {
    position: 'absolute',
    top: -18,
    right: -45,

    [theme.breakpoints.down('sm')]: {
      right: -37,
    },

    '& svg': {
      fill: '#fff',
      width: 25,
      height: 25,
    },
  },
  form: {
    '& .MuiTextField-root': {
      background: '#FFFFFF',
      borderRadius: 5,
      height: 40,
      width: '100%',
      marginBottom: '15px',
    },
  },
  submitButton: {
    borderRadius: '5px',
    textTransform: 'inherit',
    marginTop: '22px',
    color: '#0A142F',
    height: 45,
    fontWeight: '400',
    width: '100%',
    '& button': {
      boxShadow: 'none !important',
    },
    boxShadow: 'none !important',
    padding: '16px 0 !important',
    '@media (max-width: 420px)': {
      width: '100%',
      margin: '35px 0px 0px !important',
    },
  },

  extraText: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    margin: '7px 0px -5px',
  },
  extraTextPassword: {
    fontSize: '12px ',
    margin: '3px 0',
    lineHeight: '15px',
    display: 'block',
  },
  extraTextPasswordGood: {
    fontSize: '12px ',
    margin: '3px 0',
    lineHeight: '15px',
    display: 'block',
  },
  extraTextBtn: {
    color: '#0A142F',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    fontWeight: 'bold',
  },

  bottomText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '& span': {
      fontSize: 14,
      margin: '0 5px',
    },
    '& span:last-child': {
      cursor: 'pointer',
    },
  },
  confirmTitle: {
    textAlign: 'center',
    fontSize: '32px !important',
    lineHeight: '32px !important',
    fontWeight: 400,
    color: '#000',
    marginBottom: '20px !important',
  },
  confirmText: {
    textAlign: 'center',
    color: '#3B414B',
    marginBottom: '40px !important',
  },
  codeInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100% !important',
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      margin: '0 5px',
      border: '1px solid #E8E8E8',
      position: 'relative',
    },

    '& input:before': {
      content: '_',
      position: 'absolute',
      display: 'inline',
      bottom: 5,
      width: '90%',
      height: 1,
      background: '#E8E8E8',
    },

    '& input:first-child, input:last-child': {
      margin: 0,
      border: '1px solid #E8E8E8',
      borderRadius: '0 !important',
    },
  },
  policy: {
    color: '#353535',
    textAlign: 'center',
    fontSize: 10,

    '& a': {
      textDecoration: 'none',
    },
  },
  passwordRecovery: {
    color: '#2D2D2D',
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center',
    margin: '0 auto',
    display: 'block !important',
    marginTop: 10,

    '& button': {
      textTransform: 'inherit',
      width: 'fit-content',
    },
  },
  passwordRecoveryTitle: {
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 500,
    textAlign: 'center',
    '@media (max-width: 420px)': {
      fontSize: '16px',
    },
  },
  primaryBlue: {
    color: theme.palette.primary.blue,
  },
  textField: {
    padding: '13px 0 13px 0px !important',
    '& input': {
      '&::-webkit-input-placeholder': {
        fontSize: '12px',
      },
      '&::-moz-placeholder': {
        fontSize: '12px',
      },
      '&:-moz-placeholder': {
        fontSize: '12px',
      },
      '&:-ms-input-placeholder': {
        fontSize: '12px',
      },
      border: '1px solid #E8E8E8 !important',
    },
  },
  loginRoot: {
    marginTop: '10px',
  },
  imgSize: { width: '100%', height: '100%' },
}));
