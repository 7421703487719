import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { List, ListItem, Box, Button } from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import {
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
} from '@material-ui/icons';
import { useStyles } from './styles';
import Skeleton from './Skeleton';
import Mobile from './Mobile';

function IconCategories(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 785px)');

  const [showAll, setShowAll] = useState(false);
  const { categories, messages, intl } = props;
  if (!categories) return <Skeleton />;

  if (isMobile) {
    return (
      <Mobile
        categories={categories}
        intl={intl}
      />
    );
  }
  return (
    <Box className={classes.root}>
      <h2 className={classes.title}>
        {intl.locale === 'ru' ? 'Главные категории' : 'Негизги категориялар'}
      </h2>
      <List
        className={classes.categoryList}
        style={{
          flexWrap: 'wrap',
          overflowX: 'visible',
        }}
      >
        {[...categories]
          .slice(0, showAll ? categories.length : 5)
          .map((category) => {
            return (
              <ListItem
                key={category?.id}
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), url(${category.image})`,
                  marginBottom: 15,
                }}
                className={classes.category}
                component={NavLink}
                to={`/category/${category.title_slug}`}
              >
                {category[`title_${intl.locale}`]}
              </ListItem>
            );
          })}
      </List>
      <Button
        className={classes.showAllBtn}
        onClick={() => setShowAll((prev) => !prev)}
        endIcon={
          showAll ? <KeyboardArrowUpSharp /> : <KeyboardArrowDownSharp />
        }
      >
        {showAll
          ? intl.formatMessage({ ...messages.hide })
          : intl.formatMessage({ ...messages.allCategories })}
      </Button>
    </Box>
  );
}
IconCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  messages: PropTypes.object.isRequired,
  intl: PropTypes.object,
};
export default memo(IconCategories);
