import { makeStyles } from '@material-ui/styles';

const iconSize = 35;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%',
    borderRadius: 10,
    position: 'relative',
    marginTop: '2em',
    marginBottom: '2em',
    fontSize: 16,

    '& ::-webkit-scrollbar': {
      width: 4,
      height: 7,
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: 5,
    },

    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      boxShadow: 'none',
      marginTop: 5,
      marginBottom: 5,
      paddingLeft: 8,
    },
  },
  selectedCategoryRoot: {
    paddingTop: theme.spacing(3),
  },
  subCategoriesContainer: {
    transition: 'all .2s',
  },
  currentCategory: {},
  subCategory: {
    color: theme.palette.primary.blue,
    fontSize: '1.5em',
  },
  subCategoriesTitle: {
    display: 'inline-block',
    fontSize: 18,
    textDecoration: 'none',
    color: 'inherit',
  },
  withDecoration: {
    '&:before': {
      content: '"•"',
      margin: '0 4px 0 6px',
    },
    '&:last-child': {
      fontWeight: 'bold',
    },
  },
  showMoreCategories: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.blue,

    '& a': {
      color: 'inherit',
    },

    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: theme.spacing(4),
    },
  },

  categoryList: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  category: {
    width: 'calc(20% - 15px)',
    height: `${theme.spacing(6)}px`,
    lineHeight: `${theme.spacing(2.25)}px`,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 500,
    color: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: 0,
    paddingBottom: 0,
  },
  categoryIcon: {
    width: iconSize,
    height: iconSize,
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      width: iconSize + 5,
      height: iconSize + 5,
    },
  },
  categoryTitle: {
    fontSize: theme.spacing(1.4),
    color: '#666666',
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
  brokenImage: {
    width: 45,
    height: 45,
    marginBottom: theme.spacing(1),
    color: '#1CCA9B',
  },
  list: {
    background: '#fff',
    maxHeight: '50vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',

    '& ul': {
      maxHeight: '50vh',
    },

    '& ::-webkit-scrollbar': {
      width: 4,
    },

    '& ::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    '& ::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: 5,
    },

    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
  wrapList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    height: 120,
  },
  childrenButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    overflowX: 'auto',
    width: '85%',
    height: 78,
    scrollbarWidth: 'thin',
    paddingLeft: 8,
    paddingBottom: 8,

    '& ::-webkit-scrollbar': {
      width: 4,
      height: 8,
    },

    '& ::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    '& ::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: 5,
    },

    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },

    [theme.breakpoints.down('sm')]: {
      width: '67%',
    },
  },
  withoutIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '92%',
      paddingLeft: 0,
    },
  },
  childrenButtonsWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',

    '& button': {
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
      borderRadius: 5,
      background: '#fff',
      margin: 0,
      marginRight: theme.spacing(1),
      whiteSpace: 'nowrap',
      padding: '5px 26px 6px 25px',
      fontSize: 12,
      height: 25,
      color: theme.palette.primary.blue,

      '& span': {
        whiteSpace: 'nowrap',
        display: 'block',
        width: 'fit-content',
      },
    },
  },
  active: {
    background: `${theme.palette.primary.blue} !important`,
    color: '#fff !important',
  },
  showAllBtn: {
    display: 'flex',
    margin: '0 auto',
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.blue,

    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: theme.spacing(4),
    },
  },
  backButton: {
    minWidth: 35,
    margin: 0,
    background: '#fff',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
    border: `1px solid ${theme.palette.primary.blue}`,
    height: 78,

    '& svg': {
      fill: theme.palette.primary.blue,
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 3,
      paddingRight: 3,
      minWidth: 20,
    },
  },
  nestedMenuItem: {
    '& ul': {
      maxHeight: '50vh',
    },
  },
  shimmer: {
    width: '100%',
    height: 120,
    borderRadius: 10,
  },
  shimmerItem: {
    borderRadius: 10,
  },
  listItem: {
    width: '100%',
    cursor: 'pointer',
    height: '40px',
    paddingTop: '10px',
    '& a': {
      width: '100%',
      color: '#000000DE',
      textDecoration: 'none',
      fontSize: '14px',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  itemList: {
    minWidth: '220px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
