import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  from: {
    '& input::placeholder': {
      color: '#666',
      opacity: 1,
    },
    '&.MuiFormControl-marginDense': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-root': {
      borderBottomLeftRadius: '10px',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  to: {
    '& input::placeholder': {
      color: '#666',
      opacity: 1,
    },
    '&.MuiFormControl-marginDense': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-root': {
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

function FromTo(props) {
  const { filter, parameter, onChange, intl } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TextField
        margin="dense"
        variant="outlined"
        autoComplete="off"
        value={parameter?.from || ''}
        onChange={(e) =>
          onChange({
            id: filter?.id,
            from: e.target.value,
            to: parameter?.to || '',
            type: 'enter',
          })
        }
        placeholder={`${filter[`title_${intl?.locale}`]} от`}
        className={classes.from}
      />
      <TextField
        margin="dense"
        variant="outlined"
        autoComplete="off"
        value={parameter?.to || ''}
        onChange={(e) =>
          onChange({
            id: filter?.id,
            from: parameter?.from || '',
            to: e.target.value,
            type: 'enter',
          })
        }
        placeholder="до"
        className={classes.to}
      />
    </div>
  );
}

FromTo.propTypes = {
  filter: PropTypes.object.isRequired,
  parameter: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  intl: intlShape,
};

export default injectIntl(FromTo);
