import { setLocal } from 'common/helpers';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export function changeLocale(languageLocale) {
  setLocal('__ts:locale', languageLocale);
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}
