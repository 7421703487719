import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { paths } from 'common/constants';

const SubCategoryList = ({ cat, intl, onClick, classes }) => {
  const [listOpen, setListOpen] = useState(false);

  const scrollable = useMemo(() => {
    return cat?.children?.length > 6 && listOpen;
  }, [listOpen, cat]);

  return (
    <Box
      width="calc(33% - 40px)"
      margin="20px 40px 0 0"
    >
      <Typography
        variant="h6"
        component={NavLink}
        to={paths.CATEGORY_PAGE(cat.title_slug)}
        className={classes.categoryLink}
        style={{
          fontWeight: 'bold',
        }}
        onClick={onClick}
      >
        {cat[`title_${intl.locale}`]}
      </Typography>
      <List
        // disablePadding
        className={classes.childCategoryList}
        data-component-type={scrollable ? 'scrollable' : 'common'}
        style={{
          maxHeight: '168px',
          overflowY: scrollable ? 'scroll' : 'visible',
        }}
      >
        {cat?.children &&
          cat.children.length > 0 &&
          cat.children
            .slice(0, listOpen ? cat.children.length : 4)
            .map((child) => (
              <ListItem
                key={child.id}
                component={NavLink}
                to={`/category/${child.title_slug}`}
                onClick={onClick}
                className={`${classes.categoryLink} ${classes.listItem}`}
                // disablePadding
              >
                <ListItemText primary={child[`title_${intl.locale}`]} />
              </ListItem>
            ))}
      </List>
      {cat?.children?.length > 4 && (
        <Button
          className={classes.showMoreBtn}
          onClick={() => setListOpen((prevState) => !prevState)}
          endIcon={listOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          disableTouchRipple
          // disablePadding
        >
          {listOpen ? 'Скрыть' : 'Ещё'}
        </Button>
      )}
    </Box>
  );
};

SubCategoryList.propTypes = {
  cat: PropTypes.object,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  intl: intlShape,
};

export default SubCategoryList;
