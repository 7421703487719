import React from 'react';
import { Container, Grid } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';

export default function Skeleton() {
  return (
    <Container
      maxWidth="md"
      disableGutters
    >
      <Grid
        container
        spacing={2}
      >
        {new Array(6).fill(null).map((_, idx) => (
          <Grid
            item
            xs={2}
            key={idx}
          >
            <MuiSkeleton
              variant="rect"
              height={37}
              width="100%"
              style={{
                borderRadius: '10px',
                margin: 'auto',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
