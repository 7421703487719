import { defineMessages } from 'react-intl';

export const scope = 'Common';

export default defineMessages({
  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: 'Обязательное поле для заполнения',
  },
  tooShortPass: {
    id: `${scope}.tooShortPass`,
    defaultMessage: 'Формат пароля минимум 6 символа',
  },
  notSamePass: {
    id: `${scope}.notSamePass`,
    defaultMessage: 'Пароли не совпадают',
  },
});
