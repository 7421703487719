import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import ProductListComponent from 'components/ProductList';
import Skeleton from 'components/ProductCard/Skeleton';
import Grid from '@material-ui/core/Grid';

// Data
import { searchSelectors } from 'states/Search';
import { productSelectors } from 'states/Products';

import messages from 'messages/Product';
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NoResults from './NotFound';
import { productActions } from '../../states/Products';
import { useProductList } from 'hooks/useProductList';
import { useCategories } from 'hooks/useCategories';
import { appSelectors } from 'providers/AppProvider/data';
import { requester } from 'services/requester';

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '90%',
    marginLeft: '5%',
    minHeight: '200px',
    marginTop: '20px',
    cursor: 'pointer',
    '@media (min-width: 1920px)': {
      width: '1110px',
      marginLeft: '0',
    },
    '@media (max-width: 720px)': {
      display: 'none',
    },
  },
  centeredForBanner: {
    '@media (min-width: 1920px)': {
      display: 'flex',
      justifyContent: 'center',
    },
    '@media (max-width: 720px)': {
      display: 'none',
    },
  },
}));

const ProductList = ({ intl }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 720px)');

  const categoriesObj = useSelector(appSelectors.categoriesObj);

  const { getCategories, requestCategories } = useCategories();

  useEffect(() => {
    if (JSON.stringify(categoriesObj).length > 3 || requestCategories) return;
    getCategories();

    return () => {};
  }, []);

  const productList = useSelector(productSelectors.productList);
  const hasMore = useSelector(productSelectors.hasMore);
  const notFound = useSelector(productSelectors.notFound);
  const currency = useSelector(searchSelectors.currency);
  const selectedCategory = useSelector(searchSelectors.selectedCategory);

  const { fetchProductList, params } = useProductList();

  useEffect(() => {
    fetchProductList(0, false);
    dispatch(productActions.setPrevParams({ ...params }));

    return () => {};
  }, [params]);

  if (isMobile) {
    document.getElementById('scroller').style.background = '#fff';
  }

  const loader = useMemo(
    () => (
      <Grid
        container
        spacing={3}
        style={{ marginTop: '1em' }}
      >
        {new Array(8).fill(null).map((_, idx) => (
          <Grid
            key={idx}
            item
            xs={6}
            sm={4}
            md={3}
          >
            <Skeleton />
          </Grid>
        ))}
      </Grid>
    ),
    [],
  );

  if (notFound) {
    //  return (
    //   <>{loader}</>
    //  )
    return (
      <NoResults
        intl={intl}
        messages={messages}
      ></NoResults>
    );
  }

  return !productList ? (
    loader
  ) : (
    <ProductListComponent
      products={productList}
      fetchMore={fetchProductList}
      hasMore={hasMore}
      currency={currency}
      intl={intl}
      messages={messages}
      title={
        categoriesObj && categoriesObj[selectedCategory]
          ? categoriesObj[selectedCategory][`title_${intl.locale}`]
          : intl.formatMessage({ ...messages.newProducts })
      }
    />
  );
};

ProductList.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(ProductList);
