import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15px',
    height: 255,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
    borderRadius: 7,
    textDecoration: 'none',
    position: 'relative',
    // paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: 225,
    },
  },
  personalCard: {
    height: 290,

    [theme.breakpoints.down('sm')]: {
      height: 260,
    },
  },
  actions: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: '1px solid #DADADA;',
  },
  image: {
    height: 160,
    objectFit: 'contain',

    [theme.breakpoints.down('sm')]: {
      height: 130,
    },
  },
  noImage: {
    width: '100%',
    height: '100%',
    fill: '#CCCFD3 !important',
    objectFit: 'cover',
  },
  starButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    width: 35,
    height: 35,
  },
  favorite: {
    fill: theme.palette.primary.blue,
    width: 30,
    height: 30,
  },
  content: {
    padding: 10,

    '&.MuiCardContent-root:last-child': {
      paddingBottom: 16,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(2),
    textDecoration: 'none',
  },
  noAvatar: {
    border: '1px solid #B5B5B5',
    '& img': {
      width: '60%',
      height: '60%',
    },
  },
  price: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#000',
  },
  description: {
    fontSize: 12,
    color: '#434343',
    maxHeight: 40,
    textOverflow: 'ellipsis',
    maxWidth: 240,
    overflow: 'hidden',
  },
  skeletImg: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    marginBottom: 5,

    [theme.breakpoints.down('sm')]: {
      height: '130px !important',
    },
  },
  skeletRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 5,
  },
  skeletText: {
    margin: '5px auto',
  },
}));
