import React from 'react';
import BusinessNameProduct from './components/BusinessNameProduct';
import EditCard from './components/EditCard';
import EditCardNoReview from './components/EditCardNoReview';
import ParentLocation from './components/Location';
import ProductState from './productState';

export const getComponents = (type, payload, propsItem) => {
  switch (type) {
    case 'getEditCard': {
      if (payload) {
        return <EditCard {...propsItem} />;
      }
      break;
    }
    case 'getEditCardNoReview': {
      if (payload) {
        return <EditCardNoReview {...propsItem} />;
      }
      break;
    }
    case 'BusinessNameProduct': {
      if (payload) {
        return <BusinessNameProduct {...propsItem} />;
      }
      break;
    }
    case 'getLocation': {
      return <ParentLocation {...propsItem} />;
    }
    case 'getProductState': {
      return <ProductState {...propsItem} />;
    }
    default:
      break;
  }
};
