import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import messages from 'messages/AlertDialog';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertDialog(props) {
  const { title, description, onAgree, open, onClose, intl } = props;

  const handleClose = () => {
    onClose();
  };

  const handleAgree = () => {
    onClose();
    onAgree();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
          >
            {intl.formatMessage({ ...messages.no })}
          </Button>
          <Button
            onClick={handleAgree}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({ ...messages.yes })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onAgree: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  intl: intlShape,
};

export default injectIntl(AlertDialog);
