import React, { useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { CardActions, IconButton } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';

import { useStyles, CustomSwitch } from '../styles';
import DeleteIcon from 'assets/images/deletes.svg';
import edit from 'assets/images/editBlue.svg';
import chart from 'assets/images/chart.svg';
import { useOutsideClick } from 'hooks/useOutSideClick';

const EditCard = (props) => {
  const {
    intl,
    product,
    messages,
    editCard,
    checkHandler,
    checked,
    handleAgree,
    open,
    setOpen,
    handleClick,
  } = props;
  const classes = useStyles();

  const editCardRef = useRef(null);

  useOutsideClick(editCardRef, handleClick, editCard);

  return (
    <>
      <div
        ref={editCardRef}
        className={editCard ? classes.chartAndEdit : classes.chartAndEditNone}
      >
        <div className={classes.chartAndEditContainer}>
          <div className={classes.switch}>
            <CustomSwitch
              onClick={checkHandler}
              checked={!checked}
              color="primary"
            />{' '}
            <p>
              {!checked
                ? intl.formatMessage({ ...messages.activationMessage })
                : intl.formatMessage({ ...messages.deactivationMessage })}
            </p>
          </div>
          {product?.user.is_business &&
            product?.user.request_status !== 'expired' && (
              <NavLink to={`/profile/statistics/${product?.id}`}>
                <div className={classes.navLinkText}>
                  <img
                    src={chart}
                    alt="График"
                  />
                  <p>Статистика</p>
                </div>
              </NavLink>
            )}
          <NavLink to={`/profile/my-products/edit/${product?.id}/`}>
            <div className={classes.changeButton}>
              <img
                src={edit}
                alt="Изменить"
              />
              <p>
                {intl?.formatMessage({
                  ...messages.activatedMessage,
                })}
              </p>
            </div>
          </NavLink>
          <CardActions className={classes.actions}>
            <IconButton
              onClick={() => {
                setOpen({
                  title: intl?.formatMessage({
                    ...messages.deleteProductAlertTitle,
                  }),
                  description: intl?.formatMessage({
                    ...messages.deleteProductAlertDescription,
                  }),
                  method: 'delete',
                });
              }}
            >
              <img
                alt=""
                src={DeleteIcon}
                style={{ marginRight: '25px' }}
                width="20"
                height="20"
              />
              <p>
                {intl?.formatMessage({
                  ...messages.delationProduct,
                })}
              </p>
            </IconButton>
          </CardActions>
          <AlertDialog
            open={!!open}
            title={open ? open?.title : ''}
            description={open ? open?.description : ''}
            onClose={() => setOpen(null)}
            onAgree={() => handleAgree(open?.method)}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(EditCard);
