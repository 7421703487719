import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import NodeSelect from 'components/NodeSelect';
import Select from 'components/Select';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions, searchSelectors } from 'states/Search';

import { MenuItem, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { injectIntl, intlShape } from 'react-intl';
import messages from 'messages/Filter';
import { appSelectors } from 'providers/AppProvider/data';
import { useAllLocations } from 'hooks/useAllLocations';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  inputBox: {},
  orderFilterIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  orderTextField: {
    '&.MuiFormControl-marginDense': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  orderSelect: {
    margin: '0 auto',
    color: '#666',
    borderRadius: '10px',

    '& fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  locationsNodeSelect: {
    minWidth: 245,
    marginTop: 0,
    color: '#666',
  },
}));

function ProductListFilter(props) {
  const { isLoading, onlyLocation, intl } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const locationsObj = useSelector(appSelectors.locationsObj);
  const locations = useSelector(appSelectors.locations);

  const selectedLocation = useSelector(searchSelectors.selectedLocation);

  const order = useSelector(searchSelectors.order);
  const theme = useTheme();

  const regions = useMemo(() => {
    if (locations && locations[0]) {
      return [
        {
          id: locations[0].id,
          title_ru: 'Все',
          title_ky: 'Бары',
        },
        ...locations[0].children,
      ];
    }

    return [];
  }, [locations]);

  const { getAllLocations } = useAllLocations();

  useEffect(() => {
    getAllLocations();

    return () => {};
  }, []);

  const filterSkeleton = (
    <MuiSkeleton
      variant="rect"
      height={37}
      width={150}
      style={{
        borderRadius: '10px',
        margin: 'auto',
      }}
    />
  );

  if (isLoading) {
    return (
      <div className={classes.root}>
        <div
          className={classes.inputBox}
          style={{
            marginRight: 8,
          }}
        >
          {filterSkeleton}
        </div>
        <div className={classes.inputBox}>{filterSkeleton}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.inputBox}>
        <NodeSelect
          className={classes.locationsNodeSelect}
          nodes={regions}
          name="ProductsListSelectedLocation"
          selected={
            selectedLocation === 'all' ? 'all' : locationsObj[selectedLocation]
          }
          onSelect={(e) => {
            dispatch(searchActions.changeLocationAsync(e.target.value));
          }}
          inputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
        />
      </div>
      {!onlyLocation && (
        <div className={classes.inputBox}>
          <Select
            name="productListOrder"
            style={{
              marginLeft: theme.spacing(2),
            }}
            defaultValue="-upvote_date"
            value={order}
            onChange={(val) => {
              dispatch(searchActions.changeOrderAsync(val));
            }}
          >
            <MenuItem value="-upvote_date">
              {intl.formatMessage({ ...messages.new })}
              {/* Сначала новые */}
            </MenuItem>
            <MenuItem value="price">
              {intl.formatMessage({ ...messages.cheap })}
              {/* Сначала дешевле */}
            </MenuItem>
            <MenuItem value="-price">
              {intl.formatMessage({ ...messages.expensive })}
              {/* Сначала дороже */}
            </MenuItem>
          </Select>
        </div>
      )}
    </div>
  );
}

ProductListFilter.propTypes = {
  isLoading: PropTypes.bool,
  onlyLocation: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(ProductListFilter);
