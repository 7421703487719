import { defineMessages } from 'react-intl';

export const scope = 'Alert';

export default defineMessages({
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Да',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Нет',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Успешно добавлено',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Успешно удалено',
  },
  errorRemove: {
    if: `${scope}.errorRemove`,
    defaultMessage: 'Ошибка удаления',
  },
});
