import React, { memo } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import Desktop from './Desktop';

const Footer = (props) => {
  const { intl } = props;

  return <Desktop intl={intl} />;
};

Footer.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(Footer);
