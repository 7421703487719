import React from 'react';
import { MyRadio, useStyles } from '../styles';
import ProductStateActive from './ProductStateActive';
import ProductStateNotActive from './ProductStateNotActive';

import { Box, FormControlLabel, Typography } from '@material-ui/core';
import SvgFavorite from '../icons/IsFavorite';
import PriceAndTitle from '../components/PriceAndTitle';

const ProductState = ({
  product,
  favoriteHandler,
  setFill,
  favoriteProducts,
  disableLink,
  onReview,
  isSelect,
  isMyProfile,
  selectHandler,
  intl,
  favorite,
  messages,
  user,
  fill,
  select,
  discountPrice,
  haventImage,
  openAuthForm,
}) => {
  const classes = useStyles();
  const getComponent = (type, payload) => {
    switch (type) {
      case 'getDiscount': {
        if (payload) {
          return (
            <Box className={classes.discount}>
              <Typography component={'p'}>-{discountPrice}%</Typography>
            </Box>
          );
        }
        break;
      }
      case 'getIsSelectProfile': {
        if (payload) {
          return (
            <div
              className={classes.select}
              onClick={selectHandler}
            >
              <FormControlLabel
                value="check"
                control={
                  <MyRadio
                    color="primary"
                    checked={select}
                  />
                }
              />
            </div>
          );
        }
        break;
      }
      case 'getIsNotSelectProfile': {
        if (payload) {
          return (
            <SvgFavorite
              user={user}
              product={product}
              openAuthForm={openAuthForm}
              favorite={favorite}
              favoriteProducts={favoriteProducts}
              favoriteHandler={favoriteHandler}
              setFill={setFill}
              fill={fill}
            />
          );
        }
        break;
      }
      case 'getOnReview': {
        if (payload) {
          return <div className={classes.onReviewClass}>На расмотрении</div>;
        }
        break;
      }
      case 'getPriceAndTitle': {
        return (
          <PriceAndTitle
            intl={intl}
            product={product}
            messages={messages}
            isDiscount={isDiscount}
            alert={alert}
            onReview={onReview}
          />
        );
      }
      default:
        break;
    }
  };

  const isDiscount =
    product?.user.is_business &&
    product?.user.request_status !== 'expired' &&
    product?.[`discount_price_${product?.currency.toLowerCase()}`];

  return (
    <>
      {product?.state === 'active' ? (
        <ProductStateActive
          getComponent={getComponent}
          isDiscount={isDiscount}
          product={product}
          disableLink={disableLink}
          onReview={onReview}
          isSelect={isSelect}
          isMyProfile={isMyProfile}
          haventImage={haventImage}
        />
      ) : (
        <ProductStateNotActive
          getComponent={getComponent}
          isDiscount={isDiscount}
          onReview={onReview}
          product={product}
          haventImage={haventImage}
          alert={alert}
          isSelect={isSelect}
          isMyProfile={isMyProfile}
        />
      )}
    </>
  );
};

export default ProductState;
