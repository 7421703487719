import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ArrowIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { NavLink, useParams } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

export const CustomAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0
  // },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

export const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  '&.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '48px',
  },
  '&.MuiAccordionSummary-root': {
    padding: 0,
    paddingRight: 16,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 'unset',
  },
}));

function CategoryAccordion(props) {
  const { category, onItemClick, intl } = props;

  const { category: currentCategory, subcategory, tripcategory } = useParams();

  return (
    <CustomAccordion>
      <CustomAccordionSummary>
        <ListItem>
          <ListItemIcon>
            {category?.icon && (
              <img
                src={category.icon}
                alt=""
                width="30"
                height="30"
                loading="lazy"
              />
            )}
          </ListItemIcon>
          <ListItemText>{category[`title_${intl.locale}`]}</ListItemText>
        </ListItem>
      </CustomAccordionSummary>
      <AccordionDetails
        style={{
          display: 'block',
          paddingLeft: 55,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {category?.children?.map((childCat) => (
          <MenuItem
            key={childCat.id}
            component={NavLink}
            to={`/category/${category.title_slug}/${childCat.title_slug}`}
            onClick={onItemClick}
          >
            {childCat[`title_${intl.locale}`]}
          </MenuItem>
        ))}
      </AccordionDetails>
    </CustomAccordion>
  );
}

CategoryAccordion.propTypes = {
  category: PropTypes.object.isRequired,
  onItemClick: PropTypes.func,
  intl: intlShape,
};

export default compose(injectIntl)(CategoryAccordion);
