import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { intlShape } from 'react-intl';

// MUI
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import CustomDrawer from './components/CustomDrawer';
import { KeyboardArrowDownRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'scroll',
    padding: theme.spacing(1),
    fontSize: '14px',

    '&::-webkit-scrollbar': {
      width: 4,
      height: 5,
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
      borderRadius: 5,
    },
  },
  filter: {
    minWidth: 'unset',
    lineHeight: '30px',
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    fontSize: '16px',
    color: 'inherit',
    whiteSpace: 'nowrap',
    boxShadow: '0px 3px 10px rgba(164, 175, 202, 0.25)',
    borderRadius: '5px',

    '&:last-child': {
      marginRight: 0,
    },

    '@media (max-width: 420px)': {
      fontSize: 'inherit',
    },
  },
}));

function Mobile(props) {
  const { categoryParams, intl, ...rest } = props;

  const classes = useStyles();

  const [openedParam, setOpenedParam] = useState(null);

  const scrollRef = useHorizontalScroll();

  const filters = categoryParams?.map((param) => (
    <Button
      className={classes.filter}
      onClick={() => setOpenedParam(param)}
      endIcon={<KeyboardArrowDownRounded style={{ color: '#0051BA' }} />}
    >
      {param[`title_${intl.locale}`]}
    </Button>
  ));

  return (
    <div
      className={classes.root}
      ref={scrollRef}
      {...rest}
    >
      {filters}
      <CustomDrawer
        intl={intl}
        open={!!openedParam}
        onClose={() => setOpenedParam(null)}
        openedParam={openedParam}
      />
    </div>
  );
}

Mobile.propTypes = {
  categoryParams: PropTypes.arrayOf(PropTypes.object),
  intl: intlShape,
};

export default memo(Mobile);
