import axios from 'axios';
import { BASE_URL } from 'fsd/shared/consts/constants';
import { getTokenFromCookies } from 'utils/cookies';

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   exclude: {
//     paths: ['/product/v1/personal/products/']
//   }
// })

export const requester = axios.create({
  baseURL: BASE_URL,
  // adapter: cache.adapter
  // withCredentials: true
});

requester.interceptors.request.use((config) => {
  const customConfig = config;
  const token = getTokenFromCookies();

  if (token) {
    customConfig.headers.common.Authorization = `Bearer ${token}`;
  }

  if (customConfig.data && customConfig.data.noAuth) {
    delete customConfig.data.noAuth;
    delete customConfig.headers.common.Authorization;
  }

  if (customConfig.data && customConfig.data.multi) {
    customConfig.headers.common['Content-Type'] = 'multipart/form-data';
    customConfig.headers.common.Accept = 'multipart/form-data';
  }

  if (customConfig.blob) {
    customConfig.responseType = 'blob';
    customConfig.exposedHeaders = ['Content-Disposition'];
  }

  return customConfig;
});
