/**
 *
 * RouteWithLayout
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { requester } from 'services/requester';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { productActions } from 'states/Products';

const BANNER_UPDATE_DELAY = 5000;
const ADS_BANNER_TYPE = 'background';
const ADS_SEEN_KEY = 'ads_seen';

function RouteWithLayout(props) {
  const { layout: Layout, component: Component, ...rest } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 720px)');
  const [currentBanner, setCurrentBanner] = useState(null);
  const [allBanners, setAllBanners] = useState([]);

  const fetchAds = async () => {
    if (!isMobile) {
      const back = document.getElementById('scroller');
      const res = allBanners.length
        ? allBanners
        : (await dispatch(productActions.getAds())).filter(
            (item) => item.type.name === ADS_BANNER_TYPE,
          );

      const hasAdsInLocalStorage = !!localStorage.getItem(ADS_SEEN_KEY);

      if (!hasAdsInLocalStorage) {
        const ads = res.reduce((acc, item) => {
          return acc.concat({ id: item.id, count: 0 });
        }, []);
        localStorage.setItem(ADS_SEEN_KEY, JSON.stringify(ads));
      }

      if (!allBanners.length) {
        setAllBanners(res);
      }

      let randomBannerIdx = Math.floor(Math.random() * res.length);
      let randomBanner = res[randomBannerIdx];

      while (randomBanner.id === currentBanner?.id) {
        if (res.length <= 1) break;
        randomBannerIdx = Math.floor(Math.random() * res.length);
        randomBanner = res[randomBannerIdx];
      }

      if (!randomBanner.image_link) return;

      setCurrentBanner(randomBanner);
      back.style.background = `url('${randomBanner.image_link}') 50% 0% fixed no-repeat`;
      back.style.backgroundSize = 'cover';

      const adsWithViewCount = JSON.parse(
        localStorage.getItem(ADS_SEEN_KEY) ?? '[]',
      );

      if (
        adsWithViewCount.find(
          (item) => item.id === randomBanner.id && item.count === 0,
        )
      ) {
        await requester.post(`/ads/count-update/${randomBanner.id}/?type=view`);
        localStorage.setItem(
          ADS_SEEN_KEY,
          JSON.stringify(
            adsWithViewCount.map((item) => {
              if (item.id === randomBanner.id) return { ...item, count: 1 };
              return item;
            }),
          ),
        );
      }

      back.onclick = async (e) => {
        if (e.target.id === 'scroller') {
          try {
            await requester.post(
              `/ads/count-update/${randomBanner.id}/?type=click`,
            );
            window.open(randomBanner.link);
          } catch (e) {
            console.log(e);
          }
        }
      };
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!currentBanner) {
      fetchAds();
      return;
    }

    const timeoutId = setTimeout(() => {
      fetchAds();
    }, BANNER_UPDATE_DELAY);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentBanner?.id]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

export default RouteWithLayout;
