/*
 *
 * AdsPage actions
 *
 */

import { createActions } from 'redux-actions';

import { createRequestAction, getResponseError } from 'common/helpers';
import { ENDPOINTS, ACTION_TYPES } from './constants';

const actionCreators = createActions({
  ...createRequestAction(ACTION_TYPES.GET_USER),
});

export function getUser(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getUserRequest());
    try {
      const { data } = await api.get(`${ENDPOINTS.USER}${id}/`);

      dispatch(actionCreators.getUserSuccess(data));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.getUserFail(error));
      return Promise.reject(error);
    }
  };
}

export const actions = {
  ...actionCreators,
  getUser,
};
