import { createBrowserHistory } from 'history';

let history = {
  location: '/',
};

if (typeof document !== 'undefined') {
  history = createBrowserHistory();
}

export default history;
