import { useRef, useEffect } from 'react';

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        e.preventDefault();
        el.scrollTo({
          left:
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1
              ? el.scrollLeft + e.deltaY * 20
              : el.scrollLeft + e.deltaY,
          behavior: 'auto',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
    return () => null;
  }, []);
  return elRef;
}
