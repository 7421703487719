import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import messages from 'messages/Filter';

// MUI
import {
  Typography,
  IconButton,
  Button,
  makeStyles,
  Drawer,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(
      1.1,
    )}px ${theme.spacing(0.5)}px`,
    borderBottom: '1px solid #F4F4F4',
  },
  closeIcon: {
    fontSize: '32px',
    color: theme.palette.primary.blue,
  },
  clearBtn: {
    fontSize: 'inherit',
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    fontWeight: 300,
  },
  filterDrower: {
    fontSize: '16px',
    '& .MuiPaper-root': {
      borderRadius: '15px 15px 0px 0px',
      minHeight: '50%',
      maxHeight: '80%',
    },
  },
  submitBtn: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(4)}px)`,
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontSize: 'inherit',
  },
}));

function DrawerHeader({ title, intl, onClear, onClose }) {
  const classes = useStyles();

  return (
    <div className={classes.drawerHeader}>
      <IconButton onClick={onClose}>
        <Close className={classes.closeIcon} />
      </IconButton>
      <Typography
        variant="h5"
        component="h5"
      >
        {title}
      </Typography>
      <Button
        className={classes.clearBtn}
        onClick={onClear}
      >
        {intl.formatMessage({ ...messages.reset })}
      </Button>
    </div>
  );
}

DrawerHeader.propTypes = {
  title: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape,
};

function DrawerContainer({ className, ...rest }) {
  const classes = useStyles();
  return (
    <Drawer
      anchor="bottom"
      className={`${classes.filterDrower} ${className}`}
      {...rest}
    />
  );
}

DrawerContainer.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const SubmitButton = injectIntl(({ onSubmit, intl, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.submitBtn}
      onClick={onSubmit}
      {...rest}
    >
      {intl.formatMessage({ ...messages.accept })}
    </Button>
  );
});

SubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export { DrawerHeader, DrawerContainer, SubmitButton };
