import React from 'react';
import more from 'assets/images/more.svg';
import locationIcon from 'assets/images/subtractGrey.svg';

import { useStyles } from '../styles';
import { Box, Typography } from '@material-ui/core';

export const Location = ({
  isMobile,
  isMyProfile,
  region,
  intl,
  handleClick,
  product,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.locationBurger}>
      <div className={classes.location}>
        <img
          src={locationIcon}
          alt=""
        />
        <Typography component={'p'}>{region}</Typography>
        <Typography component={'p'}>
          {product?.location?.[`title_${intl?.locale}`]?.slice(0, 10) + '...'}
        </Typography>
      </div>
      <div className={classes.burger}>
        {isMyProfile && (
          <div
            className={classes.moreButton}
            onClick={handleClick}
          >
            <img
              src={more}
              alt="more"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ParentLocation = (
  props,
  { isMyProfile, isSelect, withoutSwitch, onReview },
) => {
  const classes = useStyles();

  return (
    <>
      {isMyProfile && !isSelect && !withoutSwitch && onReview ? (
        <div className={classes.footer}>
          <Location {...props} />
        </div>
      ) : (
        <Box className={classes.locationInfo}>
          <Location {...props} />
        </Box>
      )}
    </>
  );
};

export default React.memo(ParentLocation);
