import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 20px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    color: '#666666',
    zIndex: '-1',
  },
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '960px',
    margin: '0 auto',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderTextField: {
    '&.MuiFormControl-marginDense': {
      marginTop: theme.spacing(0.5),
    },
  },
  defaultFiltersContainer: {
    '&.MuiGrid-spacing-xs-2': {
      width: '100%',
    },
  },
  orderSelect: {
    margin: '0 auto',
    borderRadius: '10px',
    color: 'inherit',

    '& fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  orderFilterIcon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  locationsNodeSelect: {
    marginTop: 0,
    color: 'inherit',
  },
  currencyItems: {
    padding: '4px 17px',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',

    '& .MuiToggleButtonGroup-grouped': {
      marginRight: theme.spacing(1.5),
      border: 0,
      paddingTop: 12,

      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
    '& .MuiToggleButtonGroup-grouped:last-child': {
      marginRight: 0,
    },
  },
  currencyItem: {
    height: '15px',
    width: '40px',
    borderRadius: '5px',

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
    },

    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
    },
  },
  priceFrom: {
    '& input::placeholder': {
      color: '#666',
      opacity: 1,
    },
    '&.MuiFormControl-marginDense': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-root': {
      borderBottomLeftRadius: '10px',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  priceTo: {
    '& input::placeholder': {
      color: '#666',
      opacity: 1,
    },
    '&.MuiFormControl-marginDense': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-root': {
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  showAllBtn: {
    display: 'flex',
    minWidth: '136px',
    // paddingRight: theme.spacing(1),
    // marginRight: theme.spacing(1),
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.blue,
  },
}));
