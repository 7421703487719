export const ACTION_TYPES = {
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  CHANGE_SEARCH: 'CHANGE_SEARCH',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY',
  CHANGE_ORDER: 'CHANGE_ORDER',
  CHANGE_GTE: 'CHANGE_GTE',
  CHANGE_LTE: 'CHANGE_LTE',
  CHANGE_OPTIONS: 'CHANGE_OPTIONS',
  CHANGE_PARAMETERS: 'CHANGE_PARAMETERS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  GET_RECOMMENDATIONS: 'GET_RECOMENDATIONS',
  GET_CATEGORY_AND_CHILDREN: 'GET_CATEGORY_AND_CHILDREN',
  GET_SEARCH_TIPS: 'GET_SEARCH_TIPS',
};

export const ENDPOINTS = {
  CATEGORY_AND_CHILDREN: 'category/v1/categories/',
  // SEARCH_RECOMMENDATION: 'product/v2/suggestions/?query='
  SEARCH_TIPS: 'product/v1/suggestions/',
};
