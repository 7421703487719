import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'common/constants';
import { getTokenFromCookies } from 'utils/cookies';

export const chatsApi = createApi({
  reducerPath: 'chats',
  tagTypes: ['Chats'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers['Custom-Header-Name'] = 'Custom-Header-Value';

      const token = getTokenFromCookies();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    deleteMessages: builder.mutation({
      query: (id) => ({
        url: `/chat/v2/message/delete/${id}/`,
        method: 'DELETE',
      }),
    }),
    deleteRoom: builder.mutation({
      query: (id) => ({
        url: `/chat/v2/myrooms/delete/${id}/`,
        method: 'DELETE',
      }),
    }),
    blockUser: builder.mutation({
      query: (id) => ({
        url: `/chat/v3/block-user/${id}`,
        method: 'POST',
      }),
    }),
    unBlockUser: builder.mutation({
      query: (id) => ({
        url: `/chat/v3/block-user/${id}`,
        method: 'DELETE',
      }),
    }),
    readAllNotif: builder.mutation({
      query: () => ({
        url: '/notification/v1/notifications/read_all/',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useDeleteMessagesMutation,
  useBlockUserMutation,
  useDeleteRoomMutation,
  useUnBlockUserMutation,
  useReadAllNotifMutation,
} = chatsApi;
