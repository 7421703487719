import React, { memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import messages from 'messages/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { useCategories } from 'hooks/useCategories';
import { appSelectors } from 'providers/AppProvider/data';
import { useSelector } from 'react-redux';

function Search(props) {
  const isMobile = useMediaQuery('(max-width:720px)');

  const localCategories = JSON.parse(localStorage.getItem('iconCategories'));

  const categories = useSelector(appSelectors.categories);

  const { getCategories } = useCategories();

  useEffect(() => {
    if (JSON.stringify(localCategories).length > 3) return;
    getCategories();

    return () => {};
  }, []);

  const list = useMemo(
    () => (categories.length ? categories : localCategories),
    [categories, localCategories],
  );

  if (isMobile) {
    return (
      <Mobile
        {...props}
        categories={list}
        messages={messages}
      />
    );
  }

  return (
    <Desktop
      {...props}
      categories={list}
      messages={messages}
    />
  );
}

Search.propTypes = {
  intl: intlShape,
  messages: PropTypes.object,
  isMouseNearOrScrolling: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  clearSearchValue: PropTypes.func,
};

export default compose(memo, injectIntl)(Search);
