/**
 *
 * UserProvider
 *
 */

import React, {
  memo,
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  authSelectors,
  authActions,
  key as authKey,
  reducer as authReducer,
} from 'services/auth';
import { getTokenFromCookies } from 'utils/cookies';
import { useInjectReducer } from 'utils/injectReducer';
import { FirebaseContext } from 'providers/FirebaseProvider';

import { key, reducer, profileActions } from 'states/Profile';
import { appActions } from 'providers/AppProvider/data';

import AuthFormContainer from 'containers/AuthForm';
import { productActions } from 'states/Products';
import { userActions } from 'states/User';

export const UserContext = createContext({ user: null });

export function UserProvider({ children }) {
  useInjectReducer({ key, reducer });
  useInjectReducer({ key: authKey, reducer: authReducer });
  const dispatch = useDispatch();
  const token = getTokenFromCookies();
  const [businessProfile, setBusinessProfile] = useState(null);
  const { auth } = useContext(FirebaseContext);

  // Selectors
  const user = useSelector(authSelectors.getAuthUser);
  // States
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [isOpenAuthForm, setOpenAuthForm] = useState(false);
  const [formType, setFormType] = useState('login');

  const [productsObj, setProductsObj] = useState();
  const [usersObj, setUsersObj] = useState();

  // Notify states
  const [notifys, setNotifys] = useState({
    count: 0,
    results: [],
  });
  const [notifyCount, setNotifyCount] = useState(0);

  const registration = 'register';

  const openAuthForm = (type) => {
    setFormType(type || 'login');
    setOpenAuthForm(true);
  };

  const closeAuthForm = () => {
    setOpenAuthForm(false);
    setFormType('login');
  };

  const signInWithToken = useCallback(async () => {
    try {
      await dispatch(authActions.tokenLogin());
    } catch (e) {
      return null;
    }
    return null;
  }, [dispatch]);

  const fetchBusinessProfile = useCallback(async () => {
    const res = await dispatch(profileActions.getBusinessProfile());
    if (res) {
      setBusinessProfile(res);
    }

    return res;
  }, [dispatch]);

  useEffect(() => {
    if (!user && token) {
      signInWithToken();
    }
    if (user?.info?.is_business) fetchBusinessProfile();

    return () => null;
  }, [signInWithToken, user, token, fetchBusinessProfile]);

  const logout = useCallback(async () => {
    auth.signOut();
    await dispatch(authActions.logOut());
  }, [dispatch, auth]);

  const handleFavoriteProduct = useCallback(
    async (productID) => {
      try {
        if (user) {
          if (favoriteProducts.includes(productID)) {
            await dispatch(profileActions.removeFavoriteProduct(productID));
            setFavoriteProducts((prev) =>
              prev.filter((item) => item !== productID),
            );
          } else {
            await dispatch(
              profileActions.setToFavorite({ product: productID }),
            );
            setFavoriteProducts((prev) => [...prev, productID]);
          }
        }
      } catch (e) {
        return null;
      }

      return null;
    },
    [dispatch, user, favoriteProducts],
  );

  const updateUsersAndProducts = useCallback((product, userParam) => {
    setProductsObj((prev) => ({
      [product.id]: product,
      ...prev,
    }));

    setUsersObj((prev) => ({
      [userParam.id]: userParam,
      ...prev,
    }));
  }, []);

  const getUser = useCallback(
    async (userId) => {
      try {
        const userData = await dispatch(userActions.getUser(userId));
        return userData;
      } catch (e) {
        return null;
      }
    },
    [dispatch],
  );

  const getProduct = useCallback(
    async (productId) => {
      try {
        const product = await dispatch(
          productActions.getProductInfo(productId, true),
        );
        return product;
      } catch (e) {
        return null;
      }
    },
    [dispatch],
  );

  const addUserAndProductToChats = useCallback(
    async ({ product, fetch }) => {
      if (fetch) {
        const productRes = await getProduct(product.id);
        const userRes = await getUser(product.user.id || product.user);
        updateUsersAndProducts(productRes, userRes);
      } else {
        updateUsersAndProducts(product, product.user);
      }
    },
    [getProduct, getUser, updateUsersAndProducts],
  );

  const fetchFavoriteProducts = useCallback(async () => {
    if (user) {
      try {
        const { results } = await dispatch(
          profileActions.getFavorites({ limit: 1000 }),
        );
        const favorites = results.map((item) => item.id);
        setFavoriteProducts(favorites);
      } catch (err) {
        return null;
      }
    } else {
      setFavoriteProducts([]);
    }

    return null;
  }, [dispatch, user]);

  useEffect(() => {
    fetchFavoriteProducts();
    return () => null;
  }, [fetchFavoriteProducts]);

  const getPersonalProducts = useCallback(async () => {
    if (user) {
      try {
        const personalProductsRes = await dispatch(
          profileActions.getProducts({ limit: 1000 }),
        );

        const personalProductsObj = personalProductsRes.results.reduce(
          (acc, rec) => ({
            ...acc,
            [rec.id]: {
              ...rec,
              description: rec.title,
            },
          }),
          {},
        );

        setProductsObj((prev) => ({
          ...prev,
          ...personalProductsObj,
        }));
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [dispatch, user]);

  useEffect(() => {
    getPersonalProducts();

    return () => null;
  }, [getPersonalProducts]);

  // NOTIFICATIONS

  const getNotifysCount = useCallback(async () => {
    if (user) {
      const res = await dispatch(appActions.getUnreadNotifys());
      setNotifyCount(res.unread_count);
    }
  }, [dispatch, user]);

  useEffect(() => {
    getNotifysCount();

    return () => null;
  }, [getNotifysCount]);

  const getNotifys = useCallback(async () => {
    if (user) {
      const res = await dispatch(appActions.getNotifys());
      setNotifys(res);
    }
  }, [dispatch, user]);

  useEffect(() => {
    getNotifys();

    return () => null;
  }, [getNotifys]);

  // END NOTIFICATIONS

  return (
    <UserContext.Provider
      value={{
        user,
        businessProfile,
        logout,
        favoriteProducts,
        fetchFavoriteProducts,
        openAuthForm,
        registration,
        closeAuthForm,
        handleFavoriteProduct,
        signInWithToken,
        notifyCount,
        notifys,
        getNotifysCount,
        getNotifys,
        addUserAndProductToChats,
      }}
    >
      <>
        <AuthFormContainer
          isOpen={isOpenAuthForm}
          onClose={closeAuthForm}
          defaultType={formType}
        />
        {children}
      </>
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default memo(UserProvider);
