import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

import { makeSelectLocale } from './data/selectors';

export function LanguageProvider(props) {
  const { locale, children, messages } = props;
  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages[locale]}
    >
      {/* <Context.Provider value={{ locale: locale }}> */}
      {React.Children.only(children)}
      {/* </Context.Provider> */}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
)(LanguageProvider);
