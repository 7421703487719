import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
// MUI
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { Grow, Paper } from '@material-ui/core';

import { injectIntl, intlShape } from 'react-intl';
import { changeLocale } from 'providers/LanguageProvider/data/actions';
import { appLocales } from 'i18n';
import CheckImg from 'assets/images/check.svg';

import { useStyles } from './styles';
import { FacebookShareButton } from 'react-share';

export function ToggleLocale(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const { intl, className, style } = props;

  const handleChange = (evt) => {
    let language = window.confirm(
      'У вас есть несохраненные изменения. Вы уверены что хотите сменять язык? ',
    );
    if (language) dispatch(changeLocale(evt.target.value));
  };

  const toggleChecked = () => setChecked((prevState) => !prevState);

  const InvisibleBlock = () =>
    checked ? (
      <div
        className={classes.invisibleBlock}
        onClick={toggleChecked}
      />
    ) : null;

  return (
    <>
      <InvisibleBlock />
      <Button
        className={`${classes.button} ${className}`}
        onClick={toggleChecked}
        style={{ ...style }}
      >
        <Typography
          id="clickableItem"
          className={classes.heading}
        >
          {intl.locale === 'ru' ? 'RU' : 'KY'}
        </Typography>
        <Grow
          in={checked}
          className={classes.grow}
          timeout={400}
        >
          <Paper
            elevation={5}
            onClick={(e) => e.stopPropagation()}
          >
            {appLocales.map((value) => (
              <ListItem
                style={{ paddingTop: '0', paddingBottom: '0' }}
                id="clickableItem"
                key={value}
                button
                onClick={() => handleChange({ target: { value } })}
              >
                <ListItemText
                  id="clickableItem"
                  className={classes.text}
                >
                  {value === 'ky' ? 'Кыргыз тили' : 'Русский'}
                </ListItemText>
                {value === intl.locale ? (
                  <img
                    id="clickableItem"
                    alt=""
                    src={CheckImg}
                    width="14"
                    height="11"
                  />
                ) : null}
              </ListItem>
            ))}
          </Paper>
        </Grow>
      </Button>
    </>
  );
}

ToggleLocale.propTypes = {
  intl: intlShape,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default compose(memo, injectIntl)(ToggleLocale);
