/**
 *
 * GoogleSignIn
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// Assets
import googleIcon from 'assets/images/google.svg';

const useStyles = makeStyles(() => ({
  root: {
    height: 45,
  },
  icon: {
    width: 20,
    height: 20,
  },
  text: {
    color: '#0A142F',
    marginLeft: 10,
    fontWeight: 'bold',
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;
export function GoogleSignIn(props) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.root}
      style={{ width: '100%' }}
      href={`${baseUrl}/auth/v2/google/login/`}
      component={'a'}
    >
      <img
        alt="Google"
        src={googleIcon}
        className={classes.icon}
      />
      <p className={classes.text}>Войти с помощью Google</p>
    </Button>
  );
}

GoogleSignIn.propTypes = {
  onClick: PropTypes.func,
};

export default GoogleSignIn;
