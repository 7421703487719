import React, { memo, createContext } from 'react';
import { useInjectReducer } from 'utils/injectReducer';

// Data
import { key as productKey, reducer as productReducer } from 'states/Products';
import { key as searchKey, reducer as searchReducer } from 'states/Search';
import { reducer } from './data';

export const AppContext = createContext();
const key = 'app';

function AppProvider({ children }) {
  useInjectReducer({ key: searchKey, reducer: searchReducer });
  useInjectReducer({ key: productKey, reducer: productReducer });
  useInjectReducer({ key, reducer });

  return <>{children}</>;
}

export default memo(AppProvider);
