import { defineMessages } from 'react-intl';

export const scope = 'Product';

export default defineMessages({
  delationProduct: {
    id: `${scope}.delationProduct`,
    defaultMessage: 'Удалить',
  },
  deletedProduct: {
    id: `${scope}.deletedProduct`,
    defaultMessage: 'Продукт удалён',
  },
  deleteProductAlertTitle: {
    id: `${scope}.deleteProductAlertTitle`,
    defaultMessage: 'Удаление продукта',
  },
  deleteProductAlertDescription: {
    id: `${scope}.deleteProductAlertDescription`,
    defaultMessage: 'Вы точно хотите удалить продукт?',
  },
  newProducts: {
    id: `${scope}.newProducts`,
    defaultMessage: 'Новые объявления',
  },
  negotiable: {
    id: `${scope}.negotiable`,
    defaultMessage: 'Договорная',
  },
  call: {
    id: `${scope}.call`,
    defaultMessage: 'Позвонить',
  },
  callNumber: {
    id: `${scope}.callNumber`,
    defaultMessage: 'Показать номер',
  },
  sendMessage: {
    id: `${scope}.sendMessage`,
    defaultMessage: 'Написать',
  },
  sames: {
    id: `${scope}.sames`,
    defaultMessage: 'Похожие объявления',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Email или номер телефона',
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Местоположение',
  },
  twoGisLink: {
    id: `${scope}.twoGisLink`,
    defaultMessage: 'Ссылка на 2GIS',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Категория',
  },
  showCount: {
    id: `${scope}.showCount`,
    defaultMessage: 'Показы',
  },
  less: {
    id: `${scope}.less`,
    defaultMessage: 'Скрыть',
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: 'Показать полностью',
  },
  createDate: {
    id: `${scope}.createDate`,
    defaultMessage: 'Дата обновления',
  },
  updateDate: {
    id: `${scope}.updateDate`,
    defaultMessage: 'Дата добавления',
  },
  deactivate: {
    id: `${scope}.deactivate`,
    defaultMessage: 'Отключение',
  },
  activate: {
    id: `${scope}.activate`,
    defaultMessage: 'Активация',
  },
  alertDeactivate: {
    id: `${scope}.alertDeactivate`,
    defaultMessage: 'Хотите отключить объявление ?',
  },
  alertActivate: {
    id: `${scope}.alertActivate`,
    defaultMessage: 'Хотите активировать объявление ?',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Активные',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Неактивные',
  },
  on_review: {
    id: `${scope}.on_review`,
    defaultMessage: 'На рассмотрении',
  },
  views: {
    id: `${scope}.views`,
    defaultMessage: 'Просмотры',
  },
  favorities: {
    id: `${scope}.favorities`,
    defaultMessage: 'Избранные объявления',
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage:
      'Удалить избранные объявления без возможности восстановления?',
  },
  uploadPhoto: {
    id: `${scope}.uploadPhoto`,
    defaultMessage: 'Загрузите фото',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Описание',
  },
  descriptionPlaceholder: {
    id: `${scope}.descriptionPlaceholder`,
    defaultMessage: 'Описание вашего товара',
  },
  parameters: {
    id: `${scope}.parameters`,
    defaultMessage: 'Параметры',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Цена',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Email или номер телефона',
  },
  preview: {
    id: `${scope}.preview`,
    defaultMessage: 'Предварительный просмотр',
  },
  post: {
    id: `${scope}.post`,
    defaultMessage: 'Опубликовать',
  },
  photo: {
    id: `${scope}.photo`,
    defaultMessage: 'Фото',
  },
  editPost: {
    id: `${scope}.editPost`,
    defaultMessage: 'Редактировать объявление',
  },
  putPost: {
    id: `${scope}.putPost`,
    defaultMessage: 'Разместить объявление',
  },
  createPost: {
    id: `${scope}.createPost`,
    defaultMessage: 'Создайте объявление',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Подтвердить',
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: 'Поделиться с друзьями',
  },
  сomplaint: {
    id: `${scope}.сomplaint`,
    defaultMessage: 'Пожаловаться',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'Ничего не найдено',
  },
  noResultsText: {
    id: `${scope}.noResultsText`,
    defaultMessage:
      'По вашему запросу ничего не найдено попробуйте использовать другие ключевые слова',
  },
  successfullyCreated: {
    id: `${scope}.successfullyCreated`,
    defaultMessage: 'Объявление успешно создано!',
  },
  successfullyUpdated: {
    id: `${scope}.successfullyUpdated`,
    defaultMessage: 'Изменения успешно сохранены!',
  },
  postError: {
    id: `${scope}.postError`,
    defaultMessage:
      'Ошибка ввода данных! Проверьте правильность заполнения формы и попытайтесь снова!',
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: 'Детали объявления',
  },
  upvote: {
    id: `${scope}.upvote`,
    defaultMessage: 'Поднять объявление',
  },
  successfullyUpvoted: {
    id: `${scope}.successfullyUpvoted`,
    defaultMessage: 'Ваше объявление успешно поднято',
  },
  upvoteError: {
    id: `${scope}.upvoteError`,
    defaultMessage: 'Следующее поднятие возможно через ',
  },
  noFavoriteTitle: {
    id: `${scope}.noFavoriteTitle`,
    defaultMessage: 'Здесь будет избранные обьявления',
  },
  noFavoriteDesc: {
    id: `${scope}.noFavoriteDesc`,
    defaultMessage:
      'Жмите на сердечко, чтобы не потерять интересные обьявления',
  },
  toSearch: {
    id: `${scope}.toSearch`,
    defaultMessage: 'На поиски',
  },
  userTime: {
    id: `${scope}.user_time`,
    defaultMessage: 'На Tez Sat c ',
  },
  userSimpleTime: {
    id: `${scope}.userSimpleTime`,
    defaultMessage: 'Был в сети ',
  },
  uploadPhotoHelp: {
    id: `${scope}.uploadPhotoHelp`,
    defaultMessage:
      'Чем подробнее Вы опишите свой товар или услугу, тем более возрастут шансы его продать',
  },
  review: {
    id: `${scope}.review`,
    defaultMessage: 'Отзыв',
  },
  reviewsTitle: {
    id: `${scope}.reviewsTitle`,
    defaultMessage: 'Отзывы к товару',
  },
  noReviewsTitle: {
    id: `${scope}.noReviewsTitle`,
    defaultMessage: 'К этому товару еще нет отзывов.',
  },
  addReview: {
    id: `${scope}.addReview`,
    defaultMessage: 'Оставить отзыв',
  },
  stockPrice: {
    id: `${scope}.stockPrice`,
    defaultMessage: 'Цена со скидкой',
  },
  discountBigPrice: {
    id: `${scope}.discountBigPrice`,
    defaultMessage: 'Скидка не должна превшыть существующую цену',
  },
  claim: {
    id: `${scope}.claim`,
    defaultMessage: 'пожаловаться',
  },
  claimProduct: {
    id: `${scope}.claimProduct`,
    defaultMessage: 'Пожаловаться на объявление',
  },
  claimBtn: {
    id: `${scope}.claimBtn`,
    defaultMessage: 'отправить',
  },
  claimSuccess: {
    id: `${scope}.claimSuccess`,
    defaultMessage: 'Жалоба отправлена',
  },
  claimError: {
    id: `${scope}.claimError`,
    defaultMessage: 'Жалоба не отправлена',
  },
  callNum: {
    id: `${scope}.callNum`,
    defaultMessage: 'Вызов ',
  },
  cancelNum: {
    id: `${scope}.cancelNum`,
    defaultMessage: 'Отменить',
  },
  activatedSuccessfully: {
    id: `${scope}.activatedSuccessfully`,
    defaultMessage: 'Объявление успешно активировано',
  },
  activationFailed: {
    id: `${scope}.activationFailed`,
    defaultMessage: 'Не удалось активировать объявление',
  },
  deactivatedSuccessfully: {
    id: `${scope}.deactivatedSuccessfully`,
    defaultMessage: 'Объявление успешно деактивировано',
  },
  deactivationFailed: {
    id: `${scope}.deactivationFailed`,
    defaultMessage: 'Не удалось деактивировать объявление',
  },
  callPlaceholder: {
    id: `${scope}.callPlaceholder`,
    defaultMessage: 'Опишите ситуацию',
  },
  activeProduct: {
    id: `${scope}.activeProduct`,
    defaultMessage: 'здесь будут отображаться ваши активные объявления',
  },
  inactiveProduct: {
    id: `${scope}.inactiveProduct`,
    defaultMessage: 'здесь будут отображаться ваши неактивные объявления',
  },
  postButton: {
    id: `${scope}.postButton`,
    defaultMessage: 'Подать объявление',
  },
  noProduct: {
    id: `${scope}.noProduct`,
    defaultMessage: 'Объявлений пока нет',
  },
  setMainPhoto: {
    id: `${scope}.setMainPhoto`,
    defaultMessage: 'Сделать главным',
  },
  mainPhoto: {
    id: `${scope}.mainPhoto`,
    defaultMessage: 'Главное фото',
  },
  shareInfo: {
    id: `${scope}.shareInfo`,
    defaultMessage: 'Поделиться',
  },
  activationMessage: {
    id: `${scope}.activationMessage`,
    defaultMessage: 'Активировать',
  },
  deactivationMessage: {
    id: `${scope}.deactivationMessage`,
    defaultMessage: 'Деактивировать',
  },
  activatedMessage: {
    id: `${scope}.activatedMessage`,
    defaultMessage: 'Редактировать',
  },
  allProducts: {
    id: `${scope}.allProducts`,
    defaultMessage: 'Все объявления',
  },
  alertProducts: {
    id: `${scope}.alertProducts`,
    defaultMessage: 'Заполните поле - {alerts[e]}!',
  },
  alertDiscount: {
    id: `${scope}.alertDiscount`,
    defaultMessage:
      'Скидка должна быть больше 3% и меньше 99% от стоимости товара',
  },
});
