import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  productList: [],
  hasMore: true,
  productInfo: null,
  claimCategories: [],
  error: null,
  notFound: false,
  prevParams: {},
};

const isListLoading = boolReducer(
  [combineActions(actions.getProductListRequest)],
  [combineActions(actions.getProductListSuccess, actions.getProductListFail)],
);

const isProductLoading = boolReducer(
  [combineActions(actions.getProductInfoRequest)],
  [combineActions(actions.getProductInfoSuccess, actions.getProductInfoFail)],
);

const isClaimCategoriesLoading = boolReducer(
  [combineActions(actions.getClaimCategoriesRequest)],
  [
    combineActions(
      actions.getClaimCategoriesSuccess,
      actions.getClaimCategoriesFail,
    ),
  ],
);

const data = handleActions(
  {
    [actions.getProductListSuccess]: (state, { payload }) => ({
      ...state,
      productList: !payload.previous
        ? payload.results
        : [...state.productList, ...payload.results],
      hasMore: !!payload.next,
      notFound: !payload.results.length,
      nextKey: payload.key,
    }),
    [actions.getProductListFail]: (state, { payload }) => ({
      ...state,
      error: payload,
      notFound: true,
    }),
    [actions.setPrevParams]: (state, { payload }) => ({
      ...state,
      prevParams: payload,
    }),
    [actions.getProductInfoSuccess]: (state, { payload }) => ({
      ...state,
      productInfo: payload,
    }),
    [actions.clearProduct]: (state) => ({
      ...state,
      productInfo: null,
    }),
    [actions.getProductInfoFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [actions.getClaimCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      claimCategories: payload,
    }),
    [actions.getClaimCategoriesFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isListLoading,
  isProductLoading,
  isClaimCategoriesLoading,
});

export const key = 'products';

export const productSelectors = {
  productList: (state) => getStateValue(state, key, 'productList'),
  hasMore: (state) => getStateValue(state, key, 'hasMore'),
  notFound: (state) => getStateValue(state, key, 'notFound'),
  nextKey: (state) => getStateValue(state, key, 'nextKey'),
  isListLoading: (state) => getStateValue(state, key, 'isListLoading', true),
  productInfo: (state) => getStateValue(state, key, 'productInfo'),
  claimCategories: (state) => getStateValue(state, key, 'claimCategories'),
  isProductLoading: (state) => getStateValue(state, key, 'isProductLoading'),
  prevParams: (state) => getStateValue(state, key, 'prevParams'),
};
