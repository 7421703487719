/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const ruLocaleData = require('react-intl/locale-data/ru');
const kyLocaleData = require('react-intl/locale-data/ky');

const ruTranslationMessages = require('./translations/ru.json');
const kyTranslationMessages = require('./translations/ky.json');

addLocaleData(ruLocaleData);
addLocaleData(kyLocaleData);

export const DEFAULT_LOCALE = 'ru';

// prettier-ignore
export const appLocales = [
  'ru',
  'ky'
]

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, ruTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  ru: formatTranslationMessages('ru', ruTranslationMessages),
  ky: formatTranslationMessages('ky', kyTranslationMessages),
};
