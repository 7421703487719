import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.3%',
    '@media (max-width: 580px)': {
      gap: '3%',
    },
  },
  rootProduct: {
    position: 'relative',
    background: '#fff',
    boxShadow: '0px 4px 15px 0px rgba(36, 86, 151, 0.10)',

    marginTop: '15px',
    '@media (min-width: 1125px)': {
      width: '24%',
    },
    '@media (max-width: 1125px)': {
      width: '32.46%',
    },
    '@media (max-width: 580px)': {
      width: '48.5%',
    },
  },
}));
