import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getTokenFromCookies } from 'utils/cookies';
import { BASE_URL } from 'common/constants';

export const purchaseApi = createApi({
  reducerPath: 'chats',
  tagTypes: ['Chats'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers['Custom-Header-Name'] = 'Custom-Header-Value';
      const token = getTokenFromCookies();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTarifes: builder.query({
      query: () => ({
        url: '/payment/v1/tariff/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTarifesQuery } = purchaseApi;
