import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-41%, 0)',
    top: '80%',
    borderRadius: '8px',
    width: '511px',
    backgroundColor: 'white',
    boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      // top: '15%',
      position: 'static',
      width: '267px',
      transform: 'unset',
      // marginTop,
    },
  },
  tip: {
    maxWidth: 433,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 267,
    },
    overflow: 'hidden',
    height: '30px',
    fontSize: '16px',
    color: 'black',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '5px',
    '&:hover': {
      background: '#EBEBEB',
      cursor: 'pointer',
    },
  },
}));
