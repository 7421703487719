import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    textTransform: 'none',
    color: 'inherit',
  },
  unvisableBlock: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    zIndex: '99999 !important',
    opacity: '0',
  },
  grow: {
    position: 'absolute',
    transformOrigin: 'bottom left',
    zIndex: '10000000',
  },
  endIcon: {
    marginLeft: '-1px',
  },
}));
