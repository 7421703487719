import { appActions } from 'providers/AppProvider/data';
import { useDispatch } from 'react-redux';

export const useMainCategories = () => {
  const dispatch = useDispatch();

  const getMainCategories = async () => {
    try {
      await dispatch(appActions.getMainCategories());
    } catch (e) {
      console.warn(e);
      return e;
    }
  };

  return {
    getMainCategories,
  };
};
