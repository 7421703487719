import React, { useState } from 'react';
import { useStyles } from '../styles';

const IsFavorite = ({
  favoriteHandler,
  favorite,
  isAuthorized,
  openAuthForm,
  styles,
}) => {
  const [fill, setFill] = useState(
    'M8.99999 16.9986C8.74374 16.9986 8.49668 16.9058 8.30415 16.7371C7.57699 16.1013 6.87593 15.5038 6.2574 14.9767L6.25424 14.974C4.44081 13.4286 2.87485 12.094 1.78528 10.7794C0.567306 9.30968 0 7.9162 0 6.39391C0 4.91487 0.507156 3.55037 1.42795 2.55157C2.35972 1.54097 3.63826 0.984375 5.02844 0.984375C6.06747 0.984375 7.01902 1.31287 7.85659 1.96065C8.27929 2.28763 8.66244 2.68781 8.99999 3.15459C9.33769 2.68781 9.7207 2.28763 10.1435 1.96065C10.9811 1.31287 11.9327 0.984375 12.9717 0.984375C14.3617 0.984375 15.6404 1.54097 16.5722 2.55157C17.493 3.55037 18 4.91487 18 6.39391C18 7.9162 17.4328 9.30968 16.2148 10.7792C15.1253 12.094 13.5595 13.4285 11.7463 14.9737C11.1267 15.5016 10.4245 16.1001 9.6957 16.7374C9.5033 16.9058 9.25611 16.9986 8.99999 16.9986ZM5.02844 2.03879C3.93626 2.03879 2.93294 2.47467 2.20303 3.26624C1.46228 4.06975 1.05427 5.18047 1.05427 6.39391C1.05427 7.67422 1.53012 8.81927 2.59703 10.1066C3.62823 11.3509 5.16206 12.658 6.938 14.1715L6.94129 14.1743C7.56216 14.7034 8.26597 15.3033 8.99848 15.9438C9.73539 15.302 10.4403 14.7012 11.0624 14.1713C12.8382 12.6578 14.3719 11.3509 15.4031 10.1066C16.4699 8.81927 16.9457 7.67422 16.9457 6.39391C16.9457 5.18047 16.5377 4.06975 15.797 3.26624C15.0672 2.47467 14.0637 2.03879 12.9717 2.03879C12.1716 2.03879 11.437 2.29312 10.7884 2.79465C10.2104 3.24179 9.80776 3.80704 9.57169 4.20255C9.4503 4.40593 9.23661 4.52733 8.99999 4.52733C8.76338 4.52733 8.54969 4.40593 8.42829 4.20255C8.19236 3.80704 7.78971 3.24179 7.21156 2.79465C6.56295 2.29312 5.82838 2.03879 5.02844 2.03879Z',
  );
  const fill1 =
    'M8.99998 16.9986C8.74373 16.9986 8.49667 16.9058 8.30414 16.7371C7.57698 16.1013 6.87592 15.5038 6.25739 14.9767L6.25423 14.974C4.4408 13.4286 2.87484 12.094 1.78527 10.7794C0.567305 9.30968 0 7.9162 0 6.39391C0 4.91487 0.507155 3.55037 1.42795 2.55157C2.35972 1.54097 3.63825 0.984375 5.02843 0.984375C6.06746 0.984375 7.01901 1.31287 7.85658 1.96065C8.27928 2.28763 8.66243 2.68781 8.99998 3.15459C9.33767 2.68781 9.72069 2.28763 10.1435 1.96065C10.9811 1.31287 11.9326 0.984375 12.9717 0.984375C14.3617 0.984375 15.6404 1.54097 16.5722 2.55157C17.4929 3.55037 18 4.91487 18 6.39391C18 7.9162 17.4328 9.30968 16.2148 10.7792C15.1253 12.094 13.5594 13.4285 11.7463 14.9737C11.1267 15.5016 10.4245 16.1001 9.69569 16.7374C9.50329 16.9058 9.2561 16.9986 8.99998 16.9986ZM4.5 5.5C3.40782 5.5 6.98413 4.20844 6.25423 5C5.51348 5.80351 4.5 5.28656 4.5 6.5C4.5 7.78032 8.62878 5.9101 9.69569 7.19742C10.7269 8.44176 8.60036 11.1129 10.3763 12.6264L10 13.5C10.6209 14.0291 8.26596 15.3033 8.99847 15.9438C9.73538 15.302 10.4403 14.7012 11.0624 14.1713C12.8382 12.6578 14.3719 11.3509 15.4031 10.1066C16.4698 8.81927 15 7.67422 15 6.39391C15 5.18047 16.5377 4.06975 15.7969 3.26624C15.0672 2.47467 14.092 5 13 5C12.1999 5 12.6486 4.49847 12 5C11.422 5.44714 9.5 6 10.6435 5.5C10.5221 5.70338 16.4514 2.55157 16.2148 2.55157C19.5 10.5 9.11987 16.1472 8.99847 15.9438C8.76254 15.5483 3.17518 7.44714 2.59703 7C1.94842 6.49847 5.29994 5.5 4.5 5.5Z';
  const fill2 =
    'M8.99999 16.9986C8.74374 16.9986 8.49668 16.9058 8.30415 16.7371C7.57699 16.1013 6.87593 15.5038 6.2574 14.9767L6.25424 14.974C4.44081 13.4286 2.87485 12.094 1.78528 10.7794C0.567306 9.30968 0 7.9162 0 6.39391C0 4.91487 0.507156 3.55037 1.42795 2.55157C2.35972 1.54097 3.63826 0.984375 5.02844 0.984375C6.06747 0.984375 7.01902 1.31287 7.85659 1.96065C8.27929 2.28763 8.66244 2.68781 8.99999 3.15459C9.33769 2.68781 9.7207 2.28763 10.1435 1.96065C10.9811 1.31287 11.9327 0.984375 12.9717 0.984375C14.3617 0.984375 15.6404 1.54097 16.5722 2.55157C17.493 3.55037 18 4.91487 18 6.39391C18 7.9162 17.4328 9.30968 16.2148 10.7792C15.1253 12.094 13.5595 13.4285 11.7463 14.9737C11.1267 15.5016 10.4245 16.1001 9.6957 16.7374C9.5033 16.9058 9.25611 16.9986 8.99999 16.9986ZM5.02844 2.03879C3.93626 2.03879 2.93294 2.47467 2.20303 3.26624C1.46228 4.06975 1.05427 5.18047 1.05427 6.39391C1.05427 7.67422 1.53012 8.81927 2.59703 10.1066C3.62823 11.3509 5.16206 12.658 6.938 14.1715L6.94129 14.1743C7.56216 14.7034 8.26597 15.3033 8.99848 15.9438C9.73539 15.302 10.4403 14.7012 11.0624 14.1713C12.8382 12.6578 14.3719 11.3509 15.4031 10.1066C16.4699 8.81927 16.9457 7.67422 16.9457 6.39391C16.9457 5.18047 16.5377 4.06975 15.797 3.26624C15.0672 2.47467 14.0637 2.03879 12.9717 2.03879C12.1716 2.03879 11.437 2.29312 10.7884 2.79465C10.2104 3.24179 9.80776 3.80704 9.57169 4.20255C9.4503 4.40593 9.23661 4.52733 8.99999 4.52733C8.76338 4.52733 8.54969 4.40593 8.42829 4.20255C8.19236 3.80704 7.78971 3.24179 7.21156 2.79465C6.56295 2.29312 5.82838 2.03879 5.02844 2.03879Z';
  const fill3 =
    'M8.99998 16.9986C8.74373 16.9986 8.49667 16.9058 8.30414 16.7371C7.57698 16.1013 6.87592 15.5038 6.25739 14.9767L6.25423 14.974C4.4408 13.4286 2.87484 12.094 1.78527 10.7794C0.567305 9.30968 0 7.9162 0 6.39391C0 4.91487 0.507155 3.55037 1.42795 2.55157C2.35972 1.54097 3.63825 0.984375 5.02843 0.984375C6.06746 0.984375 7.01901 1.31287 7.85658 1.96065C8.27928 2.28763 8.66243 2.68781 8.99998 3.15459C9.33767 2.68781 9.72069 2.28763 10.1435 1.96065C10.9811 1.31287 11.9326 0.984375 12.9717 0.984375C14.3617 0.984375 15.6404 1.54097 16.5722 2.55157C17.4929 3.55037 18 4.91487 18 6.39391C18 7.9162 17.4328 9.30968 16.2148 10.7792C15.1253 12.094 13.5594 13.4285 11.7463 14.9737C11.1267 15.5016 10.4245 16.1001 9.69569 16.7374C9.50329 16.9058 9.2561 16.9986 8.99998 16.9986ZM4.5 5.5C3.40782 5.5 6.98413 4.20844 6.25423 5C5.51348 5.80351 4.5 5.28656 4.5 6.5C4.5 7.78032 8.62878 5.9101 9.69569 7.19742C10.7269 8.44176 8.60036 11.1129 10.3763 12.6264L10 13.5C10.6209 14.0291 8.26596 15.3033 8.99847 15.9438C9.73538 15.302 10.4403 14.7012 11.0624 14.1713C12.8382 12.6578 14.3719 11.3509 15.4031 10.1066C16.4698 8.81927 15 7.67422 15 6.39391C15 5.18047 16.5377 4.06975 15.7969 3.26624C15.0672 2.47467 14.092 5 13 5C12.1999 5 12.6486 4.49847 12 5C11.422 5.44714 9.5 6 10.6435 5.5C10.5221 5.70338 16.4514 2.55157 16.2148 2.55157C19.5 10.5 9.11987 16.1472 8.99847 15.9438C8.76254 15.5483 3.17518 7.44714 2.59703 7C1.94842 6.49847 5.29994 5.5 4.5 5.5Z';

  const handleFavorite = (e) => {
    e.preventDefault();
    if (isAuthorized) {
      favoriteHandler(favorite);
      return;
    }
    openAuthForm();
  };
  return (
    <svg
      style={{ zIndex: 2, ...styles }}
      onClick={(e) => handleFavorite(e)}
      width={'20px'}
      height={'20px'}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setFill(fill1)}
      onMouseLeave={() => setFill(fill2)}
    >
      <path
        fill={'#FAC917'}
        d={favorite ? fill3 : fill}
      />
    </svg>
  );
};

const SvgFavorite = ({
  user,
  product,
  favorite,
  favoriteProducts,
  favoriteHandler,
  openAuthForm,
  styles,
}) => {
  const classes = useStyles();
  const isAuthorized = user?.info;
  const isOwner = product?.user.id === user?.info.id;
  if (isOwner) return <></>;
  return (
    <div className={classes.favorite}>
      <IsFavorite
        favoriteHandler={favoriteHandler}
        favoriteProducts={favoriteProducts}
        openAuthForm={openAuthForm}
        favorite={favorite || favoriteProducts?.includes(product?.id)}
        product={product}
        isAuthorized={isAuthorized}
        styles={styles}
      />
    </div>
  );
};

export default SvgFavorite;
