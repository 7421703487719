import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './App';

const MOUNT_NODE = document.getElementById('root');

ReactDOM.hydrate(<App />, MOUNT_NODE);
serviceWorker.unregister();
