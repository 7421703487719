import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { authActions } from 'services/auth';

const googleApi = `${process.env.REACT_APP_BASE_URL}/auth/v2/google/connect/`;

const GoogleAuth = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const accessCode = params?.code;

  const regExp = /4%[0-9A-Za-z-_]+/;

  const newCode = accessCode?.match(regExp);

  const formattedCode = newCode?.[0]?.replace(/%2F/, '/');

  const handleGoogleConnect = async () => {
    await axios.post(googleApi, { code: formattedCode }).then((data) => {
      window.location.href = '/';
      dispatch(authActions.socialLogin(data));
    });
  };
  useEffect(() => {
    handleGoogleConnect();
  }, []);

  return <></>;
};

export default GoogleAuth;
