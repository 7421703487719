import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    borderRadius: '15px 15px 0px 0px',
    background: '#FFF',
    boxShadow:
      '0px 0.444px 4.444px 0px rgba(0, 0, 0, 0.04), 0px 3.333px 9.778px 0px rgba(0, 0, 0, 0.19)',
  },
  fab: {
    position: 'absolute',
    top: -30,
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 0,
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.blue,
    boxShadow: 'none',
    '& svg': {
      color: '#fff',
      width: 30,
      height: 30,
    },
  },
  navBtnFab: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiBottomNavigationAction-label': {
      color: '#1D1D1D',
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 400,
      lineHeight: '14.222px',
    },
  },
  navBtn: {
    '& .MuiBottomNavigationAction-label': {
      color: '#1D1D1D',
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 400,
      lineHeight: '14.222px',
    },
  },
}));
