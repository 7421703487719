import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import InfoIcon from './icons/InfoIcon';
import SuccessIcon from './icons/SuccessIcon';
import ErrorIcon from './icons/ErrorIcon';
import CloseIcon from './icons/CloseIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    padding: '5px 8px',
    textTransform: 'uppercase',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
    width: '100%',
    maxWidth: 450,
    // boxSizing: 'border-box',
  },
  info: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    marginLeft: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#FFFFFF',
  },
}));

const AlertTemplate = ({ style, message, options, className, close }) => {
  const classes = useStyles();

  return (
    <div
      style={style}
      className={clsx(
        { [classes[options.type]]: options.type },
        classes.root,
        className,
      )}
    >
      {options.type === 'info' && <InfoIcon color="#FFFFFF" />}
      {options.type === 'success' && <SuccessIcon color="#FFFFFF" />}
      {options.type === 'error' && <ErrorIcon color="#FFFFFF" />}
      <span style={{ flex: 2 }}>{message}</span>
      <IconButton
        onClick={close}
        className={classes.button}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

AlertTemplate.propTypes = {
  message: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string,
  close: PropTypes.func,
  style: PropTypes.object,
};

export default AlertTemplate;
